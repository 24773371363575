import React, { useState, useEffect, useRef } from 'react';
import io from "socket.io-client";
import axios from 'axios';
import RangeSlider from 'react-bootstrap-range-slider';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import '../css/style.css';
import nilanAbove from './NilanAbove'
import nilanFront from './NilanFront'

var currentSetpointTemp = {};
var origSetpointTemp = {};
var commit = false;


function setRoomSetPoint(SetPointTemp){ 
  if(SetPointTemp.length > 0){
    currentSetpointTemp.fields = Object.keys(SetPointTemp[0]).slice(1).join(",");
    currentSetpointTemp.values = Object.values(SetPointTemp[0]).slice(1).join(",");
  } 
}

function setOrigRoomSetPoint(_origSetPointTemp){ 
  if(_origSetPointTemp.length > 0){
    origSetpointTemp.fields = Object.keys(_origSetPointTemp[0]).slice(1).join(",");
    origSetpointTemp.values = Object.values(_origSetPointTemp[0]).slice(1).join(",");
  }  
}

function commitRoomSetPoint(){
  
  if(origSetpointTemp.values != currentSetpointTemp.values && !commit){
    commit = true;
    const query = {
      "request" : "{\"id\" : \"sqlQuery\", \"query\": \"insert into ifserver.roomsetpointtemp (" + currentSetpointTemp.fields + ") values (" + currentSetpointTemp.values + ");\", \"db\": \"ifserver\"}",
      "authorization" : localStorage.getItem("token")
    }    
       
    axios.post('/sendRequest', query)
    .then((res) => { 
           
    }).catch((error) => {
        console.log(error)
    });
  }

}





const Heating = () => {

  const [currentSetPointTemp, setCurrentSetPointTemp] = useState([])
  const [metersAndTemperatures, setmetersAndTemperatures] = useState([]);
  const socketRef = useRef();
  const [isLoading, setLoading] = useState(true);
  const [isLoading2, setLoading2] = useState(true);
  const [T1, setT1] = useState(0.0);
  const [T2, setT2] = useState(0.0);
  const [T3, setT3] = useState(0.0);
  const [T4, setT4] = useState(0.0);
  const [T5, setT5] = useState(0.0);
  const [T6, setT6] = useState(0.0);
  const [T11, setT11] = useState(0.0);
  const [T12, setT12] = useState(0.0);

  window.addEventListener('beforeunload', (event) => {
    commitRoomSetPoint();
  });
  
  function getStat(){
    
    var registers = [4747, 5152, 5153, 5154, 5155, 5156, 5157, 5162, 5163]
    var boshIds = ["/heatingCircuits/hc1/actualSupplyTemperature", "/heatSources/returnTemperature", "/dhwCircuits/dhw1/actualTemp"]
    
    const nilan = [ 
    {
      "request" : "{\"id\" : \"Nilan\", \"function\" : \"readRegisters\", \"registers\" : ["+ registers.map(r => {return "{\"register\" : \"" + r + "\", \"quantity\" : \"1\"} "}) +"]}",
      "authorization" : localStorage.getItem("token")
    },
    
  
  ]
  
  boshIds.map(b => nilan.push({"request" : "{\"id\" : \"Bosch\", \"function\" : \"read\", \"path\" : \"" + b + "\"}", "authorization" : localStorage.getItem("token")}))
  
  
  var query = nilan;
  
  
  
   query.map(q => {
    axios.post('/sendRequest', q)
    .then((res) => { 
     if(res.data.registers){

      res.data.registers.map(r => {
        switch(r.register) {
          case '5152':
            setT1((Math.round(r.value * 100) / 1000).toFixed(1))
            break;
          case '5153':
            setT2((Math.round(r.value * 100) / 1000).toFixed(1))
            break;
          case '5154':
            setT3((Math.round(r.value * 100) / 1000).toFixed(1))
            break;
          case '5155':
            setT4((Math.round(r.value * 100) / 1000).toFixed(1))
            break;
          case '5156':
            setT5((Math.round(r.value * 100) / 1000).toFixed(1))
            break;
          case '5157':
            setT6((Math.round(r.value * 100) / 1000).toFixed(1))
            break;
          case '5162':
            setT11((Math.round(r.value * 100) / 1000).toFixed(1))
            break;
          case '5163':
            setT12((Math.round(r.value * 100) / 1000).toFixed(1))
            break;
          default:
        }
      }
        
      
        
        )
      
      
     }else if(res.data.id.startsWith('/')){
  
     var bosch = res.data;
      
     }
       
    }).catch((error) => {
    });  
   }
  
  
   )
  
  }

  useEffect(() => {

      socketRef.current = io.connect('/temperatures');
      getRoomSetPoint()
  
      socketRef.current.on("temperatures", (result) => {
        setmetersAndTemperatures(result.result);
        setLoading(false);   
      })

      getStat();
      var refreshIntervalId = setInterval(getStat, 15000);

      return () => {
        commitRoomSetPoint();
        commit = false;
        clearInterval(refreshIntervalId);
        socketRef.current.close(); 
          
      }
      
  }, []);

  
  function getRoomSetPoint(){
    
      const query = {
        "request" : "{\"id\" : \"sqlQuery\", \"query\": \"SELECT * FROM ifserver.roomsetpointtemp order by idroomsetpointtemp desc limit 1;\", \"db\": \"ifserver\"}",
        "authorization" : localStorage.getItem("token")
      } 
      
      
      axios.post('/sendRequest', query)
      .then((res) => { 
        
        setCurrentSetPointTemp(res.data.result);
        setOrigRoomSetPoint(res.data.result) 
        setLoading2(false)     
      }).catch((error) => {
         
          if(error.code != 'ERR_BAD_REQUEST'){
            getRoomSetPoint()
          }
          
      });  
  }

  
  setRoomSetPoint(currentSetPointTemp);
 
  if (isLoading || isLoading2) {
 
    return <div className="App">Loading...</div>;
  }




  const withSetpoint = metersAndTemperatures.map(cn => {return cn.room.toLowerCase() in currentSetPointTemp[0] ? (
    <Card key={cn.room} style={{ width: '15%', minWidth: '240px' }} className="justify-content m-2">
      
    <Card.Body>
      <Card.Title>{cn.room}</Card.Title>
      <RangeSlider
        min={18}
        max={25}
        step={0.5}
        value={currentSetPointTemp[0][cn.room.toLowerCase()]}
        style={{width: "100%"}} 
        onChange={changeEvent => setCurrentSetPointTemp([{...currentSetPointTemp[0], [cn.room.toLowerCase()]: changeEvent.target.value}])}
      />
      <Row>
      <Col xs='6' style={{textAlign: "center", fontSize: "14px"}} >       
        Asetus
        <Form.Control disabled style={{textAlign: "center", backgroundColor: "transparent"}} value={(Math.round(currentSetPointTemp[0][cn.room.toLowerCase()] * 100) / 100).toFixed(1) + " °C"} onChange={e => this.setState({ val: e.target.value })}/>
        
      </Col>

      <Col xs='6' style={{textAlign: "center", fontSize: "14px"}} >
        Lämpötila
        <Form.Control disabled style={{textAlign: "center", backgroundColor: "transparent"}} value={(Math.round(cn.value * 100) / 100).toFixed(1) + " °C"} onChange={e => this.setState({ val: e.target.value })}/>
      </Col>

      </Row>
     
    </Card.Body>
  </Card>
  ) : null })


  //const temperatures = 
  /*
  <Card style={{ width: '15%', minWidth: '240px' }} className="justify-content m-2">
      
  <Card.Body>
    <Card.Title>{cn.room}</Card.Title>
   

    <Col xs='6' style={{textAlign: "center", fontSize: "14px"}} >
      Lämpötila
      <Form.Control style={{textAlign: "center"}} value={(Math.round(cn.value * 100) / 100).toFixed(1) + " °C"}/>
    </Col>

 
  </Card.Body>
</Card>
*/
	return (
    <div className="p-2">
  
  
  
  <Container >

    <>

    <Row className="rivi" style={{textAlign: "center"}}>

    {withSetpoint}

    <Card style={{ minWidth: '240px' }} className="justify-content m-2">
      
      <Card.Body>
        <Card.Title>Ilmanvaihtokone </Card.Title>
       
    
        <Col xs={5} md={3}>
    {nilanFront(12,T4,T5,T6,T11,T12)}
    {nilanAbove(12,T1,T2,T3)}
    </Col>
    
     
      </Card.Body>
    </Card>

    
    </Row>
   
 


  
   
      
    </>
   
    </Container>
    
     
    
    
   </div>

	);
};





export default Heating;