import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';



import Lighting from './pages/Lighting/Lighting';
import Settings from './pages/Settings/Settings';
import Overview from './pages/Overview/Overview';
import Heating from './pages/Heating/Heating';
import Electric from './pages/Electric/Electric';
import Navbar from './Components/Navbar/Navbar';

const App = () => {
  return (
<BrowserRouter>
      <Routes>
        <Route path="/" element={<Navbar />}>
          <Route index element={<Overview />} />
          <Route path="lighting" element={<Lighting />} />
          <Route path="heating" element={<Heating />} />
          <Route path="electric" element={<Electric />} />
          <Route path="settings" element={<Settings />} />
          <Route path="*" element={<Overview />} />
        </Route>
      </Routes>
    </BrowserRouter>



  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);