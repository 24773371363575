import * as React from "react";
const NilanFront = (props, T4, T5, T6 ,T11, T12) => (
  <svg
    viewBox="293.9025 67.485 187.9575 436.6125"
    width={187.9575}
    height={436.6125}
    {...props}
  >
    <defs>
      <clipPath id="clip_1">
        <path
          transform="matrix(.75,0,0,.75,0,0)"
          d="M484.48 669.28H485.92V672.48H484.48Z"
          fillRule="evenodd"
        />
      </clipPath>
      <clipPath id="clip_2">
        <path
          transform="matrix(.75,0,0,.75,0,0)"
          d="M475.52 670.56H476.96V672.48H475.52Z"
          fillRule="evenodd"
        />
      </clipPath>
      <clipPath id="clip_3">
        <path
          transform="matrix(.75,0,0,.75,0,0)"
          d="M403.52 669.28H404.96V672.48H403.52Z"
          fillRule="evenodd"
        />
      </clipPath>
      <clipPath id="clip_4">
        <path
          transform="matrix(.75,0,0,.75,0,0)"
          d="M394.72 670.56H396.16V672.48H394.72Z"
          fillRule="evenodd"
        />
      </clipPath>
      <clipPath id="clip_5">
        <path
          transform="matrix(.75,0,0,.75,0,0)"
          d="M638.56 669.28H640V672.48H638.56Z"
          fillRule="evenodd"
        />
      </clipPath>
      <clipPath id="clip_6">
        <path
          transform="matrix(.75,0,0,.75,0,0)"
          d="M629.76 670.56H631.2V672.48H629.76Z"
          fillRule="evenodd"
        />
      </clipPath>
      <clipPath id="clip_7">
        <path
          transform="matrix(.75,0,0,.75,0,0)"
          d="M624.48 89.6H626.24V91.2H624.48Z"
          fillRule="evenodd"
        />
      </clipPath>
      <clipPath id="clip_8">
        <path
          transform="matrix(.75,0,0,.75,0,0)"
          d="M580.64 89.6H582.4V91.2H580.64Z"
          fillRule="evenodd"
        />
      </clipPath>
      <clipPath id="clip_9">
        <path
          transform="matrix(.75,0,0,.75,0,0)"
          d="M569.92 89.6H571.68V91.2H569.92Z"
          fillRule="evenodd"
        />
      </clipPath>
      <clipPath id="clip_10">
        <path
          transform="matrix(.75,0,0,.75,0,0)"
          d="M525.92 89.6H527.68V91.2H525.92Z"
          fillRule="evenodd"
        />
      </clipPath>
      <clipPath id="clip_11">
        <path
          transform="matrix(.75,0,0,.75,0,0)"
          d="M113.92 89.6V672.48H643.04V89.6Z"
          fillRule="evenodd"
        />
      </clipPath>
      <clipPath id="clip_12">
        <path
          transform="matrix(.75,0,0,.75,0,0)"
          d="M113.92 89.6V672.48H643.04V89.6Z"
          fillRule="evenodd"
        />
      </clipPath>
      <clipPath id="clip_13">
        <path
          transform="matrix(.75,0,0,.75,0,0)"
          d="M475.52 671.52H485.92V672.48H475.52Z"
          fillRule="evenodd"
        />
      </clipPath>
      <clipPath id="clip_14">
        <path
          transform="matrix(.75,0,0,.75,0,0)"
          d="M394.72 671.52H404.96V672.48H394.72Z"
          fillRule="evenodd"
        />
      </clipPath>
      <clipPath id="clip_15">
        <path
          transform="matrix(.75,0,0,.75,0,0)"
          d="M629.76 671.52H640V672.48H629.76Z"
          fillRule="evenodd"
        />
      </clipPath>
      <clipPath id="clip_16">
        <path
          transform="matrix(.75,0,0,.75,0,0)"
          d="M113.92 89.6V672.48H643.04V89.6Z"
          fillRule="evenodd"
        />
      </clipPath>
      <clipPath id="clip_17">
        <path
          transform="matrix(.75,0,0,.75,0,0)"
          d="M641.76 267.52H643.04V268.96H641.76Z"
          fillRule="evenodd"
        />
      </clipPath>
      <clipPath id="clip_18">
        <path
          transform="matrix(.75,0,0,.75,0,0)"
          d="M641.44 267.52H643.04V269.12H641.44Z"
          fillRule="evenodd"
        />
      </clipPath>
      <clipPath id="clip_19">
        <path
          transform="matrix(.75,0,0,.75,0,0)"
          d="M113.92 89.6V672.48H643.04V89.6Z"
          fillRule="evenodd"
        />
      </clipPath>
      <clipPath id="clip_20">
        <path
          transform="matrix(.75,0,0,.75,0,0)"
          d="M641.92 267.52H643.04V311.84H641.92Z"
          fillRule="evenodd"
        />
      </clipPath>
      <clipPath id="clip_21">
        <path
          transform="matrix(.75,0,0,.75,0,0)"
          d="M641.44 310.4H643.04V311.84H641.44Z"
          fillRule="evenodd"
        />
      </clipPath>
      <clipPath id="clip_22">
        <path
          transform="matrix(.75,0,0,.75,0,0)"
          d="M113.92 89.6V672.48H643.04V89.6Z"
          fillRule="evenodd"
        />
      </clipPath>
      <clipPath id="clip_23">
        <path
          transform="matrix(.75,0,0,.75,0,0)"
          d="M580.96 89.6H625.92V90.72H580.96Z"
          fillRule="evenodd"
        />
      </clipPath>
      <clipPath id="clip_24">
        <path
          transform="matrix(.75,0,0,.75,0,0)"
          d="M526.24 89.6H571.36V90.72H526.24Z"
          fillRule="evenodd"
        />
      </clipPath>
      <clipPath id="clip_25">
        <path
          transform="matrix(.75,0,0,.75,0,0)"
          d="M113.92 89.6V672.48H643.04V89.6Z"
          fillRule="evenodd"
        />
      </clipPath>
      <clipPath id="clip_26">
        <path
          transform="matrix(.75,0,0,.75,481.74,98.362499)"
          d="M-528.4-41.55V541.33H.72V-41.55Z"
          fillRule="evenodd"
        />
      </clipPath>
      <clipPath id="clip_27">
        <path
          transform="matrix(.75,0,0,.75,0,0)"
          d="M641.76 132.8H643.04V134.24H641.76Z"
          fillRule="evenodd"
        />
      </clipPath>
      <clipPath id="clip_28">
        <path
          transform="matrix(.75,0,0,.75,0,0)"
          d="M641.44 132.8H643.04V134.72H641.44Z"
          fillRule="evenodd"
        />
      </clipPath>
      <clipPath id="clip_29">
        <path
          transform="matrix(.75,0,0,.75,0,0)"
          d="M113.92 89.6V672.48H643.04V89.6Z"
          fillRule="evenodd"
        />
      </clipPath>
      <clipPath id="clip_30">
        <path
          transform="matrix(.75,0,0,.75,0,0)"
          d="M641.76 130.56H643.04V132H641.76Z"
          fillRule="evenodd"
        />
      </clipPath>
      <clipPath id="clip_31">
        <path
          transform="matrix(.75,0,0,.75,0,0)"
          d="M641.92 130.56H643.04V134.24H641.92Z"
          fillRule="evenodd"
        />
      </clipPath>
      <clipPath id="clip_32">
        <path
          transform="matrix(.75,0,0,.75,0,0)"
          d="M113.92 89.6V672.48H643.04V89.6Z"
          fillRule="evenodd"
        />
      </clipPath>
      <clipPath id="clip_33">
        <path
          transform="matrix(.75,0,0,.75,0,0)"
          d="M641.44 130.08H643.04V132H641.44Z"
          fillRule="evenodd"
        />
      </clipPath>
      <clipPath id="clip_34">
        <path
          transform="matrix(.75,0,0,.75,0,0)"
          d="M113.92 89.6V672.48H643.04V89.6Z"
          fillRule="evenodd"
        />
      </clipPath>
      <clipPath id="clip_35">
        <path
          transform="matrix(.75,0,0,.75,0,0)"
          d="M641.92 133.76H643.04V268.96H641.92Z"
          fillRule="evenodd"
        />
      </clipPath>
      <clipPath id="clip_36">
        <path
          transform="matrix(.75,0,0,.75,0,0)"
          d="M641.92 310.4H643.04V664.32H641.92Z"
          fillRule="evenodd"
        />
      </clipPath>
      <clipPath id="clip_37">
        <path
          transform="matrix(.75,0,0,.75,0,0)"
          d="M641.44 662.88H643.04V664.32H641.44Z"
          fillRule="evenodd"
        />
      </clipPath>
      <clipPath id="clip_38">
        <path
          transform="matrix(.75,0,0,.75,0,0)"
          d="M641.92 133.6H643.04V135.2H641.92Z"
          fillRule="evenodd"
        />
      </clipPath>
      <clipPath id="clip_39">
        <path
          transform="matrix(.75,0,0,.75,0,0)"
          d="M641.44 133.6H643.04V135.04H641.44Z"
          fillRule="evenodd"
        />
      </clipPath>
      <clipPath id="clip_40">
        <path
          transform="matrix(.75,0,0,.75,0,0)"
          d="M641.44 133.28H643.04V135.04H641.44Z"
          fillRule="evenodd"
        />
      </clipPath>
    </defs>
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M583.67 141.56 584.15 141.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M584.15 140.28 583.67 139.96"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M598.89 242.16V247.13"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M601.14 246.65V241.04"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M608.19 245.85V237.68"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M605.94 238.8V246.01"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M594.25 249.21V244.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M592 245.53V249.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.83 235.28V246.01"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M615.08 246.17V234.31"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M592 249.21C592 249.85 592.16 250.49 592.32 250.97"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M586.71 142.2H584.15"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M584.15 144.12H586.71"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M584.15 142.52H581.43"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M581.43 143.64H584.15"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M414.46 130.67V130.35"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M407.09 130.35V130.67"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M414.46 130.35 413.98 129.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M407.57 129.39 407.09 130.35"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M413.98 129.39 412.06 128.59"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M409.49 128.59 407.57 129.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M412.06 128.59V128.43"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M409.49 128.43V128.59"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M412.06 128.43 410.78 128.27"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M410.78 128.27 409.49 128.43"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M422.79 130.67V130.35"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M415.58 130.35V130.67"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M422.79 130.35 422.31 129.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M415.9 129.39 415.58 130.35"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M422.31 129.39 420.39 128.59"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M417.83 128.59 415.9 129.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M420.39 128.59V128.43"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M417.83 128.43V128.59"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M420.39 128.43 419.11 128.27"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.11 128.27 417.83 128.43"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M446.19 130.67V130.35"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M438.82 130.35V130.67"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M446.19 130.35 445.71 129.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M439.3 129.39 438.82 130.35"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M445.71 129.39 443.78 128.59"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M441.22 128.59 439.3 129.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.78 128.43 442.5 128.27"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M442.5 128.27 441.22 128.43"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.78 128.59V128.43"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M441.22 128.43V128.59"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M455.8 130.67V130.35"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M448.59 130.35V130.67"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M455.8 130.35 455.48 129.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M449.07 129.39 448.59 130.35"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M455.48 129.39 453.4 128.59"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M450.99 128.59 449.07 129.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M453.4 128.43 452.28 128.27"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M452.28 128.27 450.99 128.43"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M453.4 128.59V128.43"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M450.99 128.43V128.59"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M422.79 311.37V312.17"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M428.72 312.17V311.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M422.79 312.17C422.79 312.97 423.43 313.61 424.23 313.61"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M427.28 313.61C428.08 313.61 428.72 312.97 428.72 312.17"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M414.94 312.17C414.94 312.97 415.58 313.61 416.38 313.61"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.43 313.61C420.23 313.61 421.03 312.97 421.03 312.17"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M414.94 311.37V312.17"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M421.03 312.17V311.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M407.09 311.37V312.17"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M413.18 312.17V311.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M407.09 312.17C407.09 312.97 407.89 313.61 408.69 313.61"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M411.74 313.61C412.54 313.61 413.18 312.97 413.18 312.17"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M508.36 396.11 506.28 396.75"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M505.15 401.72 507.72 405.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M507.72 405.24C507.72 405.4 507.88 405.4 507.88 405.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M506.28 396.75 506.12 396.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M506.12 396.91 505.15 401.56"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M505.15 401.56V401.72"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M578.22 593.63 578.7 593.95"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M583.99 593.95 584.31 593.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M578.7 591.23 578.22 591.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M528.55 593.63 529.03 593.95"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M529.03 591.23 528.55 591.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M538.16 591.39 537.84 591.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M532.55 591.23 532.07 591.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M532.07 593.63 532.55 593.95"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M537.84 593.95 538.16 593.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M547.62 593.95 547.94 593.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M547.94 591.39 547.62 591.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M541.85 593.63 542.33 593.95"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M542.33 591.23 541.85 591.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M561.24 591.39 560.92 591.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M555.15 593.63 555.63 593.95"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M560.92 593.95 561.24 593.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M555.63 591.23 555.15 591.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M587.52 593.95 588 593.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M568.45 593.63 568.93 593.95"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M574.54 591.39 574.22 591.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M568.93 591.23 568.45 591.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M574.22 593.95 574.54 593.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M583.19 593.95 583.51 594.27"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M586.23 594.27 586.55 593.95"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M579.66 593.95 579.98 594.27"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M582.71 594.27 583.03 593.95"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M569.89 593.95 570.21 594.27"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M572.93 594.27 573.25 593.95"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M556.59 593.95 556.91 594.27"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M559.63 594.27 559.95 593.95"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M533.52 593.95 533.84 594.27"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M536.56 594.27 536.88 593.95"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M529.99 593.95 530.15 594.27"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M533.04 594.27 533.2 593.95"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M543.29 593.95 543.45 594.27"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M546.33 594.27 546.66 593.95"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M539.44 601.8V601.96H539.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M539.44 602.28V602.44L539.6 602.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M539.44 602.92V602.92L539.6 603.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M539.44 603.4V603.56L539.6 603.72"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M539.44 604.04V604.04L539.6 604.2"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M539.44 604.52V604.68L539.6 604.84"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M546.33 602.28 546.5 602.12V601.96"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M546.33 602.76H546.5V602.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M546.33 603.4 546.5 603.24V603.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M546.33 603.88H546.5V603.72"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M546.33 604.52 546.5 604.36V604.2"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M546.33 605H546.5V604.84"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M546.33 601.8 546.5 601.64V601.48"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M539.44 601.16V601.32L539.6 601.48"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M539.6 601.96 539.44 602.12V602.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M539.6 602.6 539.44 602.76"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M539.6 603.08 539.44 603.24V603.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M539.6 603.72 539.44 603.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M539.6 604.2 539.44 604.36V604.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M539.6 604.84 539.44 605"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M546.5 601.96V601.96L546.33 601.8"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M546.5 602.6V602.44L546.33 602.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M546.5 603.08V602.92H546.33"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M546.5 603.72V603.56L546.33 603.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M546.5 604.2V604.04H546.33"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M546.5 604.84V604.68L546.33 604.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M546.5 601.48V601.32L546.33 601.16"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M539.6 601.48 539.44 601.64V601.8"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M546.33 601.16V600.84"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M546.33 602.92V602.76"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M546.33 604.04V603.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M546.66 600.84V596.67"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M539.12 596.67V600.84"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M539.12 591.23V596.67"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M546.66 596.67V593.95"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M589.6 591.39V591.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M589.6 591.87V591.87L589.44 591.71"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M589.6 592.51V592.35L589.44 592.19"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M589.6 592.99V592.99L589.44 592.83"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M589.6 593.47 589.44 593.31"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M589.6 592.03V591.87"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M589.6 593.15V592.99"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M582.71 590.91 582.55 591.07V591.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M582.71 591.39 582.55 591.55V591.71"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M582.71 592.03 582.55 592.19"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M582.71 592.51 582.55 592.67V592.83"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M582.71 593.15 582.55 593.31"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M589.6 591.39V591.39L589.44 591.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M589.6 592.03V591.87L589.44 591.71"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M589.6 592.51V592.35H589.44"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M589.6 593.15V592.99L589.44 592.83"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M582.55 591.23V591.39H582.71"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M582.55 591.71V591.87L582.71 592.03"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M582.55 592.35V592.51H582.71"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M582.55 592.83V592.99L582.71 593.15"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M589.44 591.23 589.6 591.07V590.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M589.44 591.71 589.6 591.55V591.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M589.44 592.19H589.6V592.03"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M589.44 592.83 589.6 592.67V592.51"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M589.44 593.31H589.6V593.15"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M589.44 592.35V592.19"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M570.05 601.8V601.96H570.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M570.05 602.28V602.44L570.21 602.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M570.05 602.92V602.92L570.21 603.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M570.05 603.4V603.56L570.21 603.72"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M570.05 604.04V604.04L570.21 604.2"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M570.05 604.52V604.68L570.21 604.84"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M576.94 602.28 577.1 602.12V601.96"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M576.94 602.76H577.1V602.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M576.94 603.4 577.1 603.24V603.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M576.94 603.88H577.1V603.72"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M576.94 604.52 577.1 604.36V604.2"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M576.94 605H577.1V604.84"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M576.94 601.8 577.1 601.64V601.48"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M570.05 601.16V601.32L570.21 601.48"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M570.21 601.96 570.05 602.12V602.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M570.21 602.6 570.05 602.76"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M570.21 603.08 570.05 603.24V603.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M570.21 603.72 570.05 603.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M570.21 604.2 570.05 604.36V604.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M570.21 604.84 570.05 605"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M577.1 601.96V601.96L576.94 601.8"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M577.1 602.6V602.44L576.94 602.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M577.1 603.08V602.92H576.94"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M577.1 603.72V603.56L576.94 603.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M577.1 604.2V604.04H576.94"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M577.1 604.84V604.68L576.94 604.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M577.1 601.48V601.32L576.94 601.16"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M570.21 601.48 570.05 601.64V601.8"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M576.94 601.16V600.84"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M576.94 602.92V602.76"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M576.94 604.04V603.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M577.26 600.84V596.67"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M569.73 596.67V600.84"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M569.73 593.95V596.67"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M577.26 596.67V591.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M628.54 311.37V607.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M631.42 607.09V311.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M623.89 614.62C628.06 614.62 631.42 611.25 631.42 607.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M623.89 611.73C626.45 611.73 628.54 609.65 628.54 607.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M623.89 611.73H577.1"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M577.1 614.62H623.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M633.18 311.37V598.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M628.38 606.77H628.54"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M631.42 605.97C633.66 604.84 635.27 602.76 635.91 600.36"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M631.42 602.44C632.54 601.32 633.18 599.88 633.18 598.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M628.54 603.72H628.38 628.06"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M628.06 603.72 584.63 606.77"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M584.95 609.65 628.38 606.77"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M572.29 614.62H577.1"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M577.1 611.73H572.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M584.95 609.65C583.19 609.65 581.27 609.97 579.5 610.13"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M584.63 606.77C582.71 606.77 580.95 606.93 579.02 607.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M572.29 611.73H560.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M560.12 614.62H572.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M579.02 607.25 560.28 611.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M572.29 611.73 579.5 610.13"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 417.56249999999994, 455.6774902343749)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V.48 1.12L.16 1.92V2.56L.32 3.36 .48 4 .8 4.64 .96 5.12 1.28 5.76 1.6 6.08 1.92 6.4 2.24 6.73 2.56 6.89 3.04 7.05H3.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 420.3300170898437, 458.7974853515624)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0L-.16-.32V-.64L-.32-.96-.49-1.44-.65-1.92V-2.4-3.04L-.81-3.68V-4.16"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M559.63 607.57V596.67"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M556.75 596.67V607.57"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 420.5700073242187, 458.3175048828124)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0H-.16V-.32L-.32-.64-.48-.96-.64-1.44-.81-2.08V-2.72L-.97-3.36V-4-4.8-5.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M559.79 605.81V599.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M552.74 591.23V593.95"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M563.8 593.95V591.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M525.5 590.91V591.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M590.88 591.23V590.91"
    />
    <g
      clipPath="url(#clip_1)"
      transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -5.684341886080802e-14, 0)"
    >
      <path
        transform="matrix(.75,0,0,.75,0,0)"
        strokeWidth={0.801}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="#040503"
        d="M485.12 672.13V669.88"
      />
    </g>
    <g
      clipPath="url(#clip_2)"
      transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -5.684341886080802e-14, 0)"
    >
      <path
        transform="matrix(.75,0,0,.75,0,0)"
        strokeWidth={0.801}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="#040503"
        d="M476.15 671.16V672.13"
      />
    </g>
    <g
      clipPath="url(#clip_3)"
      transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -5.684341886080802e-14, 0)"
    >
      <path
        transform="matrix(.75,0,0,.75,0,0)"
        strokeWidth={0.801}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="#040503"
        d="M404.21 672.13V669.88"
      />
    </g>
    <g
      clipPath="url(#clip_4)"
      transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -5.684341886080802e-14, 0)"
    >
      <path
        transform="matrix(.75,0,0,.75,0,0)"
        strokeWidth={0.801}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="#040503"
        d="M395.39 671.16V672.13"
      />
    </g>
    <g
      clipPath="url(#clip_5)"
      transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -5.684341886080802e-14, 0)"
    >
      <path
        transform="matrix(.75,0,0,.75,0,0)"
        strokeWidth={0.801}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="#040503"
        d="M639.27 672.13V669.88"
      />
    </g>
    <g
      clipPath="url(#clip_6)"
      transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -5.684341886080802e-14, 0)"
    >
      <path
        transform="matrix(.75,0,0,.75,0,0)"
        strokeWidth={0.801}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="#040503"
        d="M630.46 671.16V672.13"
      />
    </g>
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M629.66 669.88V671.16"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M394.43 669.88V671.16"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M475.35 669.88V671.16"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M581.43 144.77C580.14 144.77 579.02 145.25 578.22 146.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M578.22 140.12C579.02 140.92 580.14 141.4 581.43 141.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M577.74 146.21H578.22"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M578.22 140.12H577.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M483.52 658.83V590.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.03 405.08H611.71"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M610.27 419.02C610.59 418.7 610.75 418.38 610.59 418.06"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M610.59 418.06C610.75 417.58 610.27 417.1 609.79 417.1"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M609.79 415.01C610.27 414.85 610.75 414.37 610.59 413.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M610.59 413.89C610.75 413.41 610.27 412.93 609.79 412.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M609.95 417.42H609.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M609.79 414.69H609.95"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M609.95 413.25H609.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M609.63 415.01H609.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M609.79 412.93H609.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M609.79 417.1H609.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M611.55 419.98H612.03"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.19 410.69H612.83"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.83 407.8H612.67"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M515.25 215.89 513.97 214.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M511.72 214.93 510.28 215.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M511.24 214.93V215.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M514.29 215.09V214.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M599.69 250.97 597.93 249.53"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M596.17 251.62 597.77 253.06"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M590.88 255.62 590.4 265.07"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M618.6 246.65C615.72 246.01 612.67 245.85 609.79 245.85"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 457.3424682617187, 184.38751220703122)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0-2.73 .16-5.29 .32-7.69 .64-9.94 .96-12.02 1.6-13.62 2.24-15.06 3.04-16.35 4-16.83 4.48-17.31 5.12-17.95 5.77-18.27 6.57-18.59 7.53-18.75 8.49-18.91 9.77"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M588.96 265.07V267.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M512.69 151.81V156.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M515.73 151.81V151.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M513.33 147.65V151.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M515.41 147.17V145.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M513.65 145.09V147.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M517.97 142.52C516.53 142.52 515.41 143.64 515.41 145.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M517.97 140.92C515.57 140.92 513.65 142.68 513.65 145.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M524.86 140.92H517.97"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M517.97 142.52H524.86"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M486.89 261.87V254.82"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M484.16 254.82V261.87"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M496.98 246.81 490.89 250.17"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M492.18 252.58 498.26 249.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M496.98 232.87 490.89 236.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M492.18 238.64 498.26 235.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M486.89 247.93V240.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M484.16 240.88V247.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M496.98 239.76 490.89 243.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M492.18 245.53 498.26 242.16"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M496.98 253.7 490.89 257.22"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M492.18 259.46 498.26 256.1"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M496.98 260.59 490.89 264.11"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M492.18 266.51 498.26 262.99"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M490.89 229.35 484.8 232.87"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M486.25 235.12 492.18 231.75"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M496.98 267.63 496.66 267.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M498.26 225.82 492.18 222.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M490.89 224.7 496.98 228.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M488.49 212.05V207.56"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M485.93 207.56V212.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M498.58 199.39 492.5 202.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M493.94 205.32 499.87 201.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M498.58 185.62 492.5 188.98"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M493.94 191.38 499.87 187.86"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M488.49 158.86V151.97"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M485.93 151.97V158.86"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M498.58 171.68 492.5 175.2"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M493.94 177.45 499.87 173.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M488.49 172.8V165.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M485.93 165.91V172.8"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M498.58 178.57 492.5 182.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M493.94 184.49 499.87 180.97"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M498.58 143.8 492.5 147.33"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M493.94 149.73 499.87 146.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M488.49 186.74V179.85"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M485.93 179.85V186.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M498.58 164.63 492.5 168.15"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M493.94 170.56 499.87 167.03"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M498.58 150.85 492.5 154.22"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M493.94 156.62 499.87 153.1"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M488.49 200.67V193.62"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M485.93 193.62V200.67"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M498.58 157.74 492.5 161.27"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M493.94 163.51 499.87 160.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M498.58 192.5 492.5 196.03"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M493.94 198.27 499.87 194.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M492.5 140.44 486.57 143.8"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M487.85 146.21 493.94 142.68"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M497.78 212.05 493.94 209.96"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M496.66 138.04 498.58 139.16"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M490.89 267.31 490.73 267.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M494.42 267.79 500.83 264.11"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M499.71 262.19 490.89 267.31"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M493.62 180.17 502.43 175.04"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M501.31 173.28 492.5 178.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M492.5 178.25 492.18 179.53"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M492.34 179.85 493.62 180.17"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.31 188.34 419.59 188.5"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M395.23 130.19C395.23 130.03 394.91 129.71 394.75 129.71"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M393.31 129.71C393.15 129.71 392.99 130.03 392.99 130.19"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M395.23 130.35V130.19"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M392.99 130.19V130.35"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M394.91 130.67C395.07 130.67 395.23 130.51 395.23 130.35"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M392.99 130.35C392.99 130.51 393.15 130.67 393.31 130.67"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M394.59 133.55V133.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M393.47 133.39V133.55"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M393.47 133.55V133.71"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M394.59 133.71V133.55"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M641.52 130.67C641.68 130.67 641.68 130.51 641.68 130.35"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M639.59 130.35C639.59 130.51 639.59 130.67 639.75 130.67"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M641.68 130.35V130.19"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M639.59 130.19V130.35"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M641.68 130.19C641.68 130.03 641.52 129.71 641.35 129.71"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M639.91 129.71C639.75 129.71 639.59 130.03 639.59 130.19"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M641.19 133.55V133.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M640.07 133.39V133.55"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M640.07 133.55V133.71"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M641.19 133.71V133.55"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M398.92 130.35V130.67"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M448.59 122.98 447.15 122.18"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M403.24 122.18 401.8 122.98"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M447.31 129.71C447.31 130.19 447.63 130.35 447.95 130.35"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M402.44 130.35C402.76 130.35 403.08 130.19 403.08 129.71"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M447.31 120.42C447.31 120.1 447.15 119.94 446.99 119.94"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M403.4 119.94C403.24 119.94 403.08 120.1 403.08 120.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M447.31 121.7V120.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M403.08 120.42V121.7"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M447.31 125.06 446.83 122.98"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M403.56 122.98 403.08 125.06"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M447.31 129.71V125.06"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M403.08 125.06V129.71"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M447.15 122.18V121.7"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M403.24 121.7V122.18"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M626.77 93.18 625.33 92.38"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M581.43 92.38 579.98 93.18"
    />
    <g
      clipPath="url(#clip_7)"
      transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -5.684341886080802e-14, 0)"
    >
      <path
        transform="matrix(.75,0,0,.75,0,0)"
        strokeWidth={0.801}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="#040503"
        d="M625.49 90.46C625.49 90.3 625.33 89.98 625.17 89.98"
      />
    </g>
    <g
      clipPath="url(#clip_8)"
      transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -5.684341886080802e-14, 0)"
    >
      <path
        transform="matrix(.75,0,0,.75,0,0)"
        strokeWidth={0.801}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="#040503"
        d="M581.59 89.98C581.43 89.98 581.27 90.3 581.27 90.46"
      />
    </g>
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M625.49 91.74V90.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M581.27 90.46V91.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M625.49 95.11 625.01 93.18"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M581.75 93.18 581.27 95.11"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M625.49 97.51V95.11"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M581.27 95.11V97.51"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M625.33 92.38V91.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M581.43 91.74V92.38"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M572.13 93.18 570.69 92.38"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M526.79 92.38 525.34 93.18"
    />
    <g
      clipPath="url(#clip_9)"
      transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -5.684341886080802e-14, 0)"
    >
      <path
        transform="matrix(.75,0,0,.75,0,0)"
        strokeWidth={0.801}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="#040503"
        d="M570.85 90.46C570.85 90.3 570.69 89.98 570.53 89.98"
      />
    </g>
    <g
      clipPath="url(#clip_10)"
      transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -5.684341886080802e-14, 0)"
    >
      <path
        transform="matrix(.75,0,0,.75,0,0)"
        strokeWidth={0.801}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="#040503"
        d="M526.95 89.98C526.79 89.98 526.63 90.3 526.63 90.46"
      />
    </g>
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M570.85 91.74V90.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M526.63 90.46V91.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M570.85 95.11 570.37 93.18"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M527.11 93.18 526.63 95.11"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M570.85 97.51V95.11"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M526.63 95.11V97.51"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M570.69 92.38V91.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M526.79 91.74V92.38"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M446.83 220.22V221.18"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M565.4 253.22 565.24 253.38"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M567.49 255.14 567.33 255.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M565.24 253.38 567.33 255.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M567.49 255.14 565.4 253.22"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M570.37 252.74 570.53 252.9"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M570.69 252.74V252.58"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M570.69 252.74C570.69 252.74 570.53 252.74 570.53 252.9"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M570.69 252.58C570.53 252.58 570.37 252.58 570.37 252.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M571.97 252.58H570.69"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M571.97 252.74V252.58"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M570.69 252.74H571.97"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M570.37 252.74 567.81 255.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M567.97 255.3 567.81 255.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M567.97 255.3 570.53 252.9"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M567.33 255.3C567.49 255.62 567.81 255.62 567.97 255.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M567.49 255.14C567.49 255.3 567.65 255.3 567.81 255.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M577.74 252.74V252.58"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M577.74 252.58V252.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M577.9 252.74 577.74 252.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M577.74 252.74H577.9"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M577.74 252.74H571.97"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M577.74 252.58H571.97"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M580.3 251.62 577.9 252.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M580.3 251.62 580.14 251.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M580.14 251.29 577.74 252.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M548.74 154.38 548.26 154.22"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M546.66 151.65C546.66 151.97 546.66 152.29 546.66 152.62"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M549.22 150.05C548.9 150.05 548.58 150.05 548.26 150.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M550.82 152.62C550.98 152.29 550.98 151.97 550.82 151.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M549.22 154.22 548.74 154.38"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M548.74 154.38 549.22 154.22"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M550.82 152.62C550.98 152.29 550.98 151.97 550.82 151.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M549.22 150.05C548.9 150.05 548.58 150.05 548.26 150.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M546.66 151.65C546.66 151.97 546.66 152.29 546.66 152.62"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M548.26 154.22 548.74 154.38"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M565.08 146.69H569.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M569.25 138.04V146.69"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M565.08 138.04V146.69"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M569.25 147.33H565.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M569.25 146.69V147.33"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M565.08 147.33V146.69"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M569.57 146.69H569.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M569.57 138.04V146.69"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M569.57 147.33H569.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M569.57 146.69V147.33"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M572.29 145.25H569.57"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M562.68 141.72H565.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M562.68 141.72V139.96"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M562.68 139.96H565.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M562.68 145.41H565.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M562.68 145.41V143.64"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M562.68 143.64H565.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M572.29 145.57H570.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M570.05 145.57V145.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M583.67 141.56V139.96"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M584.15 140.28V141.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M572.29 149.57H577.26"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M572.29 138.04V149.57"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M577.74 149.57H577.26"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M577.74 138.04V149.57"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M577.26 149.89C577.42 149.89 577.58 149.73 577.58 149.57"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M577.26 150.05V149.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M577.26 150.05C577.58 150.05 577.74 149.89 577.74 149.57"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M577.26 149.89H572.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M577.26 150.05H572.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M572.29 150.05V149.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 402.4199829101562, 106.04999542236328)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .16-.48 .48-.48 .64-.48 .96-.32 .96 0 .96 .16 .64 .32 .48 .32 .16 .32 0 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M524.54 159.98V172.96"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 403.73999023437494, 104.37000274658203)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.16 .32-.32 .48-.32 .8-.32 .96-.16 .96 0 .96 .32 .8 .48 .48 .48 .32 .48 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M524.54 138.04V140.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M524.54 142.52V144.45"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M551.14 152.13V151.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M549.22 149.89C548.9 149.73 548.58 149.73 548.26 149.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M546.5 151.65 546.33 152.13 546.5 152.62"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M548.26 154.54C548.58 154.54 548.9 154.54 549.22 154.54"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M551.14 152.62V152.13"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M526.47 173.44H524.54"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M524.54 172.96V173.44"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M626.77 228.23V170.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 475.00500488281244, 134.2875061035156)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .16-.48 .48-.48 .64-.48 .96-.32 .96 0 .96 .16 .64 .48 .48 .48 .16 .48 0 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 475.00500488281244, 168.04499816894528)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.16 .16-.48 .48-.48 .64-.48 .96-.16 .96 0 .96 .32 .64 .48 .48 .48 .16 .48 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M631.74 170.4H626.77"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M626.77 228.23H631.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M626.29 170.4H626.77"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M626.77 228.23H626.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M626.29 228.23V170.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M626.77 169.92V170.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M626.77 170.08C626.61 170.08 626.45 170.24 626.45 170.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M626.77 169.92C626.45 169.92 626.29 170.08 626.29 170.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M626.77 228.55V228.87"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M626.77 228.55H631.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M626.77 228.71H631.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M631.74 169.92H626.77"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M631.74 170.08H626.77"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M626.29 228.23C626.29 228.55 626.45 228.71 626.77 228.71"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M626.45 228.23C626.45 228.39 626.61 228.55 626.77 228.55"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M571.97 252.42V252.58"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M571.97 252.74V253.06"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M577.74 252.42V252.58"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M577.74 253.06V253.22"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M626.29 228.71V228.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M577.74 252.42 626.29 228.71"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M577.74 252.42H572.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M616.2 169.92H626.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M626.29 170.4V169.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M572.29 252.42H571.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M571.81 251.46V252.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 444.48001098632807, 114.46499633789062)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.33 .16-.49 .48-.49 .64-.49 .8-.33 .8 0 .8 .16 .64 .32 .48 .32 .16 .32 0 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 460.1024780273437, 114.46499633789062)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.33 .32-.49 .48-.49 .64-.49 .97-.33 .97 0 .97 .16 .64 .32 .48 .32 .32 .32 0 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M592.16 152.78 592.8 153.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M592.48 151.97 592.16 152.78"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M593.28 151.65 592.48 151.97"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M593.92 152.29 593.28 151.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M593.76 153.26 593.92 152.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M592.8 153.42 593.76 153.26"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M613.15 152.46 613.47 153.26"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M614.44 151.81H613.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M614.92 152.62 614.44 151.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M614.27 153.42 614.92 152.62"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M613.47 153.26 614.27 153.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M613.63 151.81 613.15 152.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 444.11999511718744, 114.46499633789062)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.65 .32-.97 .96-.97 1.44-.97 1.93-.65 1.93 0 1.93 .48 1.44 .96 .96 .96 .32 .96 0 .48 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 459.7424926757812, 114.46499633789062)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.65 .48-.97 .96-.97 1.45-.97 1.93-.65 1.93 0 1.93 .48 1.45 .96 .96 .96 .48 .96 0 .48 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 443.40002441406244, 114.46499633789062)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-1.13 .8-2.09 1.92-2.09 3.05-2.09 3.85-1.13 3.85 0 3.85 .96 3.05 1.92 1.92 1.92 .8 1.92 0 .96 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 459.0225219726562, 114.46499633789062)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-1.13 .8-2.09 1.92-2.09 3.05-2.09 3.85-1.13 3.85 0 3.85 .96 3.05 1.92 1.92 1.92 .8 1.92 0 .96 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M434.49 165.59V171.68"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.09 171.68H434.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M434.01 171.52C434.49 171.2 434.49 170.56 434.01 170.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M434.01 170.08C434.49 169.6 434.49 169.12 434.01 168.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M434.01 168.63C434.49 168.15 434.49 167.51 434.01 167.19"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.57 165.75V171.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.09 165.59H434.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.09 165.59V171.68"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.57 171.52H434.01"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M434.01 167.19C434.49 166.71 434.49 166.07 434.01 165.75"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M434.01 165.75H432.57"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.37 166.55H433.69"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.37 166.23V166.55"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.69 166.55V166.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.69 166.23H433.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.69 170.72H433.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.37 170.88H433.69"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.37 170.72V170.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.69 170.88V170.72"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.69 167.83H433.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.37 167.99H433.69"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.37 167.83V167.99"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.69 167.99V167.83"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.69 169.44V169.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.37 169.44H433.69"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.37 169.28V169.44"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.69 169.28H433.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 324.90750122070307, 128.16000366210935)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.16 .16-.32 .32-.32 .48-.32 .48-.16 .48 0 .48 .16 .48 .16 .32 .16 .16 .16 0 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 324.90750122070307, 125.87249755859372)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.16 .16-.16 .32-.16 .48-.16 .48-.16 .48 0 .48 .16 .48 .32 .32 .32 .16 .32 0 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 324.90750122070307, 124.79249572753903)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.16 .16-.16 .32-.16 .48-.16 .48-.16 .48 0 .48 .16 .48 .32 .32 .32 .16 .32 0 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 324.90750122070307, 126.95999908447263)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0 0 .16-.16 .32-.16 .48-.16 .48 0 .48 0 .48 .16 .48 .32 .32 .32 .16 .32 0 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.57 165.43H434.01"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M434.01 163.83C434.49 163.51 434.49 162.87 434.01 162.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M434.49 156.46V165.43"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M434.01 159.5C434.49 159.02 434.49 158.54 434.01 158.06"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M434.01 162.39C434.49 162.07 434.49 161.43 434.01 160.95"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.09 156.46V165.43"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M434.01 165.43C434.49 164.95 434.49 164.31 434.01 163.83"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M434.01 156.62H432.57"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M434.01 158.06C434.49 157.58 434.49 156.94 434.01 156.62"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.57 156.62V165.43"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M434.01 160.95C434.49 160.62 434.49 159.98 434.01 159.5"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.09 165.43H434.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.09 156.46H434.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.69 157.26H433.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.69 157.42V157.26"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.37 157.42H433.69"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.37 157.26V157.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.69 163.03H433.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.69 163.35V163.03"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.37 163.35H433.69"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.37 163.03V163.35"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.37 160.46H433.69"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.37 160.14V160.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.69 160.46V160.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.69 160.14H433.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.37 158.7V158.86"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.69 158.86V158.7"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.69 158.7H433.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.37 158.86H433.69"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.37 161.91H433.69"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.37 161.59V161.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.69 161.59H433.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.69 161.91V161.59"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.69 164.79V164.47"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.37 164.79H433.69"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.37 164.47V164.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.69 164.47H433.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 324.90750122070307, 121.31249999999997)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.16 .16-.32 .32-.32 .48-.32 .48-.16 .48 0 .48 .16 .48 .16 .32 .16 .16 .16 0 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 324.90750122070307, 120.2250061035156)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.16 .16-.32 .32-.32 .48-.32 .48-.16 .48 0 .48 .16 .48 .16 .32 .16 .16 .16 0 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 324.90750122070307, 123.47250366210935)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.16 .16-.32 .32-.32 .48-.32 .48-.16 .48 0 .48 .16 .48 .32 .32 .32 .16 .32 0 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 324.90750122070307, 117.94499206542966)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.16 .16-.16 .32-.16 .48-.16 .48-.16 .48 0 .48 .16 .48 .32 .32 .32 .16 .32 0 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 324.90750122070307, 119.14500427246091)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.16 .16-.32 .32-.32 .48-.32 .48-.16 .48 0 .48 0 .48 .16 .32 .16 .16 .16 0 0 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 324.90750122070307, 122.39250183105466)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.16 .16-.32 .32-.32 .48-.32 .48-.16 .48 0 .48 .16 .48 .32 .32 .32 .16 .32 0 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.09 153.26V156.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.09 153.26H434.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M434.49 153.26V156.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.09 156.46H434.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.57 156.3H434.01"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M434.01 156.3C434.49 155.82 434.49 155.18 434.01 154.86"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M434.01 154.86C434.49 154.38 434.49 153.74 434.01 153.26"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M434.01 153.26H432.57"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.57 153.26V156.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.69 155.34H433.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.37 155.66H433.69"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.37 155.34V155.66"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.69 155.66V155.34"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.69 154.22V153.9"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.37 153.9V154.22"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.69 153.9H433.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.37 154.22H433.69"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 324.90750122070307, 116.62499999999997)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.16 .16-.32 .32-.32 .48-.32 .48-.16 .48 0 .48 .16 .48 .32 .32 .32 .16 .32 0 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 324.90750122070307, 115.54499816894531)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.16 .16-.32 .32-.32 .48-.32 .48-.16 .48 0 .48 .16 .48 .32 .32 .32 .16 .32 0 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.88 162.87H430.32"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.32 162.87V163.03"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.32 163.03H424.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.88 162.87V163.03"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.88 160.3V162.87"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.88 162.87H424.72"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.72 162.87V160.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.72 160.3H424.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.88 165.91V168.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.88 168.63H424.72"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.72 168.63V165.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.72 165.91H424.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.88 160.14V160.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.88 160.14 424.72 160.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.88 163.03H430.32"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.32 163.03V163.19"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.32 163.19H424.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.88 163.03V163.19"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.32 160.14V160.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.32 160.3V160.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.88 163.19V165.75"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.88 165.75H424.72"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.72 165.75V163.19"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.72 163.19H424.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.88 157.42V159.98"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.88 159.98H424.72"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.72 159.98V157.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.72 157.42H424.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.88 159.98H430.32"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.32 159.98V160.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.32 160.14H424.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.88 159.98V160.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.32 162.87V160.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.32 160.3V162.87"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.88 168.79V171.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.32 168.79H424.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.32 171.52V168.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.88 171.52H430.32"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.72 154.54H424.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.88 154.54H424.72"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.88 168.63H430.32"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.32 168.63H424.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.32 157.42H424.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.32 159.98V157.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.32 165.91H424.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.32 168.63V165.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.72 168.79H424.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.88 168.79H424.72"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.72 171.52H424.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.88 171.52H424.72"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.32 157.42V159.98"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.88 168.79H430.32"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.32 165.75V163.19"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.32 163.19V165.75"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.32 160.3H424.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.32 160.14V159.98"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.72 159.98C424.72 160.14 424.88 160.14 424.88 160.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.32 168.79V171.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.88 154.54V157.26"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.88 157.26H424.72"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.72 157.26V154.54"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.32 157.26V154.54"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.32 154.54V157.26"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.32 165.91V168.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.88 160.14H430.32"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.88 154.54H430.32"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.32 154.54H424.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.88 165.75H430.32"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.72 168.63H424.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.72 171.52V168.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.32 171.52H424.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.32 165.75H424.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.88 165.91H430.32"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.88 157.26H430.32"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.32 157.26H424.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.88 157.42H430.32"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.32 163.03V162.87"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.88 157.42H424.72"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.32 163.19V163.03"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.72 157.26H424.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.88 163.03C424.88 163.03 424.72 163.03 424.72 163.19"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.88 165.91H424.72"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.72 162.87C424.72 163.03 424.88 163.03 424.88 163.03"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.72 165.75H424.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 318.7799987792968, 132.6000061035156)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V-.16 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426.16 178.41V173.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.23 178.41H426.16"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.23 173.92V178.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426.16 173.92H424.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426 175.04V174.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426 174.24 425.68 174.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.68 174.4V174.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.68 174.24H424.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.4 174.24V175.04"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.4 175.04H425.68"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.68 175.04V174.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.68 174.88 425.84 175.04"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426 176V175.36"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426 175.36 425.68 175.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.68 175.52V175.2"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.68 175.2H424.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.4 175.2V176.16"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.4 176.16H425.68"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.68 176.16V175.84"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426 177.12V176.32"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426 176.32 425.68 176.48"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.68 176.48V176.32"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.68 176.32H424.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.4 176.32V177.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.4 177.12H425.68"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.68 177.12V176.96"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426 178.25V177.45"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426 177.45 425.68 177.61"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.68 177.29H424.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.68 177.93 425.84 178.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.4 177.29V178.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.68 177.61V177.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.68 175.84 425.84 176"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.68 176.96 425.84 177.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.68 178.25V177.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.4 178.25H425.68"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 318.7799987792968, 139.2149963378906)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V-.16 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.68 179.05V178.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.4 179.69H425.68"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.68 179.85H424.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426 181.77V180.97"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426 180.97 425.68 181.13"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.68 181.13V180.97"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.68 180.97H424.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.4 181.77H425.68"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.68 181.77V181.45"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.68 181.61 425.84 181.77"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426 182.73V181.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426 181.93 425.68 182.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.68 182.09V181.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.68 181.93H424.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.4 181.93V182.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.4 182.73H425.68"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.68 182.73V182.57"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.68 182.57 425.84 182.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426 183.85V183.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426 183.05 425.68 183.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.68 183.21V183.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.68 183.05H424.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.4 183.05V183.85"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.68 184.01H424.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.68 184.65 425.84 184.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426 184.17 425.68 184.33"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.4 178.73V179.69"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.68 180.01V179.85"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.68 183.69 425.84 183.85"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.4 180.97V181.77"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.68 180.49 425.84 180.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426 180.65V179.85"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.4 180.65H425.68"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.68 185.78 425.84 185.94"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.68 187.06V186.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426 185.94V185.13"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426 185.13 425.68 185.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426.16 178.57H424.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.4 186.1V187.06"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.68 185.13H424.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426 187.06V186.26"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426 179.53V178.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.68 183.85V183.69"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.68 180.65V180.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.4 187.06H425.68"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.4 183.85H425.68"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426 179.85 425.68 180.01"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426 186.26 425.68 186.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.4 179.85V180.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.68 184.33V184.01"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426 178.89 425.68 179.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.4 185.13V185.94"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.4 184.01V184.97"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.68 178.73H424.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.23 187.22H426.16"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.23 178.57V187.22"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.68 179.69V179.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.68 186.74 425.84 187.06"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.68 185.94V185.78"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.4 185.94H425.68"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426 184.81V184.17"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.68 184.97V184.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.68 186.42V186.1"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.68 179.37 425.84 179.53"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.68 185.29V185.13"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.68 186.1H424.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426.16 187.22V178.57"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.4 184.97H425.68"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 318.7799987792968, 135.24749755859372)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V-.16 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 318.7799987792968, 130.91999816894528)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0 .16 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 318.7799987792968, 137.5274963378906)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0 .16 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.07 152.62V189.94"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M434.65 152.62H424.07"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M434.65 189.94V152.62"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.07 189.94H434.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 321.6600036621093, 115.30500793457031)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .16-.48 .48-.48 .64-.48 .96-.32 .96 0 .96 .16 .64 .32 .48 .32 .16 .32 0 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 324.78747558593744, 129.6000061035156)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.16 .16-.32 .48-.32 .64-.32 .96-.16 .96 0 .96 .32 .64 .48 .48 .48 .16 .48 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 318.5400085449218, 129.6000061035156)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.16 .16-.32 .48-.32 .64-.32 .96-.16 .96 0 .96 .32 .64 .48 .48 .48 .16 .48 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M578.38 139.32H577.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M578.38 138.36H577.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M578.38 139.32V138.36"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M578.38 138.04V138.36"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M578.38 140.28V139.32"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M579.02 138.36H578.38"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M585.27 139C585.43 139 585.59 138.84 585.59 138.68"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M585.27 138.68V139"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M585.59 138.68H585.27"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M578.06 146.53H579.98"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M578.06 147.01V146.53"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M577.74 147.01H578.06"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M583.35 138.68H585.27"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M583.35 140.12V138.68"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M579.98 140.12H583.35"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M579.98 140.12V141.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M579.98 145.09V146.53"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M585.59 138.04V138.68"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M583.35 138.68 583.67 139"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M583.67 139V140.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M583.67 140.12H583.35"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M578.06 147.01V147.33"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M578.06 147.33H577.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M578.06 147.01H578.38"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M578.06 147.33C578.22 147.33 578.38 147.17 578.38 147.01"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M578.06 146.53 578.38 146.85"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M578.38 146.85V147.01"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M579.98 146.85H578.38"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M579.98 146.53V146.85"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M585.27 139H583.67"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M583.35 146.85C583.51 146.85 583.67 146.69 583.67 146.53"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M579.98 146.21H583.19"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M583.19 146.21V143.64"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M583.19 142.52V141.56"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M583.19 141.56H581.43"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M583.67 146.53V143.64"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M583.67 142.52V140.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M579.98 146.85H583.35"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M582.87 141.88V142.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M582.87 143.64V146.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M581.91 146.05H582.87"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M581.91 141.88V142.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M581.91 143.64V146.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M582.87 141.88H581.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M581.43 142.52V142.04"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M581.43 141.88H581.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M581.91 146.05H579.98"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M581.43 145.73V144.61"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M581.43 144.45V143.64"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M579.98 145.73H581.43"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M580.47 145.57C580.79 145.57 580.95 145.41 580.95 145.25 580.95 145.09 580.79 144.93 580.63 144.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M580.3 144.93C580.14 145.09 580.14 145.25 580.3 145.41 580.3 145.57 580.47 145.57 580.47 145.57"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M583.67 142.04V142.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M583.67 143.64V145.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M583.67 144.61V145.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M583.67 142.36V142.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M583.67 143.64V144.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M586.87 142.2V144.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M586.87 144.12H586.71"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M586.71 144.12V142.2"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M586.71 142.2H586.87"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M592 141.88 591.84 142.2"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M591.84 141.88V142.2"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M592 141.88H591.84"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M592 141.88 593.92 142.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M593.92 142.52V142.84"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M593.92 142.84 591.84 142.2"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M591.84 144.12 593.92 143.48"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M586.87 144.12H591.84"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M591.84 142.2H586.87"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M593.92 143.48V142.84"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M586.87 141.88V142.2"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M586.87 141.88C586.71 141.88 586.71 142.04 586.71 142.2"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M586.87 141.88H591.84"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M593.92 143.48V143.8"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M593.92 143.8 592 144.45"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M591.84 144.12 592 144.45"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M591.84 144.45H592"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M591.84 144.12V144.45"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M594.09 142.84H593.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M594.09 142.84V143.48"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M593.92 143.48H594.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M586.87 144.12V144.45"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M586.71 144.12C586.71 144.29 586.71 144.45 586.87 144.45"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M594.09 142.84C594.09 142.68 594.09 142.52 593.92 142.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M593.92 143.8C594.09 143.8 594.09 143.64 594.09 143.48"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M591.84 144.45H586.87"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M585.27 142.2H584.95"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M584.95 142.2V144.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M584.95 144.12H585.27"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M584.63 142.2H584.47"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M584.47 144.12H584.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M585.27 144.12V142.2"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M584.63 144.12V142.2"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M584.15 143.16V142.2"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M584.15 144.12V143.16"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M584.47 142.2V144.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M596.49 142.84H594.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M596.49 143.48H594.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M596.49 142.84V143.48"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M584.47 143.64H584.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M584.63 142.68H584.47"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M584.95 143.64H585.27"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M585.27 142.68H584.95"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M414.46 130.35H407.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M413.98 129.39H407.57"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M412.06 128.59H409.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M412.06 128.43H409.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M412.06 128.59H409.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M422.79 130.35H415.58"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M422.31 129.39H415.9"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M420.39 128.59H417.83"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M420.39 128.59H417.83"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M420.39 128.43H417.83"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M446.19 130.35H438.82"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M445.71 129.39H439.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.78 128.59H441.22"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.78 128.43H441.22"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.78 128.59H441.22"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M455.8 130.35H448.59"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M455.48 129.39H449.07"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M453.4 128.59H450.99"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M453.4 128.43H450.99"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M453.4 128.59H450.99"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M428.4 311.37H428.72"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M422.79 311.37H423.11"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M423.11 311.37H425.68"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.68 311.37H428.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.68 313.61H427.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.23 313.61H425.68"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M428.4 311.37H428.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M428.56 311.37H428.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M423.27 311.37V311.37H423.11"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.68 311.37V311.37H425.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.84 311.37H425.68"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M420.71 311.37V311.37H420.55"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M415.42 311.37H417.99"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M417.99 311.37H417.83"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M417.99 311.37H420.55"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.15 311.37H417.99"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M415.42 311.37H415.26"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M417.99 313.61H419.43"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M416.38 313.61H417.99"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M420.55 311.37H421.03"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M414.94 311.37H415.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M410.13 311.37V311.37H409.97"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M410.29 311.37H410.13"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M407.73 311.37V311.37H407.57"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M412.86 311.37H412.7"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M410.13 311.37H412.86"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M407.57 311.37H410.13"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M412.86 311.37H413.18"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M407.09 311.37H407.57"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M410.13 313.61H411.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M408.69 313.61H410.13"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M391.87 657.39H392.19"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M392.19 658.83H391.87"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M391.87 658.83V657.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M392.19 554.54H391.87"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M391.87 554.54V553.1"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M391.87 553.1H392.19"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M391.87 554.54V657.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M564.12 550.54V542.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M563.48 550.54V542.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M564.12 550.54H563.48"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M564.12 542.21H563.48"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M553.06 550.54V542.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M552.42 550.54V542.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M553.06 550.54H552.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M553.06 542.21H552.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M555.47 550.54V542.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M555.47 550.54H553.06"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M555.47 542.21H553.06"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M563.48 550.54H561.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M561.08 550.54V542.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M563.48 542.21H561.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M565.08 542.21H564.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M566.2 543.65C566.36 542.85 565.88 542.37 565.08 542.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M566.2 549.26V543.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M565.08 550.54C565.88 550.54 566.36 549.9 566.2 549.26"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M565.08 550.54H564.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M551.3 542.21C550.66 542.37 550.18 542.85 550.18 543.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M552.42 542.21H551.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M552.42 550.54H551.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M550.18 549.26C550.18 549.9 550.66 550.54 551.3 550.54"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M550.18 549.26V543.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M555.63 550.54V542.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M555.63 550.54H555.47"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M555.63 542.21H555.47"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M561.08 550.54H560.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M560.92 550.54V542.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M561.08 542.21H560.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 418.04251098632807, 409.7774963378905)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.48 .32-.8 .8-.8 1.28-.8 1.76-.48 1.76 0 1.76 .48 1.28 .96 .8 .96 .32 .96 0 .48 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M560.92 542.21H555.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M560.92 550.54H555.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M553.06 425.43V417.1"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M553.06 425.43H552.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M552.42 425.43V417.1"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M553.06 417.1H552.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M564.12 425.43V417.1"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M563.48 425.43V417.1"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M564.12 417.1H563.48"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M564.12 425.43H563.48"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M555.47 425.43V417.1"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M555.47 417.1H553.06"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M555.47 425.43H553.06"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M563.48 425.43H561.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M561.08 425.43V417.1"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M563.48 417.1H561.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M566.2 418.38C566.36 417.74 565.88 417.1 565.08 417.1"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M566.2 423.98V418.38"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M565.08 425.43C565.88 425.27 566.36 424.79 566.2 423.98"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M565.08 425.43H564.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M565.08 417.1H564.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M551.3 417.1C550.66 417.1 550.18 417.74 550.18 418.38"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M552.42 425.43H551.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M550.18 423.98C550.18 424.79 550.66 425.27 551.3 425.43"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M550.18 423.98V418.38"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M552.42 417.1H551.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M555.63 425.43V417.1"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M555.63 425.43H555.47"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M555.63 417.1H555.47"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M561.08 425.43H560.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M560.92 425.43V417.1"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M561.08 417.1H560.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M560.92 417.1H555.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 418.04251098632807, 315.94500732421875)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.48 .32-.96 .8-.96 1.28-.96 1.76-.48 1.76 0 1.76 .48 1.28 .8 .8 .8 .32 .8 0 .48 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M560.92 425.43H555.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 418.04251098632807, 315.94500732421875)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.48 .32-.8 .8-.8 1.28-.8 1.6-.48 1.6 0 1.6 .48 1.28 .8 .8 .8 .32 .8 0 .48 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 418.04251098632807, 409.7774963378905)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.48 .32-.8 .8-.8 1.28-.8 1.6-.48 1.6 0 1.6 .48 1.28 .8 .8 .8 .32 .8 0 .48 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M505.8 402.84C505.96 404.12 506.28 405.4 506.92 406.68"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M506.92 394.35C506.28 395.63 505.96 397.07 505.8 398.51"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 378.3825073242187, 306.4574890136719)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0-.48 .64-1.12 1.28-1.76 1.76-2.4 2.24-3.2 2.56-3.84 2.88-4.64 3.04H-5.44-6.09L-6.89 2.88-7.53 2.56-8.33 2.24-8.97 1.76-9.61 1.28-10.25 .64-10.73 0-11.21-.81-11.69-1.61-12.17-2.57-12.5-3.53-12.82-4.49-12.98-5.45-13.14-6.57V-7.53-8.65-9.62L-12.98-10.74-12.82-11.7-12.5-12.66-12.17-13.62-11.69-14.58-11.21-15.38-10.73-16.18-10.25-16.82-9.61-17.47-8.97-17.95-8.33-18.43-7.53-18.75-6.89-19.07-6.09-19.23H-5.44-4.64L-3.84-19.07-3.2-18.75-2.4-18.43-1.76-18.11-1.12-17.47-.48-16.98 0-16.18"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 378.3825073242187, 294.322509765625)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0 .96 .64 1.77 1.28 2.57 2.08 3.21 2.88 3.69 3.68 4.17 4.48 4.49 5.44 4.81 6.24 4.97 7.2V8.01 8.97L4.81 9.93 4.49 10.73 4.17 11.69 3.69 12.49 3.21 13.29 2.57 14.09 1.77 14.89 .96 15.54 0 16.18"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M504.51 590.91V408.61"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M504.51 392.43V311.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M611.87 404.76V311.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 458.90249633789057, 315.3450012207031)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0-.8-.8-1.6-1.44-2.24-2.24-2.88-3.04-3.36-3.84-3.84-4.81-4.16-5.61-4.33-6.57-4.49-7.37V-8.33L-4.33-9.29-4.16-10.09-3.84-11.05-3.36-11.85-2.88-12.82-2.24-13.62-1.6-14.26-.8-15.06 0-15.7"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M611.87 590.91V420.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 410.35501098632807, 409.7774963378905)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-6.08 4.96-11.05 11.05-11.05 17.3-11.05 22.27-6.08 22.27 0 22.27 6.25 17.3 11.22 11.05 11.22 4.96 11.22 0 6.25 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M611.87 590.91H504.51"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 410.35501098632807, 315.94500732421875)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-6.25 4.96-11.21 11.05-11.21 17.3-11.21 22.27-6.25 22.27 0 22.27 6.09 17.3 11.05 11.05 11.05 4.96 11.05 0 6.09 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M476.15 590.91V312.97"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M476.15 312.97 477.75 311.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M504.51 590.91H476.15"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 458.90249633789057, 303.57000732421875)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0 .64-.64 1.12-1.28 1.76-1.92 2.57-2.4 3.21-2.72 3.85-3.04 4.65-3.2 5.45-3.36H6.09L6.89-3.2 7.53-3.04 8.33-2.72 8.97-2.24 9.62-1.76 10.26-1.28 10.9-.48 11.38 .16 11.86 .96 12.34 1.76 12.66 2.72 12.98 3.69 13.14 4.65 13.46 5.77V6.73 7.85 8.81 9.93L13.14 10.89 12.98 11.86 12.66 12.82 12.34 13.78 11.86 14.58 11.38 15.38 10.9 16.18 10.26 16.82 9.62 17.46 8.97 17.94 8.33 18.26 7.53 18.58 6.89 18.74 6.09 18.9H5.45 4.65L3.85 18.58 3.21 18.42 2.57 17.94 1.76 17.46 1.12 16.98 .64 16.34 0 15.7"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M633.18 590.91H631.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M628.54 590.91H611.87"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M505.15 401.56 507.72 405.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M506.28 397.07 505.15 401.56"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M507.88 396.27 508.2 395.95"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M584.31 593.47V593.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M584.31 593.63 583.99 593.79 583.67 593.95"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M582.87 593.63C583.03 593.79 583.35 593.95 583.67 593.95"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M582.87 593.47V593.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M581.27 591.23H581.75L582.71 591.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M583.67 593.95H581.27"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M582.87 593.63 581.75 593.95H581.27"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M581.27 593.95 580.14 593.79 579.82 593.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M579.82 591.39V593.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M579.82 591.39H580.14L581.27 591.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M579.82 591.39C579.5 591.23 579.34 591.23 579.02 591.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M579.82 593.63 579.34 593.79 579.02 593.95"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M578.22 593.63C578.54 593.79 578.7 593.95 579.02 593.95"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M578.22 591.39V593.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M578.22 591.39 578.7 591.23H579.02"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M579.02 593.95H581.27"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M578.7 593.95H579.02"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M583.67 593.95H583.99"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M532.55 593.95H531.59"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M532.39 593.79 532.07 593.95H531.59"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M531.59 591.23H532.07 532.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M531.59 593.95 530.47 593.79 530.15 593.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M530.15 591.39V593.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M530.15 591.39H530.47L531.59 591.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M529.35 593.95H531.59"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M530.15 593.63 529.67 593.79 529.35 593.95"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M530.15 591.39C529.83 591.23 529.51 591.23 529.35 591.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M528.55 593.63C528.71 593.79 529.03 593.95 529.35 593.95"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M528.55 591.39V593.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M528.55 591.39 528.87 591.23H529.35"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M529.03 593.95H529.35"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M536.72 593.63C536.88 593.79 537.2 593.95 537.52 593.95"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M537.52 593.95H535.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M536.72 593.63 535.6 593.95H535.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M536.72 591.39 537.04 591.23H537.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M535.12 591.23H535.6L536.72 591.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M536.72 591.39V593.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M535.12 593.95 534 593.79 533.68 593.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M533.68 591.39V593.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M533.68 591.39H534L535.12 591.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M538.16 591.39C538 591.23 537.68 591.23 537.52 591.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M538.16 591.39V593.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M538.16 593.63 537.84 593.79 537.52 593.95"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M533.68 591.39C533.36 591.23 533.2 591.23 532.88 591.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M533.68 593.63 533.2 593.79 532.88 593.95"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M532.07 593.63C532.39 593.79 532.55 593.95 532.88 593.95"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M532.07 591.39V593.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M532.07 591.39 532.55 591.23H532.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M532.88 593.95H535.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M532.55 593.95H532.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M537.52 593.95H537.84"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M546.5 591.39 546.82 591.23H547.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M544.89 591.23H545.37L546.5 591.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M546.5 591.39V593.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M546.5 593.63 545.37 593.95H544.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M544.89 593.95 543.77 593.79 543.45 593.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M543.45 591.39V593.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M543.45 591.39H543.77L544.89 591.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M546.5 593.63C546.66 593.79 546.98 593.95 547.14 593.95"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M547.14 593.95H544.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M547.94 591.39C547.78 591.23 547.46 591.23 547.14 591.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M547.94 591.39V593.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M547.94 593.63 547.62 593.79 547.14 593.95"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M543.45 591.39C543.13 591.23 542.97 591.23 542.65 591.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M543.45 593.63 542.97 593.79 542.65 593.95"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M541.85 593.63C542.17 593.79 542.33 593.95 542.65 593.95"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M541.85 591.39V593.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M541.85 591.39 542.17 591.23H542.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M542.65 593.95H544.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M547.14 593.95H547.62"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M542.33 593.95H542.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M559.79 591.39 560.12 591.23H560.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M558.19 591.23H558.67L559.79 591.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M559.79 593.63C559.95 593.79 560.28 593.95 560.6 593.95"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M560.6 593.95H558.19"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M559.79 593.63 558.67 593.95H558.19"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M561.24 591.39C561.08 591.23 560.76 591.23 560.6 591.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M561.24 591.39V593.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M561.24 593.63 560.92 593.79 560.6 593.95"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M559.79 591.39V593.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M558.19 593.95 557.07 593.79 556.75 593.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M556.75 591.39V593.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M556.75 591.39H557.07L558.19 591.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M556.75 591.39C556.43 591.23 556.27 591.23 555.95 591.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M556.75 593.63 556.27 593.79 555.95 593.95"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M555.15 593.63C555.47 593.79 555.63 593.95 555.95 593.95"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M555.15 591.39V593.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M555.15 591.39 555.63 591.23H555.95"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M555.95 593.95H558.19"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M555.63 593.95H555.95"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M560.6 593.95H560.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M586.39 593.47V593.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M586.39 593.63 585.27 593.95H584.95"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M584.95 593.95 584.15 593.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M586.39 593.63C586.55 593.79 586.87 593.95 587.2 593.95"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M587.2 593.95H584.95"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M588 593.47V593.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M588 593.63 587.52 593.79 587.2 593.95"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M583.99 593.95H584.95"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M587.2 593.95H587.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M574.54 591.39C574.38 591.23 574.06 591.23 573.9 591.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M574.54 591.39V593.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M574.54 593.63 574.22 593.79 573.9 593.95"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M573.09 593.63C573.25 593.79 573.57 593.95 573.9 593.95"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M573.09 591.39V593.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M573.09 591.39 573.41 591.23H573.9"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M571.49 591.23H571.97L573.09 591.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M573.9 593.95H571.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M573.09 593.63 571.97 593.95H571.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M571.49 593.95 570.37 593.79 570.05 593.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M570.05 591.39V593.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M570.05 591.39H570.37L571.49 591.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M569.25 593.95H571.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M570.05 593.63 569.73 593.79 569.25 593.95"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M570.05 591.39C569.73 591.23 569.57 591.23 569.25 591.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M568.45 593.63C568.77 593.79 568.93 593.95 569.25 593.95"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M568.45 591.39V593.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M568.45 591.39 568.93 591.23H569.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M568.93 593.95H569.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M573.9 593.95H574.22"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M583.19 593.95H586.55"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M583.51 594.27H586.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M579.98 594.27H582.71"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M579.66 593.95H583.03"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M570.21 594.27H572.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M569.89 593.95H573.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M556.59 593.95H559.95"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M556.91 594.27H559.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M533.52 593.95H536.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M533.84 594.27H536.56"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M529.99 593.95H533.2"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M530.15 594.27H533.04"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M543.45 594.27H546.33"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M543.29 593.95H546.66"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 404.69998168945307, 451.1099853515624)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H.33 .97 1.61L2.41 .16H3.37 4.17 4.97 5.77 6.25 6.57L6.73 .32"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 404.69998168945307, 451.47003173828114)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0L.33 .16H.97 1.61 2.41 3.37 4.17 4.97L5.77 .32H6.25 6.57 6.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 404.69998168945307, 451.949981689453)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H.33 .97 1.61 2.41L3.37 .16H4.17 4.97 5.77 6.25 6.57 6.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 404.69998168945307, 452.30999755859364)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H.33L.97 .16H1.61 2.41 3.37 4.17 4.97 5.77L6.25 .32H6.57 6.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 404.69998168945307, 452.78997802734364)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H.33 .97 1.61 2.41L3.37 .16H4.17 4.97 5.77 6.25 6.57 6.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 404.69998168945307, 453.1500244140624)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H.33L.97 .16H1.61 2.41 3.37 4.17 4.97 5.77L6.25 .32H6.57 6.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 404.69998168945307, 453.6300048828124)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H.33 .97 1.61 2.41 3.37L4.17 .16H4.97 5.77 6.25 6.57 6.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M539.6 601.48 539.28 601.16"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 404.4600219726562, 450.8699951171874)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0H.16 .49L1.13 .16H1.77 2.73 3.69 4.65 5.45 6.25L6.73 .32H7.22"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M546.5 601.96C544.09 601.96 541.85 601.8 539.44 601.8"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M546.5 602.6C544.09 602.44 541.85 602.44 539.44 602.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M546.5 603.08C544.09 603.08 541.85 602.92 539.44 602.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M546.5 603.72C544.09 603.56 541.85 603.56 539.44 603.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M546.5 604.2C544.09 604.2 541.85 604.04 539.44 604.04"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M546.5 604.84C544.09 604.68 541.85 604.52 539.44 604.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M539.44 605H539.28L539.44 605.16"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M539.28 601.16 539.6 600.84"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 404.69998168945307, 450.6300048828124)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V.16H.33 .97 1.61 2.41 3.37L4.17 .32H4.97 5.77 6.25 6.57 6.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 404.69998168945307, 451.1099853515624)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H.33 .97L1.61 .16H2.41 3.37 4.17 4.97 5.77 6.25L6.57 .32H6.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 404.69998168945307, 451.47003173828114)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V.16H.33 .97 1.61 2.41 3.37 4.17L4.97 .32H5.77 6.25 6.57 6.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 404.69998168945307, 451.949981689453)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H.33 .97 1.61L2.41 .16H3.37 4.17 4.97 5.77 6.25L6.57 .32H6.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 404.69998168945307, 452.30999755859364)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V.16H.33 .97 1.61 2.41 3.37 4.17L4.97 .32H5.77 6.25 6.57 6.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 404.69998168945307, 452.78997802734364)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H.33 .97 1.61L2.41 .16H3.37 4.17 4.97 5.77 6.25 6.57L6.73 .32"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 404.69998168945307, 453.1500244140624)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0L.33 .16H.97 1.61 2.41 3.37 4.17 4.97L5.77 .32H6.25 6.57 6.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 404.69998168945307, 453.6300048828124)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H.33 .97 1.61L2.41 .16H3.37 4.17 4.97 5.77 6.25 6.57L6.73 .32"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M539.44 605.16H546.33"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M546.66 600.84H539.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M546.66 596.67H539.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M582.55 591.23H582.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M589.6 591.39C587.36 591.39 584.95 591.23 582.55 591.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M582.55 591.71H582.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M589.6 592.03C587.36 591.87 584.95 591.87 582.55 591.71"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M582.55 592.19 582.39 592.35"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M589.6 592.51C587.36 592.51 584.95 592.35 582.55 592.35"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M582.55 592.83H582.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M589.6 593.15C587.36 592.99 584.95 592.99 582.55 592.83"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M582.55 593.31 582.39 593.47H582.55"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 437.0325317382812, 443.1824951171874)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H.32 .96L1.6 .16H2.4 3.36 4.32 5.13 5.77 6.25L6.57 .32H6.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 437.0325317382812, 443.542510986328)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V.16H.32 .96 1.6 2.4 3.36 4.32L5.13 .32H5.77 6.25 6.57 6.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 437.0325317382812, 444.02252197265614)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H.32 .96L1.6 .16H2.4 3.36 4.32 5.13 5.77 6.25L6.57 .32H6.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 437.0325317382812, 444.38250732421864)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V.16H.32 .96 1.6 2.4 3.36 4.32L5.13 .32H5.77 6.25 6.57 6.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 437.0325317382812, 444.86251831054676)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H.32 .96 1.6L2.4 .16H3.36 4.32 5.13 5.77 6.25 6.57L6.73 .32"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M582.55 593.47H589.44"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 437.15249633789057, 443.1824951171874)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0H.16 .8 1.44L2.24 .16H3.2 4.16 4.97 5.61 6.09 6.41L6.57 .32"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 437.0325317382812, 443.542510986328)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0L.32 .16H.96 1.6 2.4 3.36 4.32 5.13L5.77 .32H6.25 6.57 6.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 437.0325317382812, 444.02252197265614)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H.32 .96 1.6 2.4L3.36 .16H4.32 5.13 5.77 6.25 6.57 6.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 437.0325317382812, 444.38250732421864)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0L.32 .16H.96 1.6 2.4 3.36 4.32 5.13L5.77 .32H6.25 6.57 6.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 437.0325317382812, 444.86251831054676)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H.32 .96 1.6 2.4L3.36 .16H4.32 5.13 5.77 6.25 6.57 6.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 427.6575317382812, 451.1099853515624)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H.33 .97 1.61L2.41 .16H3.37 4.17 4.97 5.77 6.25 6.57L6.73 .32"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 427.6575317382812, 451.47003173828114)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0L.33 .16H.97 1.61 2.41 3.37 4.17 4.97L5.77 .32H6.25 6.57 6.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 427.6575317382812, 451.949981689453)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H.33 .97 1.61 2.41L3.37 .16H4.17 4.97 5.77 6.25 6.57 6.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 427.6575317382812, 452.30999755859364)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H.33L.97 .16H1.61 2.41 3.37 4.17 4.97 5.77L6.25 .32H6.57 6.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 427.6575317382812, 452.78997802734364)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H.33 .97 1.61 2.41L3.37 .16H4.17 4.97 5.77 6.25 6.57 6.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 427.6575317382812, 453.1500244140624)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H.33L.97 .16H1.61 2.41 3.37 4.17 4.97 5.77L6.25 .32H6.57 6.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 427.6575317382812, 453.6300048828124)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H.33 .97 1.61 2.41 3.37L4.17 .16H4.97 5.77 6.25 6.57 6.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M570.21 601.48 569.89 601.16"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 427.41751098632807, 450.8699951171874)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0H.16 .49L1.13 .16H1.77 2.73 3.69 4.65 5.45 6.25L6.73 .32H7.22"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M577.1 601.96C574.7 601.96 572.45 601.8 570.05 601.8"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M577.1 602.6C574.7 602.44 572.45 602.44 570.05 602.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M577.1 603.08C574.7 603.08 572.45 602.92 570.05 602.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M577.1 603.72C574.7 603.56 572.45 603.56 570.05 603.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M577.1 604.2C574.7 604.2 572.45 604.04 570.05 604.04"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M577.1 604.84C574.7 604.68 572.45 604.52 570.05 604.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M570.05 605H569.89L570.05 605.16"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M569.89 601.16 570.21 600.84"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 427.6575317382812, 450.6300048828124)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V.16H.33 .97 1.61 2.41 3.37L4.17 .32H4.97 5.77 6.25 6.57 6.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 427.6575317382812, 451.1099853515624)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H.33 .97L1.61 .16H2.41 3.37 4.17 4.97 5.77 6.25L6.57 .32H6.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 427.6575317382812, 451.47003173828114)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V.16H.33 .97 1.61 2.41 3.37 4.17L4.97 .32H5.77 6.25 6.57 6.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 427.6575317382812, 451.949981689453)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H.33 .97 1.61L2.41 .16H3.37 4.17 4.97 5.77 6.25L6.57 .32H6.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 427.6575317382812, 452.30999755859364)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V.16H.33 .97 1.61 2.41 3.37 4.17L4.97 .32H5.77 6.25 6.57 6.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 427.6575317382812, 452.78997802734364)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H.33 .97 1.61L2.41 .16H3.37 4.17 4.97 5.77 6.25 6.57L6.73 .32"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 427.6575317382812, 453.1500244140624)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0L.33 .16H.97 1.61 2.41 3.37 4.17 4.97L5.77 .32H6.25 6.57 6.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 427.6575317382812, 453.6300048828124)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H.33 .97 1.61L2.41 .16H3.37 4.17 4.97 5.77 6.25 6.57L6.73 .32"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M570.05 605.16H576.94"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M577.26 600.84H569.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M577.26 596.67H569.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M559.79 599.24H559.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M553.39 596.51H558.19"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M553.39 596.51V594.27"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M558.19 596.51V594.27"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M558.19 596.67H553.71"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M553.39 596.51C553.39 596.67 553.55 596.67 553.71 596.67"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M558.19 596.67V596.51"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M563.16 594.27C563.16 594.11 563 593.95 562.84 593.95"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M558.19 596.51H563.16"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M563.16 596.51V594.27"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M562.84 596.67H558.19"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M562.84 596.67C563 596.67 563.16 596.67 563.16 596.51"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M553.71 593.95C553.55 593.95 553.39 594.11 553.39 594.27"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M563.8 593.95H559.95"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M556.59 593.95H552.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M590.88 591.23H589.44"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M582.55 591.23H525.5"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M548.74 552.14H567.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M640.55 669.88V668.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M640.55 669.88H394.11"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M394.11 668.28V669.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M640.55 668.28H394.11"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M392.35 663.47V668.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M392.35 668.28H394.11"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M398.76 658.83H635.91"
    />
    <g
      clipPath="url(#clip_11)"
      transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -5.684341886080802e-14, 0)"
    >
      <path
        transform="matrix(.75,0,0,.75,0,0)"
        strokeWidth={0.801}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="#040503"
        d="M642.32 663.47V668.28"
      />
      <path
        transform="matrix(.75,0,0,.75,0,0)"
        strokeWidth={0.801}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="#040503"
        d="M640.55 668.28H642.32"
      />
    </g>
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M392.35 668.28C392.35 669.08 393.15 669.88 394.11 669.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M393.31 668.28C393.31 668.76 393.63 669.08 394.11 669.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M394.11 669.88V669.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M640.55 669.88V669.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M640.55 669.08C641.03 669.08 641.35 668.76 641.35 668.28"
    />
    <g
      clipPath="url(#clip_12)"
      transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -5.684341886080802e-14, 0)"
    >
      <path
        transform="matrix(.75,0,0,.75,0,0)"
        strokeWidth={0.801}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="#040503"
        d="M640.55 669.88C641.52 669.88 642.32 669.08 642.32 668.28"
      />
    </g>
    <g
      clipPath="url(#clip_13)"
      transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -5.684341886080802e-14, 0)"
    >
      <path
        transform="matrix(.75,0,0,.75,0,0)"
        strokeWidth={0.801}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="#040503"
        d="M485.12 672.13H476.15"
      />
    </g>
    <g
      clipPath="url(#clip_14)"
      transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -5.684341886080802e-14, 0)"
    >
      <path
        transform="matrix(.75,0,0,.75,0,0)"
        strokeWidth={0.801}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="#040503"
        d="M404.21 672.13H395.39"
      />
    </g>
    <g
      clipPath="url(#clip_15)"
      transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -5.684341886080802e-14, 0)"
    >
      <path
        transform="matrix(.75,0,0,.75,0,0)"
        strokeWidth={0.801}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="#040503"
        d="M639.27 672.13H630.46"
      />
    </g>
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M631.42 671.16H629.66"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M396.19 671.16H394.43"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M477.11 671.16H475.35"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M462.85 267.79V139.96"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 345.69750976562494, 163.71749877929685)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .16-.64 .48-.64 .8-.64 .96-.32 .96 0 .96 .17 .8 .49 .48 .49 .16 .49 0 .17 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 343.1699829101562, 164.92498779296872)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .16-.48 .48-.48 .81-.48 .97-.32 .97 0 .97 .16 .81 .48 .48 .48 .16 .48 0 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 345.69750976562494, 195.80250549316403)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .16-.64 .48-.64 .8-.64 .96-.32 .96 0 .96 .17 .8 .49 .48 .49 .16 .49 0 .17 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 345.93749999999994, 116.38499450683594)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .32-.48 .64-.48 1.12-.48 1.28-.32 1.28 0 1.28 .48 1.12 .64 .64 .64 .32 .64 0 .48 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 345.93749999999994, 153.99000549316403)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .32-.64 .64-.64 1.12-.64 1.28-.32 1.28 0 1.28 .32 1.12 .64 .64 .64 .32 .64 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M462.85 139.96C462.85 139.16 462.21 138.52 461.41 138.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M457.4 138.52H461.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M456.92 138.52V267.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M457.4 138.52H456.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M400.2 139.96V267.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M401.64 138.52C400.84 138.52 400.2 139.16 400.2 139.96"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M401.64 138.52H405.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 300.3899841308593, 164.92498779296872)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .16-.48 .48-.48 .81-.48 .97-.32 .97 0 .97 .16 .81 .48 .48 .48 .16 .48 0 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 300.3899841308593, 199.88250732421872)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.16 .16-.32 .48-.32 .8-.32 .96-.16 .96 0 .96 .32 .8 .48 .48 .48 .16 .48 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 300.3899841308593, 106.29000091552734)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.16 .16-.48 .48-.48 .8-.48 .96-.16 .96 0 .96 .32 .8 .48 .48 .48 .16 .48 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 302.6699829101562, 154.3500061035156)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.16 .16-.32 .48-.32 .8-.32 .96-.16 .96 0 .96 .32 .8 .48 .48 .48 .16 .48 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M405.97 138.52V267.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M405.97 138.52H405.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M456.92 138.04V138.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M457.24 138.04V138.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M405.97 138.04V138.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M405.81 138.04V138.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 323.3475036621093, 170.5724945068359)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .32-.64 .64-.64 .96-.64 1.28-.32 1.28 0 1.28 .32 .96 .64 .64 .64 .32 .64 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 323.3475036621093, 165.40499877929685)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.48 .32-.8 .64-.8 .96-.8 1.28-.48 1.28 0 1.28 .32 .96 .64 .64 .64 .32 .64 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 307.2374877929687, 195.80250549316403)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .16-.48 .32-.48 .64-.48 .8-.32 .8 0 .8 .16 .64 .48 .32 .48 .16 .48 0 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 307.2374877929687, 193.15499877929685)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .16-.48 .32-.48 .64-.48 .8-.32 .8 0 .8 .32 .64 .48 .32 .48 .16 .48 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 307.2374877929687, 190.5149993896484)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.16 .16-.48 .32-.48 .64-.48 .8-.16 .8 0 .8 .32 .64 .48 .32 .48 .16 .48 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 307.2374877929687, 187.98748779296872)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .16-.48 .32-.48 .64-.48 .8-.32 .8 0 .8 .16 .64 .32 .32 .32 .16 .32 0 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 307.2374877929687, 185.34750366210935)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .16-.48 .32-.48 .64-.48 .8-.32 .8 0 .8 .32 .64 .48 .32 .48 .16 .48 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 339.3300170898437, 195.80250549316403)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .32-.48 .48-.48 .8-.48 .96-.32 .96 0 .96 .16 .8 .48 .48 .48 .32 .48 0 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 339.3300170898437, 193.15499877929685)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .32-.48 .48-.48 .8-.48 .96-.32 .96 0 .96 .32 .8 .48 .48 .48 .32 .48 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 339.3300170898437, 190.5149993896484)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.16 .32-.48 .48-.48 .8-.48 .96-.16 .96 0 .96 .32 .8 .48 .48 .48 .32 .48 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 339.3300170898437, 187.98748779296872)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .32-.48 .48-.48 .8-.48 .96-.32 .96 0 .96 .16 .8 .32 .48 .32 .32 .32 0 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 339.3300170898437, 185.34750366210935)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .32-.48 .48-.48 .8-.48 .96-.32 .96 0 .96 .32 .8 .48 .48 .48 .32 .48 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 334.7625122070312, 165.52499389648435)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .16-.48 .48-.48 .8-.48 .96-.32 .96 0 .96 .32 .8 .48 .48 .48 .16 .48 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 311.6849975585937, 165.52499389648435)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .32-.48 .64-.48 .8-.48 1.12-.32 1.12 0 1.12 .32 .8 .48 .64 .48 .32 .48 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 323.22000122070307, 177.89999389648435)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.48 .32-.8 .8-.8 1.28-.8 1.76-.48 1.76 0 1.76 .48 1.28 .96 .8 .96 .32 .96 0 .48 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 323.22000122070307, 176.4599914550781)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .33-.81 .81-.81 1.29-.81 1.61-.32 1.61 0 1.61 .48 1.29 .96 .81 .96 .33 .96 0 .48 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 323.22000122070307, 175.13249206542966)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.48 .32-.96 .8-.96 1.28-.96 1.76-.48 1.76 0 1.76 .48 1.28 .8 .8 .8 .32 .8 0 .48 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 323.22000122070307, 173.69250488281247)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.48 .32-.8 .8-.8 1.28-.8 1.6-.48 1.6 0 1.6 .48 1.28 .8 .8 .8 .32 .8 0 .48 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.57 240.88C432.57 240.4 432.09 240.08 431.61 240.08 431.29 240.24 430.96 240.56 430.96 240.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 323.22000122070307, 179.3399963378906)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.48 .32-.8 .8-.8 1.28-.8 1.6-.48 1.6 0 1.6 .48 1.28 .8 .8 .8 .32 .8 0 .48 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 312.52502441406244, 157.22999572753903)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-1.44 1.29-2.56 2.73-2.56 4.17-2.56 5.29-1.44 5.29 0 5.29 1.45 4.17 2.73 2.73 2.73 1.29 2.73 0 1.45 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 312.52502441406244, 171.89250183105466)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-1.44 1.29-2.73 2.73-2.73 4.17-2.73 5.29-1.44 5.29 0 5.29 1.44 4.17 2.56 2.73 2.56 1.29 2.56 0 1.44 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 323.3475036621093, 154.95001220703122)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.48 .32-.8 .64-.8 .96-.8 1.28-.48 1.28 0 1.28 .32 .96 .64 .64 .64 .32 .64 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 323.3475036621093, 160.11750793457028)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .32-.64 .64-.64 .96-.64 1.28-.32 1.28 0 1.28 .48 .96 .8 .64 .8 .32 .8 0 .48 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 313.1325073242187, 111.57749938964844)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C-.49-1.12-1.93-1.6-2.89-.96-4.01-.48-4.49 .96-3.85 2.08-3.37 3.04-1.93 3.52-.81 3.04-.49 2.72-.17 2.4 0 2.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.67 267.79V265.71"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.67 265.71H612.19"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.19 265.71V267.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.19 259.14H612.67"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.67 259.14V253.54"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.67 253.54H612.19"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.19 253.54V259.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.19 246.81V267.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.19 253.06H612.67"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.67 253.06V247.45"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.67 247.45H612.19"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.19 247.45V253.06"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.19 265.23H612.67"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.67 265.23V259.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.67 259.63H612.19"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.19 259.63V265.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M630.94 246.81H630.62"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M631.42 256.9H631.26"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M631.42 261.87H631.26"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.51 265.71V267.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.51 253.54V259.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.51 247.45V253.06"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.51 259.63V265.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.19 267.79V265.71"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.19 265.71H612.03"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.03 265.71V267.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.19 259.14V253.54"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.19 253.54H612.03"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.03 253.54V259.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.03 259.14H612.19"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.19 253.06V247.45"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.19 247.45H612.03"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.03 247.45V253.06"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.03 253.06H612.19"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.19 265.23V259.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.19 259.63H612.03"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.03 259.63V265.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.03 265.23H612.19"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.03 265.71V267.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.03 253.54V259.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.03 247.45V253.06"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.03 259.63V265.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 457.3424682617187, 194.47499084472653)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .32-.48 .64-.48 1.12-.48 1.28-.32 1.28 0 1.28 .48 1.12 .64 .64 .64 .32 .64 0 .48 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M609.15 246.81V267.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M631.42 246.81H631.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 474.52502441406244, 192.43499755859372)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.16 .16-.48 .48-.48 .64-.48 .8-.16 .8 0 .8 .32 .64 .48 .48 .48 .16 .48 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M631.42 267.79V266.03"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M631.42 266.03H630.94"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M630.94 266.03V267.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M631.42 259.14V253.7"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M631.42 253.7H630.94"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M630.94 253.7V259.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M630.94 259.14H631.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M630.94 246.81V267.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M630.94 246.81H631.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M631.42 253.06V247.45"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M631.42 247.45H630.94"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M630.94 247.45V253.06"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M630.94 253.06H631.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M631.42 265.39V259.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M631.42 259.79H630.94"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M630.94 259.79V265.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M630.94 265.39H631.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M631.26 266.03V267.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M631.26 253.7V259.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M631.26 247.45V253.06"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M631.26 259.79V265.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M630.62 246.65V246.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M610.27 246.81H630.62"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M610.27 246.65V246.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M610.27 246.65H630.62"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M608.99 246.81V246.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M610.27 246.81H608.99"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M608.99 246.65H610.27"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M608.99 247.13V246.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.67 250.97H612.51"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.51 264.91H612.67"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.67 267.15H612.51"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.51 267.15V267.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.51 267.63H612.67"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M608.99 247.13V267.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 457.46252441406244, 194.47499084472653)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.16 .32-.32 .48-.32 .8-.32 .96-.16 .96 0 .96 .32 .8 .48 .48 .48 .32 .48 0 .32 0 0Z"
    />
    <g
      clipPath="url(#clip_16)"
      transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -5.684341886080802e-14, 0)"
    >
      <path
        transform="matrix(.75,0,0,.75,0,0)"
        strokeWidth={0.801}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="#040503"
        d="M642 268.28 642.32 268.12"
      />
    </g>
    <g
      clipPath="url(#clip_17)"
      transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -5.684341886080802e-14, 0)"
    >
      <path
        transform="matrix(.75,0,0,.75,0,0)"
        strokeWidth={0.801}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="#040503"
        d="M642.48 268.12H642.32"
      />
    </g>
    <g
      clipPath="url(#clip_18)"
      transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -5.684341886080802e-14, 0)"
    >
      <path
        transform="matrix(.75,0,0,.75,0,0)"
        strokeWidth={0.801}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="#040503"
        d="M642 268.28 642.48 268.12"
      />
    </g>
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M642 311.05V311.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M392.67 311.37H642"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M392.67 311.37V311.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M392.67 310.89H642"
    />
    <g
      clipPath="url(#clip_19)"
      transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -5.684341886080802e-14, 0)"
    >
      <path
        transform="matrix(.75,0,0,.75,0,0)"
        strokeWidth={0.801}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="#040503"
        d="M642.32 267.96 642 268.28"
      />
      <path
        transform="matrix(.75,0,0,.75,0,0)"
        strokeWidth={0.801}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="#040503"
        d="M642.32 267.79 642 268.28"
      />
      <path
        transform="matrix(.75,0,0,.75,0,0)"
        strokeWidth={0.801}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="#040503"
        d="M642.32 267.79V267.96"
      />
    </g>
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M392.67 268.28 392.51 267.96"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M392.51 267.79V267.96"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M392.67 268.28 392.51 267.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M642 310.89V268.28"
    />
    <g
      clipPath="url(#clip_20)"
      transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -5.684341886080802e-14, 0)"
    >
      <path
        transform="matrix(.75,0,0,.75,0,0)"
        strokeWidth={0.801}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="#040503"
        d="M642.48 311.05V268.12"
      />
    </g>
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M642 311.05V310.89"
    />
    <g
      clipPath="url(#clip_21)"
      transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -5.684341886080802e-14, 0)"
    >
      <path
        transform="matrix(.75,0,0,.75,0,0)"
        strokeWidth={0.801}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="#040503"
        d="M642.48 311.05H642"
      />
    </g>
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M392.67 310.89V311.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M392.19 268.12V311.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M392.19 268.12 392.67 268.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M392.67 268.28V310.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M392.67 311.05H392.19"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M392.35 268.12 392.67 268.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M392.19 268.12H392.35"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M642 268.28H392.67"
    />
    <g
      clipPath="url(#clip_22)"
      transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -5.684341886080802e-14, 0)"
    >
      <path
        transform="matrix(.75,0,0,.75,0,0)"
        strokeWidth={0.801}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="#040503"
        d="M642.32 267.79H392.51"
      />
      <path
        transform="matrix(.75,0,0,.75,0,0)"
        strokeWidth={0.801}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="#040503"
        d="M642.32 268.12H642.16"
      />
    </g>
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M392.51 268.12H392.35"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M578.22 146.21V140.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M581.43 141.56V141.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M581.43 144.77V141.56"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 360.95999145507807, 457.95748901367176)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .16-.48 .48-.48 .8-.48 .96-.32 .96 0 .96 .32 .8 .64 .48 .64 .16 .64 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M476.15 352.86V658.83"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M482.88 590.91V658.83"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M476.15 352.86H475.67"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M475.67 352.86V658.83"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M609.63 417.1C609.31 417.1 609.15 417.26 608.99 417.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 457.2225036621093, 309.697509765625)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0H-.32L-.48 .16-.64 .32-.8 .64V.96 1.28L-.64 1.6-.48 1.92H-.32L0 2.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M608.67 417.1C609.15 416.3 608.99 415.49 608.51 414.85"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M608.51 414.85C608.35 414.69 608.03 414.69 608.03 414.85H607.87"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M607.87 414.85 607.71 415.01"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.03 405.08H611.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.03 405.08H611.55L611.39 405.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 456.8625183105468, 305.6100158691406)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0-.16 .32-.32 .97V1.61 2.25L-.16 2.89 0 3.53 .16 4.01 .48 4.49 .8 4.97 1.28 5.29 1.6 5.61 2.08 5.77 2.56 5.93H2.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 462.2699890136718, 313.18499755859375)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0 .32-.64 .64-1.45 .96-2.25 1.12-3.05 1.28-3.85 1.44-4.65V-5.61L1.28-6.41 1.12-7.21 .96-8.01 .64-8.81 .16-9.61-.16-10.26-.64-10.74-1.28-11.22-1.76-11.7-2.41-12.02-3.05-12.34-3.69-12.5H-4.33"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M616.36 417.58V417.26"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M616.36 417.26C616.84 416.46 616.68 415.49 616.2 414.85"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M616.2 414.85C616.04 414.69 615.72 414.69 615.56 414.85"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M615.56 414.85C615.08 415.49 614.92 416.46 615.4 417.26"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M615.4 417.26V418.54"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M611.39 419.98C612.83 420.14 614.44 419.66 615.4 418.54"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M611.71 405.08H611.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M616.36 417.58H615.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M615.24 415.17 615.4 415.33V415.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M615.4 416.62V416.94H615.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M614.11 404.28H612.35"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M614.27 404.28V404.28H614.11"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M615.24 416.94H615.4V416.62"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M615.4 415.49V415.33L615.24 415.17"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.19 407.8 611.87 407.32 611.55 406.84V406.36 405.88L611.71 405.4 611.87 405.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 459.5025024414062, 308.01751708984375)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0-.16 .16H-.48L-.64 0-.96-.16-1.12-.48-1.28-.8-1.44-1.12V-1.6-1.92L-1.28-2.24-.96-2.56-.8-2.72-.48-2.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M611.87 405.08 611.39 405.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 456.8625183105468, 305.6100158691406)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0L-.16 .65-.32 1.29V1.93 2.57L-.16 3.21 0 3.69 .32 4.33 .64 4.81 1.12 5.13 1.44 5.45 1.92 5.77 2.4 5.93H2.88 3.36L3.84 5.77 4.32 5.45 4.8 5.13 5.12 4.81 5.45 4.33 5.77 3.69 5.93 3.21 6.09 2.57V1.93 1.29L5.93 .65 5.77 0V-.16"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M614.76 407C614.27 406.04 613.31 405.24 612.19 405.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 457.94250488281244, 313.54498291015625)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V-.16L-.16-.48-.32-.64H-.64-.8L-.96-.48-1.12-.32V-.16 0"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M610.11 418.86C610.43 418.7 610.59 418.38 610.59 418.06"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 457.94250488281244, 310.4175109863281)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V-.16L-.16-.48-.32-.64H-.64-.8-.96L-1.12-.32V-.16 .16 .48L-.96 .64-.8 .8H-.64-.32L-.16 .48 0 .32V0"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M614.76 404.28H612.35"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M615.4 405.08C615.56 404.76 615.24 404.28 614.76 404.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M615.4 406.52V405.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 457.94250488281244, 313.54498291015625)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0-.16-.16V-.48L-.32-.64H-.64-.8L-.96-.48-1.12-.32V-.16 0"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M610.11 418.86C610.43 418.7 610.59 418.38 610.59 418.06"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 457.70251464843744, 313.54498291015625)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V-.16-.32H-.16-.32-.48L-.64-.16V0 .16L-.48 .32V.48H-.32-.16L0 .32V.16 0"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 457.94250488281244, 310.4175109863281)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0-.16-.16V-.48L-.32-.64H-.64-.8L-.96-.48-1.12-.32V-.16 .16 .48L-.96 .64-.8 .8H-.64L-.32 .64-.16 .48V.32L0 0"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 457.70251464843744, 310.4175109863281)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V-.16-.32H-.16-.32-.48L-.64-.16V0 .16L-.48 .32V.48H-.32-.16L0 .32V.16 0"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M609.79 418.54C609.95 418.38 609.95 418.22 609.95 418.06"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M609.95 418.06C609.95 417.9 609.95 417.9 609.79 417.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M609.79 414.21C609.95 414.21 609.95 414.05 609.95 413.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M609.95 413.89C609.95 413.73 609.95 413.73 609.79 413.57"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.19 405.08C612.03 405.08 612.03 405.08 611.87 405.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.67 407.8H612.19"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.19 410.85V410.69"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M613.31 407.8C613.15 407.8 612.99 407.8 612.83 407.8"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 459.6224975585937, 305.8499755859375)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0H-.32L-.64 .17-.8 .49-.96 .81-1.12 1.13V1.61 1.93L-.96 2.25-.8 2.57-.48 2.89H-.16 .16 .32L.64 2.73 .96 2.41 1.12 2.09V1.77 1.29 .97L.96 .65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M613.31 407.8H614.44"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M613.79 408.45 614.44 407.8"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.35 409.41H613.31"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M613.31 409.41V409.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M613.31 409.09H612.35"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.35 409.09V409.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.67 407.8 612.35 407.32 612.03 407 611.87 406.52 611.71 406.04V405.56L611.87 405.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M611.87 405.08H612.19"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.19 405.08 612.83 405.56 613.31 406.2 613.47 406.68V407.16L613.15 407.64 612.99 407.8"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 459.7424926757812, 305.8499755859375)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V-.16L.16-.32V-.48-.64L0-.8-.16-.96H-.32-.48V-.8L-.64-.64V-.48L-.48-.32V-.16H-.32V0"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M613.15 409.41V409.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.83 407.32C612.83 407.16 612.67 407 612.51 406.84"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.67 407.64C612.67 407.48 612.83 407.48 612.83 407.32"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M613.15 406.04H613.47"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M613.31 407.8C613.47 407.16 613.47 406.68 613.47 406.04"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M613.95 407.64C614.11 407.48 614.11 407.16 614.11 407"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M613.79 407.32C613.79 407.32 613.95 407.16 614.11 407"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M613.95 407.64C613.95 407.48 613.79 407.48 613.79 407.32"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M614.92 407.16C614.44 406.84 613.95 406.36 613.47 406.04"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M614.44 407.8C614.6 407.64 614.76 407.48 614.92 407.16"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M613.95 407.64 614.11 407"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M615.4 417.26H616.36"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M615.72 416.13C615.72 415.81 615.56 415.49 615.24 415.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M615.24 416.78C615.56 416.62 615.72 416.46 615.72 416.13"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M615.24 415.49V415.33"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M399.56 312.49H407.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M413.18 312.49H414.94"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M420.87 312.49H422.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M428.72 312.49H476.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M399.56 311.37V312.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M635.27 657.71V657.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M399.56 657.71V657.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M632.54 657.39H483.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M475.67 657.39H402.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M635.27 657.71H483.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M475.67 657.71H399.56"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 345.69750976562494, 195.80250549316403)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .16-.48 .48-.48 .64-.48 .96-.32 .96 0 .96 .16 .64 .48 .48 .48 .16 .48 0 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M510.12 215.89H515.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M515.41 215.89V216.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M510.12 215.89V216.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M514.93 215.89 513.65 214.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M513.81 214.93H513.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M513.81 214.93 515.25 215.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M510.44 215.89 511.72 214.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M511.72 214.93H511.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M511.88 214.93 510.76 215.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M513.81 214.93H513.97"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M515.25 215.89 513.97 214.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M511.72 214.93 510.28 215.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M513.65 214.93V215.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M511.88 214.93V215.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M513.49 215.09V215.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M512.04 215.89V215.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M511.88 214.93H513.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M512.04 215.09H513.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M511.24 214.93H511.72"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M513.97 214.93H514.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M626.29 174.56C626.29 174.72 626.29 174.72 626.29 174.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M523.58 173.92H523.26"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M523.58 173.92V215.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M523.26 215.73H523.58"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M523.26 173.92V215.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M523.1 216.05V216.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M523.1 216.05C523.26 216.05 523.26 215.89 523.26 215.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M523.1 216.21C523.26 216.21 523.58 216.05 523.58 215.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M519.09 216.21V216.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M519.09 216.05H523.1"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M526.47 173.44H523.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M523.74 173.6H525.98"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M523.74 173.6C523.58 173.6 523.58 173.76 523.58 173.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M523.74 173.44C523.58 173.44 523.26 173.6 523.26 173.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M549.54 151.49H549.86"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M549.86 151.49C549.86 151.33 549.7 151.17 549.54 151.01"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M549.54 151.49C549.54 151.33 549.54 151.33 549.38 151.33"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M548.74 151.01 547.78 151.17"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M549.38 151.33H547.78"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M547.78 151.33V151.17"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M547.78 151.33H547.62"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M547.78 151.17H547.62"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M549.86 152.13V151.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M549.7 153.1 549.54 151.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M549.7 153.1V153.26"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M601.3 255.62H608.99"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.51 255.62H612.67"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 446.88748168945307, 187.98748779296872)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V-.32L.16-.64 .48-.96 .8-1.12 1.12-1.28H1.44 1.76L2.08-1.12 2.24-.8 2.4-.48V-.16 .16L2.24 .48 2.08 .81 1.76 1.13 1.44 1.29H1.12 .8L.48 1.13 .16 .97 0 .64V.32 0"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 448.32751464843744, 189.67498779296872)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V.16L.32 .32 .8 .16 1.12 0 1.6-.32 1.76-.64 2.08-.96V-1.28-1.61L1.92-1.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 450.97497558593744, 191.7149963378906)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V-1.12L.16-2.24 .32-3.36 .48-4.33 .8-5.13 1.12-5.93 1.6-6.57 2.08-7.37 2.72-8.01 3.52-8.49 4.32-8.97 5.28-9.29 6.24-9.45 7.37-9.61 8.49-9.77"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M596.01 255.62H601.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M590.88 255.62H596.01"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M600.82 265.07H608.99"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M594.25 265.07H600.82"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M594.25 265.07H588.96"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 408.78747558593744, 114.09750366210938)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-2.08 1.61-3.68 3.69-3.68 5.77-3.68 7.53-2.08 7.53 0 7.53 2.09 5.77 3.85 3.69 3.85 1.61 3.85 0 2.09 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M546.82 148.29C544.73 149.41 543.77 151.97 544.89 154.06 545.37 155.02 546.01 155.66 546.82 156.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M550.66 156.14C552.9 155.02 553.71 152.46 552.74 150.21 552.26 149.41 551.62 148.77 550.66 148.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M546.82 159.66C548.1 159.98 549.38 159.98 550.66 159.66"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M546.82 156.14V159.66"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M550.66 159.66V156.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M550.66 148.29V146.53"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M550.66 146.53C549.54 146.05 548.1 146.05 546.82 146.53"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M546.82 146.53V148.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M550.66 157.9C553.87 156.78 555.47 153.42 554.51 150.21 553.87 148.45 552.42 147.17 550.66 146.53"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M546.82 146.53C543.77 147.65 542.01 151.01 543.13 154.06 543.77 155.82 545.05 157.26 546.82 157.9"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M549.86 151.01H547.62"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M547.62 151.01V153.26"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M547.62 153.26H549.86"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M548.26 150.21H549.22"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M549.22 150.21V149.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M549.22 149.09H548.26"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M548.26 149.09V150.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M549.22 154.22H548.26"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M548.26 154.22V155.18"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M548.26 155.18H549.22"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M551.78 151.65H550.82"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M550.82 151.65V152.62"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M550.82 152.62H551.78"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M551.78 152.62V151.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M545.69 151.65V152.62"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M545.69 152.62H546.82"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M546.82 152.62V151.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M546.82 151.65H545.69"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M549.22 155.18V154.22"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M549.86 153.26V151.01"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M516.05 159.34C516.05 159.66 516.37 159.82 516.69 159.82"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M516.05 145.73V159.34"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M517.33 144.45C516.69 144.45 516.05 145.09 516.05 145.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M520.06 144.45H517.33"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M520.06 144.45C519.09 149.57 518.77 154.7 518.77 159.82"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M516.69 159.82H518.77"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M520.06 144.45C519.25 149.57 518.77 154.7 518.77 159.82"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M516.69 159.82V159.98"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M518.77 159.98V159.82"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M518.77 159.98H516.69"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M518.77 159.82V159.98"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M517.33 144.45H520.06"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M516.05 145.73H515.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M517.33 144.45C516.53 144.45 515.89 145.09 515.89 145.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M516.05 159.34H515.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M515.89 159.34V151.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M515.89 151.65V147.17"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M515.89 147.17V145.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M515.89 159.34C515.89 159.66 516.21 159.98 516.69 159.98"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M537.2 150.37C537.2 150.69 537.36 150.85 537.68 150.85"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M537.68 150.85C538 150.85 538.16 150.69 538.16 150.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M538.16 150.37C538.16 150.05 538 149.89 537.68 149.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M537.68 149.89C537.36 149.89 537.2 150.05 537.2 150.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M538.16 148.45H537.2"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M537.2 148.45H538.16"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M537.2 147.17V147.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M537.68 147.17H537.2"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M537.68 147.65V147.17"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M537.68 147.17V147.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M538 147.17H537.68"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M538 147.65V147.17"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M538.16 147.65H538"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M538.16 147.01V147.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M537.2 147.01H538.16"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M537.2 147.65V147.01"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M541.21 159.82H541.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M518.77 159.82H541.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M544.41 144.45H520.06"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M544.57 144.45H544.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M545.21 145.09 544.57 144.45"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M545.21 145.09C541.37 147.17 539.77 151.81 541.69 155.66 542.17 156.46 542.65 157.1 543.29 157.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M541.37 159.82 543.29 157.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M537.2 146.53C537.2 146.69 537.2 146.85 537.36 146.85H537.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M537.52 146.85 537.68 146.69"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M537.84 146.69V146.69H537.68"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M537.84 146.69C538 146.69 538.16 146.69 538.16 146.53"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M538.16 146.21V146.53"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M537.2 146.21H538.16"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M537.2 146.53V146.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M537.2 147.81V148.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M538.16 147.81H537.2"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M537.2 147.81H538.16"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M537.2 148.29V147.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M537.2 149.09 537.84 148.77"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M537.2 149.25V149.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M537.84 149.57 537.2 149.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M537.2 149.57H537.84"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M537.2 149.73V149.57"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M538.16 149.57 537.2 149.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M537.36 149.25 538.16 149.57"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M538.16 148.77 537.36 149.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M537.2 148.61 538.16 148.77"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M537.2 148.77V148.61"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M537.84 148.77H537.2"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M538.48 148.77V149.57"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M538.48 149.57 539.28 150.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M539.28 150.37V149.57"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M539.28 149.57 538.48 148.77"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M538.48 150.69 539.28 151.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M539.28 151.49V150.69"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M539.28 150.69 538.48 149.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M538.48 149.73V150.69"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M545.21 145.09V145.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M545.21 145.25C541.37 147.17 539.93 151.81 541.85 155.66 542.17 156.46 542.65 157.1 543.29 157.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M543.29 157.9V157.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M543.29 157.9 541.37 159.82"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M543.29 157.9V157.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M544.73 144.45H544.57"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M544.73 144.45 545.37 145.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M545.37 145.09H545.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M545.37 145.09V145.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M545.37 145.25H545.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M520.06 144.45H544.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M541.21 159.82V159.98"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M541.21 159.98H518.77"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M537.52 146.69C537.52 146.69 537.68 146.69 537.68 146.53"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M537.2 146.53 537.36 146.69H537.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M537.2 146.37V146.53"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M537.68 146.53V146.53 146.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M537.68 146.37H537.2"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M537.68 146.37 537.84 146.53V146.69"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M538 146.37H537.68"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M537.84 146.69H538V146.53"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M538 146.53V146.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M541.21 159.98C541.21 159.98 541.37 159.98 541.37 159.82"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M537.68 149.89C537.36 149.89 537.2 150.21 537.2 150.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M538 150.37C538 150.21 537.84 149.89 537.68 149.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M537.68 150.85C537.84 150.85 538 150.53 538 150.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M537.2 150.37C537.2 150.53 537.36 150.85 537.68 150.85"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M544.73 144.45C544.57 144.45 544.57 144.45 544.41 144.45"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M514.93 151.65H515.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M515.73 148.93H515.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M515.41 149.73H515.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M515.41 151.17V149.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M515.73 151.17H515.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M515.73 149.73V151.17"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M514.93 151.65V147.17"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M514.93 147.17H515.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M515.41 147.65H515.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M515.73 147.65V148.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M515.41 148.93V147.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M512.69 151.81H514.61"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M514.61 151.81V158.38"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M512.69 156.46 514.61 158.38"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M514.61 158.38H515.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M515.73 153.1V151.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M515.89 153.1H515.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M514.61 151.81H515.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M545.85 145.57 546.17 146.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 412.99499511718744, 118.90499877929685)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C3.53-1.12 5.61-4.8 4.49-8.33 3.37-11.85-.32-13.77-3.84-12.81-4-12.65-4.16-12.65-4.49-12.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M544.09 156.94C544.89 157.74 545.85 158.22 546.82 158.54"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M543.77 157.42C544.57 158.22 545.69 158.86 546.82 159.18"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 412.99499511718744, 119.38499450683591)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C3.85-1.12 6.09-5.12 5.13-8.97 4.01-12.81 0-15.06-3.84-13.93-4.16-13.93-4.49-13.77-4.81-13.61"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M545.21 145.09C545.37 145.09 545.37 145.09 545.53 145.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M543.61 157.9C544.57 158.86 545.69 159.34 546.82 159.66"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 412.99499511718744, 119.745002746582)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C4.17-.96 6.73-5.28 5.61-9.45 4.65-13.61 .32-16.18-3.84-15.05-4.33-14.89-4.65-14.73-5.13-14.57"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M544.09 156.94 543.77 157.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M543.61 157.9C543.45 157.9 543.29 157.9 543.13 157.9"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M513.33 147.65H514.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M524.86 140.92V142.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M546.5 151.65 546.33 152.13 546.5 152.62"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M436.25 149.25H436.09L435.93 149.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M435.93 149.41C435.77 149.41 435.77 149.57 435.77 149.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M435.77 149.73C435.77 149.89 435.77 150.05 435.93 150.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M435.93 150.05H436.09 436.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M436.25 150.05H436.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M436.41 150.05V149.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M436.41 149.25H436.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M436.41 149.25V150.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M436.41 150.21H436.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M436.25 150.21H436.09 435.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M435.61 149.73C435.61 149.89 435.77 150.05 435.93 150.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M436.09 149.25C435.93 149.25 435.61 149.41 435.61 149.57V149.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M436.09 149.25H436.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M437.53 149.73C437.53 149.41 437.37 149.25 437.21 149.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M437.21 149.25C436.89 149.25 436.73 149.41 436.73 149.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M436.73 149.73C436.73 149.89 436.89 150.05 437.05 150.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M437.21 150.05C437.37 150.05 437.53 149.89 437.53 149.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M436.57 149.73C436.57 150.05 436.89 150.21 437.05 150.21H437.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M437.05 149.25C436.89 149.25 436.57 149.41 436.57 149.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M437.21 150.21C437.37 150.21 437.69 150.05 437.69 149.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M437.69 149.73C437.69 149.41 437.37 149.25 437.21 149.25H437.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M428.88 147.33 428.72 147.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M428.72 147.49V147.65 147.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M428.72 147.81V148.13H428.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M428.88 148.13H429.04L429.2 147.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M429.2 147.81 429.04 147.49V147.33H428.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M429.2 147.81V148.13L429.04 148.29H428.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M428.88 148.29V148.29H428.72V148.13L428.56 147.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M428.56 147.81 428.72 147.49V147.33H428.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M428.88 147.33H429.04L429.2 147.49V147.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M438.82 145.89C438.82 145.73 438.66 145.41 438.34 145.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M438.34 146.37C438.66 146.37 438.82 146.05 438.82 145.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M438.82 145.89C438.82 145.57 438.66 145.41 438.34 145.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M438.34 146.37C438.66 146.37 438.82 146.21 438.82 145.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M438.34 145.41C438.18 145.41 438.02 145.73 438.02 145.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M438.02 145.89C438.02 146.05 438.18 146.37 438.34 146.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M438.34 145.41C438.02 145.41 437.86 145.57 437.86 145.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M437.86 145.89C437.86 146.21 438.02 146.37 438.34 146.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M437.37 147.81H437.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M437.21 148.13H437.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M437.37 148.13V147.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M437.53 148.29H437.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M437.37 148.29H437.21 437.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M436.89 147.97C436.89 148.13 436.89 148.29 437.05 148.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M437.05 147.81C436.89 147.81 436.89 147.97 436.89 147.97"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M437.05 147.81H437.21 437.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M437.37 147.81V147.33"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M437.37 147.33H437.53"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M437.53 147.33V148.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M437.21 147.81H437.05V147.97"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M437.05 147.97V148.13H437.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M437.69 146.37H437.53"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M437.53 146.37V145.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M437.37 145.41C437.21 145.41 437.21 145.57 437.05 145.57"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M437.05 145.73V146.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M437.05 146.37V145.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M437.05 145.73V145.57 145.41H437.21 437.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M437.69 145.73V146.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M437.05 145.57V145.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M437.69 145.57C437.53 145.41 437.53 145.41 437.37 145.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M437.69 145.57V145.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M437.53 145.73V145.57L437.37 145.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.13 149.73H430.96"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.96 150.05H431.13"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.13 150.05V149.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.29 150.21H431.13"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.13 150.21H430.96 430.8"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.64 149.89C430.64 150.05 430.64 150.21 430.8 150.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.8 149.73H430.96 431.13"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.13 149.73V149.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.13 149.25H431.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.29 149.25V150.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.8 149.73C430.64 149.73 430.64 149.73 430.64 149.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.8 149.89V150.05H430.96"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.96 149.73H430.8V149.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M436.73 148.29H436.57"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M436.57 148.29V147.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M436.57 147.49V147.49L436.41 147.33"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M436.41 147.33C436.25 147.33 436.25 147.49 436.09 147.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M436.09 147.49V147.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M436.09 147.65V148.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M436.09 148.29V147.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M436.09 147.65V147.49 147.33H436.25 436.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M436.73 147.49C436.57 147.33 436.57 147.33 436.41 147.33"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M436.73 147.65V148.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M436.73 147.49V147.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M436.57 147.65V147.65 147.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.88 144.45V144.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.88 144.12H424.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.4 144.12V144.45"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.4 144.45H424.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.23 144.45V143.48"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.23 143.48H424.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.4 143.48V143.96"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.4 143.96H424.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.88 143.96V143.48"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.88 143.48V144.45"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.09 143.48V144.45"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.09 144.45H431.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.93 144.45 431.61 143.64"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.61 143.64 431.13 144.45"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.13 144.45V143.48"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.13 143.48 431.29 144.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.29 144.29 431.61 143.48"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.61 143.48 431.93 144.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.93 144.29 432.09 143.48"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.93 146.37H431.77"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.77 146.37 431.45 145.57"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.45 145.57 431.13 146.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.13 146.37H430.96"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.96 146.37 431.45 145.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.45 145.41 431.93 146.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M438.5 148.29 437.86 147.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M437.86 148.29H437.69"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M437.69 148.29V147.33"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M437.69 147.33 438.34 148.13"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M438.34 148.13V147.33"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M438.34 147.33H438.5"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M438.5 147.33V148.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M437.86 147.49V148.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.64 143.96H430.32"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.32 143.96 430.48 144.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.48 144.29 430.64 143.96"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.48 144.45 430 143.48"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430 143.48H430.16"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.16 143.48V143.8"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.16 143.8H430.64"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.64 143.8 430.8 143.48"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.8 143.48H430.96"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.96 143.48 430.48 144.45"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M434.65 150.21V149.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M434.65 149.25H434.17"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M434.17 149.25H434.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M434.65 149.25V150.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M429.68 143.64H429.52 429.36"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M429.36 143.64C429.2 143.64 429.04 143.8 429.04 143.96"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M429.04 143.96C429.04 144.12 429.2 144.29 429.36 144.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M429.36 144.29 429.52 144.45H429.68"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M429.68 144.45V143.64"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M429.84 143.48V144.45"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M429.68 144.45H429.52 429.36"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M429.52 143.48H429.84"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M429.52 143.48C429.2 143.48 429.04 143.64 429.04 143.96"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M429.84 144.45H429.68"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M429.04 143.96C429.04 144.12 429.2 144.45 429.36 144.45"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.16 145.89H429.68"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M429.68 145.89V145.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M429.68 145.73H430.16"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.16 145.73V145.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M423.43 148.29V147.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M423.43 147.81H422.95"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M422.95 147.81V148.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M422.95 148.29H422.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M422.79 148.29V147.33"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M422.79 147.33H422.95"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M422.95 147.33V147.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M422.95 147.81H423.43"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M423.43 147.81V147.33"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M423.43 147.33V148.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M427.76 144.45H427.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M427.6 144.45V143.48"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M427.6 143.48H427.76"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M427.76 143.48V144.45"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.21 146.37H433.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.05 146.37V145.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.05 145.41V146.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.05 146.21H433.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.21 146.21V146.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.04 147.65C424.88 147.49 424.88 147.33 424.72 147.33"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.72 147.33C424.72 147.33 424.56 147.49 424.56 147.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.56 147.65 424.72 147.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.72 147.81C424.88 147.81 424.88 147.65 425.04 147.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.72 148.29H424.56"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.56 148.29 424.88 147.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.4 147.65C424.4 147.81 424.56 147.81 424.72 147.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.04 147.65C425.04 147.49 424.88 147.33 424.72 147.33"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.04 147.65V147.81H424.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.88 147.81 424.72 148.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.88 147.81H424.72"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.72 147.33C424.56 147.33 424.4 147.33 424.4 147.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M434.17 147.65C434.01 147.65 434.01 147.65 434.01 147.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M434.17 147.65V147.65L434.33 147.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M434.33 147.81H434.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M434.49 147.81 434.65 147.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M434.65 147.65V147.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M434.49 147.97C434.49 147.81 434.49 147.81 434.65 147.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M434.49 147.97 434.33 147.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M434.33 147.81H434.17"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M434.17 147.81H434.01"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M428.08 147.49 427.76 148.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M427.6 148.29 428.08 147.33"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M428.08 147.33 428.4 148.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M428.4 148.29 428.08 147.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M427.76 148.29H427.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M428.08 150.21 428.56 149.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M428.08 149.25H428.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M428.24 149.25 428.72 149.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M428.72 149.73V149.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M428.72 149.25V150.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M428.24 150.21H428.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M428.72 149.73 428.24 150.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M428.72 150.21V149.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M428.56 149.73 428.08 149.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.85 149.89H434.01"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M434.01 149.89V149.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M434.01 149.73C434.01 149.73 433.85 149.73 433.85 149.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M438.82 148.29V147.33"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M438.82 147.33V148.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M429.52 147.65V148.13"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M429.52 148.13 429.84 147.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M429.84 147.65H429.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M429.52 148.29H429.36"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M429.36 148.29V147.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M429.36 147.65V147.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M429.36 147.49V147.33"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430 147.49 429.52 148.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M429.52 147.49H430"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M429.36 147.33H429.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M429.52 147.33V147.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.21 148.29H433.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.05 148.29V147.33"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.21 147.33V148.13"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.05 147.33H433.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.21 148.13V148.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.85 145.41C433.85 145.57 433.85 145.57 434.01 145.57"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M434.01 145.57V145.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M434.01 145.41H433.85"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M434.01 149.41V149.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.85 149.25 434.01 149.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M434.01 149.25H433.85"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M428.08 149.57H427.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M427.6 149.57H428.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.16 145.73H430.48"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.48 145.73V145.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.16 145.89V145.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.48 145.89H430.16"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M428.88 144.45H428.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M428.24 144.45H428.72"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M428.72 144.45V144.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M428.72 144.12H428.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M428.24 143.96H428.72"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M428.72 143.96V143.64"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M428.72 143.64H428.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M428.24 143.64V143.48"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M428.24 143.48H428.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M428.88 143.48V144.45"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M428.24 144.12V143.96"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M434.01 146.21V146.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M434.01 146.05H433.85"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.85 146.05C433.85 146.05 433.85 146.21 434.01 146.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M436.89 146.21V146.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M436.89 146.37H436.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M436.41 146.37V146.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M436.57 146.21V145.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M436.57 145.41H436.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M436.73 145.41V146.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M436.73 146.21H436.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M436.41 146.21H436.57"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.61 147.33C431.45 147.33 431.45 147.49 431.45 147.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.61 148.13V148.13H431.77L431.93 147.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.93 147.81 431.77 147.49 431.61 147.33"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.93 147.81V148.13H431.77V148.29H431.61"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.61 148.29V148.29H431.45L431.29 148.13V147.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.29 147.81V147.49L431.45 147.33H431.61"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.61 147.33H431.77L431.93 147.49V147.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.29 147.81 431.45 148.13H431.61"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.45 147.49V147.65L431.29 147.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M428.88 149.25H429.04"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M429.52 149.25 429.84 149.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M429.04 149.25 429.2 149.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M429.84 149.89 430 149.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M429.2 149.89 429.52 149.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M429.04 150.21 428.88 149.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M429.84 150.21 429.52 149.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M429.52 149.41 429.04 150.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430 149.25 429.84 150.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.68 147.17 425.2 148.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.2 148.29 425.52 147.17"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.52 147.17H425.68"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M427.6 149.25 427.44 150.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M427.44 150.21 426.96 149.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426.96 149.41 426.64 150.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426.64 150.21 426.48 149.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426.64 149.25V149.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426.64 149.89 426.96 149.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426.96 149.25 427.44 149.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M427.44 149.89V149.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M427.44 149.25H427.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M438.82 150.21 438.34 149.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M438.34 149.41 438.02 150.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M438.02 150.21H437.86"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M437.86 149.25 438.02 149.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M438.02 149.89 438.34 149.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M437.86 150.21V149.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M438.66 149.89 438.82 149.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M438.34 149.25 438.66 149.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M438.82 149.25V150.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.52 143.48H425.84L426 143.64"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426 143.64V143.8 143.96"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.52 144.45H425.36L425.2 144.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.52 144.45C425.84 144.45 426 144.29 426 143.96"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.2 143.8V143.64"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.52 143.48C425.36 143.48 425.2 143.48 425.2 143.64"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426 143.96C426 143.8 425.84 143.64 425.52 143.64"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.52 143.64H425.36L425.2 143.8"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.2 144.29 425.36 144.45H425.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.52 144.45C425.84 144.45 426 144.29 426 143.96"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.45 150.05 431.61 149.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.93 150.05C432.25 150.05 432.41 149.89 432.41 149.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.41 149.73C432.41 149.41 432.09 149.25 431.93 149.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.93 149.25C431.77 149.25 431.61 149.41 431.61 149.57"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.61 149.57H431.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.93 149.57V149.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.93 149.25C431.61 149.25 431.45 149.41 431.45 149.57V149.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.93 149.25H432.25L432.41 149.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.41 149.41V149.57 149.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.93 150.21C432.25 150.21 432.41 150.05 432.41 149.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.93 150.21H431.61L431.45 150.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.61 149.89 431.77 150.05H431.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.93 149.73H431.45"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M434.65 146.21V146.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M434.17 146.21H434.33"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M434.33 146.21V145.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M434.33 145.41H434.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M434.49 145.41V146.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M434.49 146.21H434.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M434.65 146.37H434.17"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M434.17 146.37V146.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.16 147.97C430.16 148.13 430.16 148.29 430.32 148.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.32 147.65 430.48 147.33"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.48 147.33H430"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430 147.33H430.8"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.32 147.65C430.32 147.81 430.16 147.81 430.16 147.97"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.16 147.97C430.16 148.13 430.32 148.13 430.32 148.13"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.8 147.33 430.32 147.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.32 147.65C430.16 147.81 430.16 147.81 430.16 147.97"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.32 148.29C430.48 148.29 430.64 148.13 430.64 147.97"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.32 148.13C430.48 148.13 430.64 148.13 430.64 147.97"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M427.12 145.73 427.28 145.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426.48 145.41H426.64"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426.64 145.41 426.8 145.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M427.28 145.41H427.44"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M427.44 145.41 426.96 146.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426.8 145.73H427.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426.96 146.37 426.48 145.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426.96 146.21 427.12 145.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M427.12 145.89H426.8"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426.8 145.89 426.96 146.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426.96 147.97V147.97H426.8"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426.48 147.65C426.48 147.81 426.64 147.97 426.8 147.97"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426.8 147.33C426.8 147.33 426.64 147.49 426.64 147.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426.96 148.13V147.97"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426.64 148.13H426.96"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M427.12 147.49C427.12 147.33 426.96 147.33 426.8 147.33"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426.8 147.33C426.64 147.33 426.48 147.49 426.48 147.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M427.12 147.49C427.12 147.49 426.96 147.33 426.8 147.33"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426.64 148.29V148.13"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426.8 147.81H426.96 427.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426.96 148.29H426.64"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M427.12 147.81 426.96 148.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426.64 147.65C426.64 147.81 426.64 147.81 426.8 147.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.37 150.21H433.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.73 149.73V150.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.73 150.21V149.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.73 149.25V149.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.21 149.25H433.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.21 149.73H432.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.21 149.73V149.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.73 149.73H433.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.37 149.25V150.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.21 150.21V149.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.04 149.89C425.04 150.05 425.04 150.21 425.2 150.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.2 149.57C425.2 149.73 425.04 149.73 425.04 149.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.04 149.89C425.04 150.05 425.2 150.05 425.2 150.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.2 150.05C425.36 150.05 425.52 150.05 425.52 149.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.2 150.21C425.36 150.21 425.52 150.05 425.52 149.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.88 149.25H425.68"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.36 149.25H424.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.68 149.25 425.2 149.57"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.2 149.57C425.04 149.73 425.04 149.73 425.04 149.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.2 149.57 425.36 149.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M423.59 143.48V144.45"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M423.59 144.45 422.95 143.64"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M422.95 143.64V144.45"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M422.95 144.45V143.48"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M422.95 143.48 423.59 144.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M423.59 144.29V143.48"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.13 147.81H430.8"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.8 147.81V147.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.13 147.65V147.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.8 147.65H431.13"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M429.04 146.37H428.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M429.04 146.21H429.2"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M429.2 146.21 429.04 146.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M428.88 145.41H429.04"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M428.88 146.37V145.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M429.04 145.41V146.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426.16 150.21H426"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426 149.25V150.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426 150.21V149.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426 150.05H426.16"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426.16 150.05V150.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.16 149.57H430.48"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.48 149.57H430.16"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M427.44 144.45 426.8 143.64"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M427.44 143.48V144.45"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426.8 144.45H426.64"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426.8 143.64V144.45"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426.64 144.45V143.48"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M427.28 143.48H427.44"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M427.28 144.29V143.48"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426.64 143.48 427.28 144.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426.16 148.13V148.13H426.32V147.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426.16 148.29H426L425.84 148.13V147.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426.16 147.33V147.33H426.32V147.49L426.48 147.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.84 147.49V147.65 147.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426.32 147.81V147.49H426.16V147.33"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426.48 147.81 426.32 148.13V148.29H426.16"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.84 147.81V147.49L426 147.33H426.16"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.84 147.81V148.13H426 426.16"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M426.16 147.33C426 147.33 426 147.49 425.84 147.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M428.4 145.41H428.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M428.4 145.57V145.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M428.24 145.41C428.24 145.57 428.4 145.57 428.4 145.57"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.07 147.33C423.91 147.33 423.91 147.49 423.75 147.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.07 147.33V147.33H424.23V147.49L424.4 147.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.23 147.81V147.49H424.07V147.33"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.4 147.81 424.23 148.13V148.29H424.07"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M423.75 147.81V147.49L423.91 147.33H424.07"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.07 148.13V148.13H424.23V147.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.07 148.29H423.91L423.75 148.13V147.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M423.75 147.49V147.65 147.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M423.75 147.81V148.13H423.91 424.07"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M427.92 146.37H427.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M427.44 145.73C427.44 145.89 427.6 146.05 427.76 146.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M427.92 145.41C427.76 145.41 427.6 145.57 427.6 145.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M427.6 145.73C427.6 145.89 427.76 145.89 427.76 145.89H427.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M427.6 146.37V146.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M427.6 146.21H427.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M427.92 145.41C427.6 145.41 427.6 145.57 427.44 145.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M427.92 146.21V146.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M428.08 145.73C428.08 145.57 427.92 145.41 427.92 145.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M428.08 145.73C428.08 145.57 428.08 145.41 427.92 145.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M427.92 146.05V146.05H427.76"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M428.08 145.89 427.92 146.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M427.92 145.89V145.89H428.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M423.91 144.45V143.48"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M423.91 143.48H424.07"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.07 144.45H423.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M424.07 143.48V144.45"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M435.29 147.97C435.29 147.97 435.29 147.81 435.13 147.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M435.13 147.81V147.97"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M435.13 147.97H435.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M422.15 143.96 422.31 144.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M422.31 144.29 422.47 143.96"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M422.31 144.45 421.83 143.48"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M421.83 143.48 421.99 143.8"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M421.99 143.8H422.47"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M422.47 143.8 422.63 143.48"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M422.79 143.48 422.31 144.45"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M422.47 143.96H422.15"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M422.63 143.48H422.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M435.45 146.37H435.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M434.81 146.37V145.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M434.81 145.73V145.57 145.41H434.97 435.13"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M435.45 145.57C435.45 145.41 435.29 145.41 435.13 145.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M435.45 145.57V145.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M435.45 145.73V146.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M435.13 145.41C434.97 145.41 434.97 145.57 434.97 145.57"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M434.97 145.57V145.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M434.97 146.37H434.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M434.97 145.73V146.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M435.29 146.37V145.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M435.29 145.73V145.57L435.13 145.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M435.13 147.33V147.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M435.13 147.49C435.29 147.49 435.29 147.49 435.29 147.33"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M435.29 147.33H435.13"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M435.93 148.29H435.45"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M435.45 148.29V148.13"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M435.45 148.13H435.61"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M435.93 148.13V148.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M435.77 148.13H435.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M435.61 147.33H435.77"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M435.77 147.33V148.13"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M435.61 148.13V147.33"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M436.09 145.89H435.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M435.77 145.89 435.61 146.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M436.25 145.41V146.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M436.09 145.41H436.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M435.77 146.05V146.21H435.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M435.93 145.89 435.77 146.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M435.77 145.89H435.93 436.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M436.25 146.37H436.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M435.93 146.21H436.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M435.61 146.05C435.61 146.21 435.77 146.37 435.77 146.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M436.09 145.89V145.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M436.09 146.37H435.93 435.77"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M436.09 146.21V145.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M435.45 150.21H434.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M434.81 150.21V150.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M435.29 149.73H434.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M435.29 150.05V149.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M434.81 150.05H435.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M435.29 149.73V149.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M435.45 149.25V150.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M434.81 149.25H435.45"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M434.81 149.73H435.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M435.29 149.25H434.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M422.79 147.33 422.31 147.97"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M422.31 147.97H422.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M422.15 147.97 422.63 147.33"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M422.63 147.97H422.15"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M422.15 147.33H422.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M422.63 147.33H422.15"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.25 146.37C432.41 146.37 432.57 146.21 432.57 146.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.09 145.73C432.09 145.89 431.93 146.05 431.93 146.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.09 145.73 432.41 145.57"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.41 145.57H431.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.93 145.57V145.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.93 145.41H432.57"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.57 145.41 432.25 145.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.25 145.89C432.09 145.89 432.09 146.05 432.09 146.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.09 146.05C432.09 146.21 432.09 146.37 432.25 146.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.93 146.05C431.93 146.21 432.09 146.37 432.25 146.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.25 146.37C432.41 146.37 432.57 146.21 432.57 146.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.41 147.33V147.33H432.57V147.49L432.73 147.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.41 148.29H432.25L432.09 148.13V147.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.09 147.81V147.49L432.25 147.33H432.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.41 148.13V148.13H432.57V147.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.57 147.81V147.49H432.41V147.33"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.41 147.33C432.25 147.33 432.25 147.49 432.09 147.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.73 147.81 432.57 148.13V148.29H432.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.09 147.49V147.65 147.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.09 147.81V148.13H432.25 432.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M420.87 151.33H439.94"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M420.87 141.24V151.33"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M439.94 141.24H420.87"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M439.94 151.33V141.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 144.77H420.07"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 144.61V144.77"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M420.07 144.61H418.47"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M420.07 144.77V144.61"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M444.75 151.17H442.34"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M444.75 162.87V151.17"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M442.34 162.87H444.75"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M442.34 151.17V162.87"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.78 159.98C444.1 159.98 444.42 159.66 444.42 159.18 444.42 158.86 444.1 158.54 443.78 158.54"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.78 158.54C444.1 158.54 444.42 158.22 444.42 157.74 444.42 157.42 444.1 157.1 443.78 157.1"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.78 157.1C444.1 157.1 444.42 156.78 444.42 156.3 444.42 155.98 444.1 155.66 443.78 155.66"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.78 155.66C444.1 155.66 444.42 155.34 444.42 154.86 444.42 154.54 444.1 154.22 443.78 154.22"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.78 154.22C444.1 154.22 444.42 153.9 444.42 153.42 444.42 153.1 444.1 152.78 443.78 152.78"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.78 152.78C444.1 152.78 444.42 152.46 444.42 151.97 444.42 151.65 444.1 151.33 443.78 151.33"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.78 151.33H442.5"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M442.5 151.33V162.71"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M442.5 162.71H443.78"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.78 162.71C444.1 162.71 444.42 162.39 444.42 162.07 444.42 161.75 444.1 161.43 443.78 161.43"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.78 161.43C444.1 161.43 444.42 161.11 444.42 160.62 444.42 160.3 444.1 159.98 443.78 159.98"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M442.34 148.45H444.58"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M442.34 145.25V148.45"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M444.58 145.25H442.34"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M444.58 148.45V145.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.78 148.29C444.1 148.29 444.42 147.97 444.42 147.49 444.42 147.17 444.1 146.85 443.78 146.85"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.78 146.85C444.1 146.85 444.42 146.53 444.42 146.05 444.42 145.73 444.1 145.41 443.78 145.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M442.5 145.41V148.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M442.5 148.29H443.78"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.78 145.41H442.5"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M444.75 166.55H442.34"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M444.75 171.04V166.55"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M442.34 171.04H444.75"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M442.34 166.55V171.04"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.78 166.71H442.5"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M442.5 170.88H443.78"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.78 170.88C444.1 170.88 444.42 170.56 444.42 170.24 444.42 169.92 444.1 169.6 443.78 169.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.78 169.6C444.1 169.6 444.42 169.28 444.42 168.79 444.42 168.47 444.1 168.15 443.78 168.15"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.78 168.15C444.1 168.15 444.42 167.83 444.42 167.35 444.42 167.03 444.1 166.71 443.78 166.71"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M442.5 166.71V170.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 332.59500122070307, 109.53750610351562)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H.16L0 .16V0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 332.59500122070307, 110.61750793457031)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H.16L0 .16V0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 332.59500122070307, 126.59249877929685)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H.16L0 .16V0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 332.59500122070307, 120.4649963378906)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H.16 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 332.59500122070307, 113.97750091552734)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H.16C.16 .16 0 .16 0 .16V0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 332.59500122070307, 119.38499450683591)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H.16L0 .16V0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 332.59500122070307, 121.55250549316403)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H.16 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 332.59500122070307, 116.14500427246094)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H.16C.16 .16 0 .16 0 .16V0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 332.59500122070307, 115.06500244140625)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H.16C.16 .16 0 .16 0 .16V0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 332.59500122070307, 118.30500793457028)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H.16L0 .16V0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 332.59500122070307, 125.51250457763669)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H.16L0 .16V0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 332.59500122070307, 117.2250061035156)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H.16L0 .16V0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 332.59500122070307, 127.68000793457028)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H.16 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.15 148.13H420.07"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.15 145.73V148.13"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 146.21 418.47 146.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 146.05V146.69"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 146.69 418.63 146.53"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 146.53V146.85"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 146.85H419.75"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 145.89V146.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 147.81H419.75"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 147.81V147.01"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 147.01H418.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 147.01V147.17"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 147.17 418.47 147.01"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 147.01V147.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 147.81 418.63 147.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 147.65V147.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 145.89H418.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M420.07 145.73H418.15"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M420.07 148.13V145.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 146.85V145.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.15 150.85H420.07"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.15 148.45V150.85"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M420.07 150.85V148.45"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 148.93 418.47 148.77"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 148.77V149.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 149.41 418.63 149.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 149.25V149.57"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 149.57H419.75"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 148.61V148.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 150.53V149.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 149.73H418.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 149.73V149.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 149.89 418.47 149.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 149.73V150.53"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 150.53 418.63 150.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 150.37V150.53"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 150.53H419.75"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 149.57V148.61"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M420.07 148.45H418.15"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 148.61H418.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 314.4524841308593, 110.49749755859375)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0H.16V.16C0 .16 0 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 314.4524841308593, 109.77749633789062)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0H.16 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.15 157.58H420.07"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.15 151.17V157.58"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 151.33V151.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 151.65 418.47 151.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 151.49V152.13"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 152.13 418.63 151.97"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 151.97V152.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 152.29H419.75"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 152.46V152.62"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 152.62 418.47 152.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 152.46V153.26"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 153.26 418.63 153.1"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 153.1V153.26"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 153.26H419.75"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 153.26V152.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 152.46H418.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 153.42V153.58"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 153.58 418.47 153.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 153.42V154.22"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 154.22 418.63 154.06"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 154.06V154.38"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 154.38H419.75"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 154.38V153.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 153.42H418.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 154.38V154.7"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 154.7 418.47 154.54"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 154.54V155.18"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 155.18 418.63 155.02"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 155.02V155.34"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 155.34H419.75"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 155.34V154.38"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 154.38H418.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 155.5V155.66"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 155.66 418.47 155.5"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 155.5V156.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 156.3 418.63 156.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 156.14V156.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 156.3H419.75"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 156.3V155.5"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 155.5H418.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 156.46V156.62"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 156.62 418.47 156.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 156.46V157.26"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 157.26 418.63 157.1"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 157.1V157.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 157.42H419.75"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 157.42V156.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 156.46H418.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 152.29V151.33"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 151.33H418.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M420.07 157.58V151.17"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M420.07 151.17H418.15"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 314.4524841308593, 126.47250366210935)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0H.16V.16C0 .16 0 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 314.4524841308593, 115.42499542236328)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.16 0-.16 .16-.16V0H0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 314.4524841308593, 128.7599945068359)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0H.16V.16C0 .16 0 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.15 183.53H420.07"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.15 176.96V183.53"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M420.07 176.96H418.15"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M420.07 183.53V176.96"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 177.29V177.61"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 177.61 418.47 177.45"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 177.45V178.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 178.09 418.63 177.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 177.93V178.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 178.25H419.75"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 177.29H418.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 178.25V178.57"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 178.57 418.47 178.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 179.05H418.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 179.05V179.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 179.21H419.75"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 179.21V178.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 178.25H418.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 179.37V179.53"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 179.53 418.47 179.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 179.37V180.17"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 180.01V180.17"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 180.17H419.75"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 180.17V179.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 179.37H418.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 180.33V180.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 180.65 418.47 180.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 180.49V181.13"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 181.13 418.63 180.97"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 180.97V181.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 181.29H419.75"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 181.29V180.33"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 180.33H418.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 181.29V181.61"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 181.61 418.47 181.45"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 181.45V182.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 182.09H418.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 182.09V182.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 182.25H419.75"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 182.25V181.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 181.29H418.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 182.41V182.57"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 182.57 418.47 182.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 182.41V183.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 183.21 418.63 183.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 183.05V183.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 183.21H419.75"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 183.21V182.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 182.41H418.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 178.25V177.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 178.41V179.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 180.17 418.63 180.01"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 314.4524841308593, 130.31999206542966)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0H.16V.16C0 .16 0 0 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 314.4524841308593, 112.53750610351562)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0H.16V.16C0 .16 0 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 314.4524841308593, 122.0325012207031)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0H.16 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 314.4524841308593, 114.58499908447266)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0H.16V.16C0 .16 0 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 314.4524841308593, 134.88751220703122)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.16 0-.16 .16-.16V0H0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 314.4524841308593, 134.04750061035153)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0H.16V.16C0 .16 0 0 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 314.4524841308593, 119.745002746582)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0H.16 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 314.4524841308593, 117.70500183105466)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0 0 0-.16 .16-.16V0H0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 314.4524841308593, 131.88000488281247)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0 0 0-.16 .16-.16V0H0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 314.4524841308593, 136.3274993896484)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0H.16V.16C0 .16 0 0 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 314.4524841308593, 120.4649963378906)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0H.16V.16C0 .16 0 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 314.4524841308593, 131.04000854492185)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0H.16V.16C0 .16 0 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 314.4524841308593, 128.04000854492185)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0H.16V.16C0 .16 0 0 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.15 176.8H420.07"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.15 167.99V176.8"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M420.07 167.99H418.15"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M420.07 176.8V167.99"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 168.47 418.47 168.31"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 168.96V169.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 169.12H419.75"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 169.12V168.31"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 169.28V169.44"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 170.24H419.75"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 169.28H418.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 171.2H419.75"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 172.16H419.75"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 172.16V171.36"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 171.36H418.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 172.48H418.47"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 173.12 418.63 172.96"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 172.96V173.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 173.28V172.32"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 172.32H418.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 173.6 418.47 173.44"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 174.08H418.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 174.24H419.75"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 174.24V173.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 174.4V174.56"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 174.56 418.47 174.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 175.04V175.2"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 175.2H419.75"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 175.2V174.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 174.4H418.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 175.52V176.16"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 173.44V174.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 168.31H418.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 176.32V175.36"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 172.32V172.48"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 172.48V173.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 175.36V175.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 175.52H418.47"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 171.04V171.2"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 168.31V169.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 170.08 418.63 169.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 169.92V170.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 171.04H418.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 176V176.32"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 176.32H419.75"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 174.08V174.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 170.24H418.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 176.16 418.63 176"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 168.31V168.47"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 171.36V172.16"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 174.4V175.2"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 175.2 418.63 175.04"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 169.44H418.47"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 171.2V170.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 169.44V170.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 173.28V173.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 170.24V170.56"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 175.36H418.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 173.28H418.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 170.4V171.04"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 171.36V171.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 169.12 418.63 168.96"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 172V172.16"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 170.24V169.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 170.56 418.47 170.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 171.52 418.47 171.36"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 172.16 418.63 172"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 173.28H419.75"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 314.4524841308593, 133.3274993896484)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0 0 0-.16 .16-.16V0H0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 314.4524841308593, 135.60749816894528)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0 0 0-.16 .16-.16V0H0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 314.4524841308593, 116.14500427246094)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0H.16V.16C0 .16 0 0 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 314.4524841308593, 137.16749572753903)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.16 0-.16 .16-.16V0H0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 314.4524841308593, 127.31999206542966)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0 0 0-.16 .16-.16V0H0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 314.4524841308593, 122.75250244140622)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0H.16V.16C0 .16 0 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 314.4524841308593, 119.02499389648435)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.16 0-.16 .16-.16V0H0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.15 167.67H420.07"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.15 157.9V167.67"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M420.07 157.9H418.15"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 158.22V158.38"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 158.38 418.47 158.22"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 158.22V159.02"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 159.02 418.63 158.86"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 158.86V159.02"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 159.02H419.75"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 158.22H418.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 160.14V159.18"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 160.3V160.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 160.3H418.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 161.43 418.47 161.27"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 162.07H419.75"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 162.07V161.27"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 163.19H419.75"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 164.47 418.47 164.31"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 164.31V165.11"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 164.31H418.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 165.43V166.07"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 166.07 418.63 165.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 165.91V166.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 166.23V165.27"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 163.51 418.47 163.35"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 161.91V162.07"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 159.02V158.22"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 164.31V164.47"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 161.27V161.43"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 166.23H419.75"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 166.39V166.55"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 165.27V165.59"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 162.87V163.19"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 166.39H418.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 165.59 418.47 165.43"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 162.23V162.55"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 162.55 418.47 162.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 163.35V163.51"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 166.55 418.47 166.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 161.27H418.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 159.18V159.5"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 163.03 418.63 162.87"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 160.14H419.75"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M420.07 167.67V157.9"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 160.3V161.11"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 161.27V162.07"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 167.19V166.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 167.19H419.75"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 159.82V160.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 161.11 418.63 160.95"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 165.27H418.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 167.03V167.19"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 159.18H418.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 163.19V162.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 160.95V161.11"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 162.07 418.63 161.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 159.34V159.98"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 165.11V164.31"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 167.19 418.63 167.03"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 164.15 418.63 163.99"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 165.11H419.75"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 165.11 418.63 164.95"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 162.23H418.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 159.5 418.47 159.34"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 161.11H419.75"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 163.35V164.15"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 164.15V163.35"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 162.39V163.03"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 163.35H418.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 160.46 418.47 160.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 159.98 418.63 159.82"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 163.99V164.15"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.47 166.39V167.19"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 164.95V165.11"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.63 164.15H419.75"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.75 161.11V160.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 314.4524841308593, 129.6000061035156)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0 0 0-.16 .16-.16V0H0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 314.4524841308593, 123.59249877929685)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.16 0-.16 .16-.16V0H0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 314.4524841308593, 113.85749816894531)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0H.16V.16C0 .16 0 0 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 314.4524841308593, 124.31249999999997)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0H.16 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 314.4524841308593, 125.0325012207031)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0H.16V.16C0 .16 0 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 314.4524841308593, 116.86500549316403)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0H.16V.16C0 .16 0 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 314.4524841308593, 121.31249999999997)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.16 0-.16 .16-.16V0H0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 314.4524841308593, 111.81749725341797)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0H.16 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 332.9550170898437, 132.47999572753903)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0H.16V.16C0 .16 0 0 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M444.75 172.96H442.82"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M444.75 179.37V172.96"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M442.82 179.37H444.75"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M442.82 172.96V179.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.3 173.6 443.14 173.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.14 173.28V173.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.3 173.76V174.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.3 174.08H444.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M444.42 174.08V173.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M444.42 173.12H443.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.3 173.12V173.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.3 174.56 443.14 174.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.14 174.24V175.04"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.14 175.04 443.3 174.72"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.3 174.72V175.04"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.3 175.04H444.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M444.42 175.04V174.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M444.42 174.24H443.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.3 174.24V174.56"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.3 175.52 443.14 175.36"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.14 175.36V176"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.14 176 443.3 175.68"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.3 175.68V176.16"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.3 176.16H444.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M444.42 176.16V175.2"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M444.42 175.2H443.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.3 175.2V175.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.3 176.64 443.14 176.32"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.14 176.32V176.96"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.14 176.96 443.3 176.8"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.3 176.8V177.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.3 177.12H444.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M444.42 177.12V176.16"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M444.42 176.16H443.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.3 176.16V176.64"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.3 177.61 443.14 177.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.14 177.29V178.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.14 178.09 443.3 177.77"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.3 177.77V178.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.3 178.09H444.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M444.42 178.09V177.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M444.42 177.29H443.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.3 177.29V177.61"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.3 178.57 443.14 178.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.14 178.41V179.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.14 179.05 443.3 178.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.3 178.73V179.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.3 179.21H444.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M444.42 179.21V178.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M444.42 178.25H443.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.3 178.25V178.57"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.14 173.92 443.3 173.76"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 332.9550170898437, 133.3274993896484)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.16 0-.16 .16-.16V0H0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 332.9550170898437, 131.04000854492185)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.16 0-.16 .16-.16V0H0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 332.9550170898437, 130.20001220703122)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0H.16V.16C0 .16 0 0 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 332.9550170898437, 131.7599945068359)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0 0 0-.16 .16-.16V0H0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 332.9550170898437, 134.04750061035153)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0 0 0-.16 .16-.16V0H0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M444.75 179.85H442.82"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M444.75 186.42V179.85"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M442.82 186.42H444.75"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M442.82 179.85V186.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M444.42 181.13V180.17"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.3 180.17V180.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.3 180.49 443.14 180.33"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.14 180.33V180.97"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.14 180.97 443.3 180.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.3 180.65V181.13"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M444.42 181.29H443.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.3 181.29V181.61"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.3 181.61 443.14 181.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.14 181.29V182.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.14 182.09 443.3 181.77"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.3 181.77V182.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.3 182.09H444.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M444.42 183.05V182.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M444.42 182.25H443.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.3 182.25V182.57"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.3 182.57 443.14 182.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.14 182.25V183.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.14 183.05 443.3 182.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.3 182.73V183.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.3 183.05H444.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M444.42 184.17V183.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M444.42 183.21H443.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.3 183.21V183.53"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.3 183.53 443.14 183.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.14 183.37V184.01"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.14 184.01 443.3 183.69"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.3 183.69V184.17"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.3 184.17H444.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M444.42 185.13V184.33"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M444.42 184.33H443.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.3 184.33V184.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.3 184.65 443.14 184.33"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.14 184.33V185.13"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.14 185.13 443.3 184.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.3 184.81V185.13"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.3 185.13H444.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M444.42 186.1V185.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M444.42 185.29H443.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.3 185.29V185.62"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.3 185.62 443.14 185.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.14 185.29V186.1"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.14 186.1 443.3 185.78"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.3 185.78V186.1"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.3 186.1H444.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M444.42 180.17H443.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.3 181.13H444.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M444.42 182.09V181.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 332.9550170898437, 136.2075042724609)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0H.16V.16C0 .16 0 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 332.9550170898437, 135.48748779296872)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0H.16 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 332.9550170898437, 139.3349914550781)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.16 0-.16 .16-.16V0H0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 332.9550170898437, 137.04750061035153)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.16 0-.16 .16-.16V0H0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 332.9550170898437, 138.48748779296872)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0H.16V.16C0 .16 0 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 332.9550170898437, 137.76750183105466)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0H.16 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M417.51 189.94H424.07"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M434.65 189.94H445.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M417.51 141.24V189.94"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M445.39 189.94V141.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 313.7324829101562, 141.37499999999997)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .32-.64 .64-.64 .96-.64 1.28-.32 1.28 0 1.28 .32 .96 .64 .64 .64 .32 .64 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 332.47497558593744, 141.37499999999997)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .32-.64 .64-.64 1.12-.64 1.28-.32 1.28 0 1.28 .32 1.12 .64 .64 .64 .32 .64 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 332.47497558593744, 106.88999938964844)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .32-.48 .64-.48 1.12-.48 1.28-.32 1.28 0 1.28 .48 1.12 .64 .64 .64 .32 .64 0 .48 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 313.7324829101562, 106.88999938964844)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .32-.48 .64-.48 .96-.48 1.28-.32 1.28 0 1.28 .48 .96 .64 .64 .64 .32 .64 0 .48 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 332.47497558593744, 123.59249877929685)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .32-.64 .64-.64 1.12-.64 1.28-.32 1.28 0 1.28 .32 1.12 .64 .64 .64 .32 .64 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 316.0125122070312, 124.07249450683591)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .32-.64 .64-.64 .96-.64 1.28-.32 1.28 0 1.28 .32 .96 .64 .64 .64 .32 .64 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M445.39 141.24H439.94"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M420.87 141.24H417.51"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M578.06 147.33V148.45"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M577.74 148.45V147.33"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M578.06 148.45H577.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M578.06 147.33V148.45"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M577.74 149.57H578.06"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M583.35 149.57V146.85"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M578.06 149.57H583.35"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M581.43 146.85C581.43 147.01 581.59 147.33 581.75 147.33 582.07 147.33 582.23 147.01 582.23 146.85"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 431.02502441406244, 196.16249084472653)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.16 .16-.32 .48-.32 .64-.32 .96-.16 .96 0 .96 .32 .64 .48 .48 .48 .16 .48 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M577.74 253.22H572.45"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M577.74 267.79V253.22"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M571.97 267.79V253.22"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M571.97 253.22H572.45"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M572.45 252.74C572.13 252.74 571.97 253.06 571.97 253.22"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M572.45 252.74V253.06"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M572.45 253.06C572.29 253.06 572.29 253.06 572.29 253.22"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M572.45 253.06H577.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M577.74 252.74V253.06"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M580.3 251.94 577.74 253.06"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M577.74 253.06V253.22"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M626.77 228.71H626.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M580.3 251.29V251.94"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M572.61 149.57V149.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M572.61 150.05V151.17"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M486.89 254.82C486.89 254.18 486.25 253.54 485.61 253.54 484.8 253.54 484.16 254.18 484.16 254.82"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M484.16 261.87C484.16 262.51 484.8 263.15 485.61 263.15 486.25 263.15 486.89 262.51 486.89 261.87"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M490.89 250.17C490.25 250.65 490.09 251.46 490.41 252.1 490.73 252.74 491.53 252.9 492.18 252.58"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M498.9 247.93C498.9 247.13 498.26 246.49 497.62 246.49 497.3 246.49 497.14 246.65 496.98 246.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M498.26 249.05C498.74 248.89 498.9 248.41 498.9 247.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M490.89 236.24C490.25 236.72 490.09 237.52 490.41 238.16 490.73 238.8 491.53 238.96 492.18 238.64"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M498.9 233.99C498.9 233.19 498.26 232.71 497.62 232.71 497.3 232.71 497.14 232.71 496.98 232.87"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M498.26 235.12C498.74 234.96 498.9 234.47 498.9 233.99"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M486.89 240.88C486.89 240.24 486.25 239.6 485.61 239.6 484.8 239.6 484.16 240.24 484.16 240.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M484.16 247.93C484.16 248.73 484.8 249.21 485.61 249.21 486.25 249.21 486.89 248.73 486.89 247.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M490.89 243.29C490.25 243.61 490.09 244.41 490.41 245.05 490.73 245.69 491.53 246.01 492.18 245.53"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M498.9 240.88C498.9 240.24 498.26 239.6 497.62 239.6 497.3 239.6 497.14 239.6 496.98 239.76"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M498.26 242.16C498.74 241.84 498.9 241.36 498.9 240.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M490.89 257.22C490.25 257.54 490.09 258.34 490.41 258.98 490.73 259.63 491.53 259.95 492.18 259.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M498.9 254.82C498.9 254.18 498.26 253.54 497.62 253.54 497.3 253.54 497.14 253.54 496.98 253.7"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M498.26 256.1C498.74 255.78 498.9 255.3 498.9 254.82"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M486.41 267.79C485.93 267.31 485.12 267.31 484.64 267.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M490.89 264.11C490.25 264.43 490.09 265.39 490.41 266.03 490.73 266.67 491.53 266.83 492.18 266.51"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M498.9 261.87C498.9 261.07 498.26 260.43 497.62 260.43 497.3 260.43 497.14 260.59 496.98 260.59"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M498.26 262.99C498.74 262.67 498.9 262.35 498.9 261.87"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M484.8 232.87C484.16 233.19 484 233.99 484.32 234.63 484.8 235.28 485.61 235.6 486.25 235.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M492.98 230.47C492.98 229.83 492.34 229.19 491.53 229.19 491.37 229.19 491.05 229.19 490.89 229.35"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M492.18 231.75C492.66 231.43 492.98 230.95 492.98 230.47"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M498.42 267.79C498.1 267.31 497.46 267.31 496.98 267.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M492.18 222.46C491.53 221.98 490.73 222.3 490.41 222.94 490.09 223.58 490.25 224.38 490.89 224.7"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M498.9 227.11C498.9 226.63 498.74 226.14 498.26 225.82"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M496.98 228.23C497.62 228.55 498.42 228.39 498.74 227.75 498.9 227.59 498.9 227.27 498.9 227.11"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M481.12 229.03V253.54"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M481.12 260.75V267.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M481.44 229.03V253.54"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M481.44 260.75V267.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 363.11999511718744, 170.3325042724609)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.81 .64-1.45 1.45-1.45 2.25-1.45 2.73-.81 2.73 0 2.73 .8 2.25 1.28 1.45 1.28 .64 1.28 0 .8 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 362.99999999999994, 170.3325042724609)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.97 .64-1.61 1.61-1.61 2.41-1.61 3.05-.97 3.05 0 3.05 .8 2.41 1.44 1.61 1.44 .64 1.44 0 .8 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M503.39 222.14V267.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M488.49 207.56C488.49 206.92 488.01 206.28 487.21 206.28 486.41 206.28 485.93 206.92 485.93 207.56"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M492.5 202.92C491.85 203.4 491.69 204.2 492.01 204.84 492.5 205.48 493.3 205.64 493.94 205.32"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M500.67 200.67C500.67 199.87 500.03 199.23 499.23 199.23 499.07 199.23 498.74 199.39 498.58 199.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M499.87 201.79C500.35 201.63 500.67 201.15 500.67 200.67"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M492.5 188.98C491.85 189.46 491.69 190.26 492.01 190.9 492.5 191.54 493.3 191.7 493.94 191.38"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M500.67 186.74C500.67 185.94 500.03 185.46 499.23 185.46 499.07 185.46 498.74 185.46 498.58 185.62"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M499.87 187.86C500.35 187.7 500.67 187.22 500.67 186.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M488.49 151.97C488.49 151.17 488.01 150.69 487.21 150.69 486.41 150.69 485.93 151.17 485.93 151.97"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M485.93 158.86C485.93 159.66 486.41 160.3 487.21 160.3 488.01 160.3 488.49 159.66 488.49 158.86"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M492.5 175.2C491.85 175.52 491.69 176.32 492.01 176.96 492.5 177.61 493.3 177.77 493.94 177.45"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M500.67 172.8C500.67 172.16 500.03 171.52 499.23 171.52 499.07 171.52 498.74 171.52 498.58 171.68"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M499.87 173.92C500.35 173.76 500.67 173.28 500.67 172.8"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M488.49 165.91C488.49 165.11 488.01 164.47 487.21 164.47 486.41 164.47 485.93 165.11 485.93 165.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M485.93 172.8C485.93 173.6 486.41 174.24 487.21 174.24 488.01 174.24 488.49 173.6 488.49 172.8"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M492.5 182.09C491.85 182.41 491.69 183.21 492.01 183.85 492.5 184.65 493.3 184.81 493.94 184.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M500.67 179.85C500.67 179.05 500.03 178.41 499.23 178.41 499.07 178.41 498.74 178.57 498.58 178.57"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M499.87 180.97C500.35 180.65 500.67 180.33 500.67 179.85"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M492.5 147.33C491.85 147.65 491.69 148.45 492.01 149.09 492.5 149.73 493.3 150.05 493.94 149.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M500.67 145.09C500.67 144.29 500.03 143.64 499.23 143.64 499.07 143.64 498.74 143.64 498.58 143.8"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M499.87 146.21C500.35 145.89 500.67 145.57 500.67 145.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M488.49 179.85C488.49 179.05 488.01 178.41 487.21 178.41 486.41 178.41 485.93 179.05 485.93 179.85"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M485.93 186.74C485.93 187.54 486.41 188.02 487.21 188.02 488.01 188.02 488.49 187.54 488.49 186.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M492.5 168.15C491.85 168.47 491.69 169.44 492.01 170.08 492.5 170.72 493.3 170.88 493.94 170.56"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M500.67 165.91C500.67 165.11 500.03 164.47 499.23 164.47 499.07 164.47 498.74 164.63 498.58 164.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M499.87 167.03C500.35 166.87 500.67 166.39 500.67 165.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M492.5 154.22C491.85 154.7 491.69 155.5 492.01 156.14 492.5 156.78 493.3 156.94 493.94 156.62"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M500.67 151.97C500.67 151.17 500.03 150.69 499.23 150.69 499.07 150.69 498.74 150.69 498.58 150.85"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M499.87 153.1C500.35 152.94 500.67 152.46 500.67 151.97"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M488.49 193.62C488.49 192.98 488.01 192.34 487.21 192.34 486.41 192.34 485.93 192.98 485.93 193.62"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M485.93 200.67C485.93 201.31 486.41 201.96 487.21 201.96 488.01 201.96 488.49 201.31 488.49 200.67"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M492.5 161.27C491.85 161.59 491.69 162.39 492.01 163.03 492.5 163.67 493.3 163.99 493.94 163.51"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M500.67 158.86C500.67 158.22 500.03 157.58 499.23 157.58 499.07 157.58 498.74 157.58 498.58 157.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M499.87 160.14C500.35 159.82 500.67 159.34 500.67 158.86"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M492.5 196.03C491.85 196.35 491.69 197.15 492.01 197.79 492.5 198.43 493.3 198.75 493.94 198.27"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M500.67 193.62C500.67 192.98 500.03 192.34 499.23 192.34 499.07 192.34 498.74 192.34 498.58 192.5"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M499.87 194.91C500.35 194.59 500.67 194.11 500.67 193.62"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M486.57 143.8C485.93 144.29 485.61 145.09 486.09 145.73 486.41 146.37 487.21 146.53 487.85 146.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M494.58 141.56C494.58 140.76 493.94 140.12 493.3 140.12 492.98 140.12 492.82 140.28 492.5 140.44"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M493.94 142.68C494.26 142.52 494.58 142.04 494.58 141.56"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M493.94 209.96C493.3 209.48 492.5 209.8 492.01 210.45 491.69 210.93 491.85 211.73 492.34 212.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M498.58 139.16C499.23 139.64 500.03 139.32 500.51 138.68 500.51 138.52 500.67 138.36 500.67 138.04"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M482.72 138.04V142.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M482.72 149.73V170.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M482.72 178.25V199.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M482.72 206.6V212.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M483.2 138.04V142.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M483.2 149.73V170.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M483.2 178.25V199.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M483.2 206.6V212.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 373.33499145507807, 155.66999816894528)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.8 .64-1.44 1.45-1.44 2.25-1.44 2.89-.8 2.89 0 2.89 .8 2.25 1.44 1.45 1.44 .64 1.44 0 .8 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 373.33499145507807, 155.66999816894528)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.8 .64-1.6 1.45-1.6 2.41-1.6 3.05-.8 3.05 0 3.05 .96 2.41 1.6 1.45 1.6 .64 1.6 0 .96 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M485.77 138.04C485.77 138.84 486.41 139.48 487.21 139.48 488.01 139.48 488.65 138.84 488.65 138.04"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M485.61 138.04C485.61 139 486.41 139.64 487.21 139.64 488.17 139.64 488.81 138.84 488.81 138.04"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M504.99 136.92V137.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M504.99 138.04V212.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M491.05 267.79 490.89 267.31"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M500.83 264.11 499.71 262.19"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M502.43 175.04 501.31 173.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M493.62 180.17 492.5 178.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M492.34 179.85 492.18 179.53"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M576.3 150.69H573.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M625.97 174.56 576.3 150.69"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M625.97 228.55V174.56"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M576.3 252.42 625.97 228.55"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M573.74 252.42H576.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M524.06 228.55 573.74 252.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M524.06 174.56V228.55"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M573.74 150.69 524.06 174.56"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M523.26 222.14V216.69"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M523.26 222.14H462.85"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 345.69750976562494, 163.71749877929685)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .16-.48 .48-.48 .64-.48 .96-.32 .96 0 .96 .16 .64 .48 .48 .48 .16 .48 0 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 385.83752441406244, 164.56500244140622)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.16 .32-.48 .48-.48 .8-.48 .96-.16 .96 0 .96 .32 .8 .48 .48 .48 .32 .48 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M523.26 216.21H462.85"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M523.26 216.69V216.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M523.74 216.69C523.74 216.53 523.58 216.21 523.26 216.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M523.58 216.69H523.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M523.58 216.69C523.58 216.69 523.42 216.53 523.26 216.53"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M523.74 216.69V222.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M523.74 222.14H523.58"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M523.58 216.69V222.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 334.7625122070312, 165.52499389648435)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .16-.48 .48-.48 .64-.48 .96-.32 .96 0 .96 .32 .64 .48 .48 .48 .16 .48 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 311.8049926757812, 165.52499389648435)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .16-.48 .48-.48 .64-.48 .96-.32 .96 0 .96 .32 .64 .48 .48 .48 .16 .48 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M524.06 216.21H523.26"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M523.74 216.53H524.06"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M523.26 222.14H523.58"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M523.74 222.14H524.06"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M444.26 143.16V142.04"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.21 245.05H430.32"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.21 247.93V245.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.25 247.93H433.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.13 247.93H432.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.32 247.93H431.13"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.32 245.05V247.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 323.22000122070307, 184.86750793457028)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.48 .32-.8 .8-.8 1.28-.8 1.6-.48 1.6 0 1.6 .48 1.28 .8 .8 .8 .32 .8 0 .48 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.25 247.93V248.57"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.13 248.57H432.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.13 248.57V247.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.21 248.89H430.32"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.21 251.62V248.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.25 251.62H433.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.13 251.62H432.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.32 251.62H431.13"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.32 248.89V251.62"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 323.22000122070307, 187.62750244140622)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .33-.81 .81-.81 1.29-.81 1.61-.32 1.61 0 1.61 .48 1.29 .96 .81 .96 .33 .96 0 .48 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.13 252.26H432.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.13 252.26V251.62"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.25 251.62V252.26"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.21 252.58H430.32"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.21 255.46V252.58"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.25 255.46H433.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.13 255.46H432.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.32 255.46H431.13"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.32 252.58V255.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 323.22000122070307, 190.5149993896484)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.48 .32-.8 .8-.8 1.28-.8 1.6-.48 1.6 0 1.6 .48 1.28 .8 .8 .8 .32 .8 0 .48 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.13 256.1H432.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.25 255.46V256.1"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.13 256.1V255.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.21 256.42H430.32"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.21 259.14V256.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.25 259.14H433.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.13 259.14H432.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.32 259.14H431.13"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.32 256.42V259.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 323.22000122070307, 193.2750091552734)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.48 .32-.8 .8-.8 1.28-.8 1.76-.48 1.76 0 1.76 .48 1.28 .96 .8 .96 .32 .96 0 .48 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.25 259.14V259.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.13 259.79H432.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.13 259.79V259.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.21 260.11H430.32"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.21 262.83V260.11"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.25 262.83H433.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.13 262.83H432.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.32 260.11V262.83"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 323.22000122070307, 196.16249084472653)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.48 .32-.8 .8-.8 1.28-.8 1.6-.48 1.6 0 1.6 .48 1.28 .8 .8 .8 .32 .8 0 .48 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.32 262.83H431.13"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.13 263.63H432.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.25 262.83V263.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.13 263.63V262.83"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.57 252.1 432.41 251.94 432.25 251.62"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.13 251.62C430.96 251.94 430.96 252.26 431.29 252.58"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.25 252.58 432.41 252.42 432.57 252.1"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.57 263.31 432.41 263.15 432.25 262.83"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 323.3475036621093, 197.12249755859372)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C-.17 .32-.17 .8 .16 1.12 .48 1.44 .96 1.28 1.12 1.12 1.28 .96 1.44 .8 1.44 .48"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.16 267.15V240.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.16 240.88H430.96"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.96 240.88C430.96 241.2 431.29 241.52 431.77 241.52 432.25 241.52 432.57 241.2 432.57 240.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M433.37 267.15V240.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.57 267.15H433.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 323.22000122070307, 193.2750091552734)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .33-.64 .81-.64 1.29-.64 1.61-.32 1.61 0 1.61 .48 1.29 .8 .81 .8 .33 .8 0 .48 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 323.22000122070307, 196.16249084472653)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.48 .33-.8 .81-.8 1.29-.8 1.61-.48 1.61 0 1.61 .32 1.29 .64 .81 .64 .33 .64 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 323.22000122070307, 198.9225158691406)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .33-.64 .81-.64 1.29-.64 1.61-.32 1.61 0 1.61 .48 1.29 .8 .81 .8 .33 .8 0 .48 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M430.16 267.15H430.96"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 323.22000122070307, 190.5149993896484)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.48 .33-.8 .81-.8 1.29-.8 1.61-.48 1.61 0 1.61 .32 1.29 .64 .81 .64 .33 .64 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.57 259.63 432.41 259.3 432.25 259.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.13 259.14C430.96 259.46 430.96 259.79 431.29 260.11"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.25 260.11 432.41 259.95 432.57 259.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 323.22000122070307, 187.62750244140622)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .33-.64 .81-.64 1.29-.64 1.61-.32 1.61 0 1.61 .48 1.29 .8 .81 .8 .33 .8 0 .48 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 324.42749023437494, 183.42750549316403)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32-.32-.64-.8-.64-1.28-.64-1.61-.32-1.61 0-1.61 .16-1.44 .32-1.28 .48"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.25 245.05 432.41 244.89 432.57 244.57"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.57 248.41 432.41 248.09 432.25 247.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.13 247.93C430.96 248.09 430.96 248.57 431.29 248.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.25 248.89 432.41 248.57 432.57 248.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.57 240.88H433.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.57 255.94 432.41 255.62 432.25 255.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M431.13 255.46C430.96 255.62 430.96 256.1 431.29 256.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.25 256.42 432.41 256.1 432.57 255.94"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 323.22000122070307, 182.1000061035156)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.48 .32-.8 .8-.8 1.28-.8 1.6-.48 1.6 0 1.6 .48 1.28 .8 .8 .8 .32 .8 0 .48 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.57 267.15C432.57 266.67 432.25 266.35 431.77 266.35 431.29 266.35 430.96 266.67 430.96 267.15"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 323.22000122070307, 184.86750793457028)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.48 .32-.8 .8-.8 1.28-.8 1.6-.48 1.6 0 1.6 .48 1.28 .8 .8 .8 .32 .8 0 .48 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M412.7 244.41V138.04"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M412.06 244.41H412.7"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M407.41 244.41V138.04"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M407.41 244.41H407.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M407.89 244.41H412.06"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M450.83 244.41H455"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M455.64 244.41V138.04"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M455 244.41H455.64"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M450.35 244.41V138.04"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M450.35 244.41H450.83"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M631.74 138.04V267.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 474.88500976562494, 134.2875061035156)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .32-.64 .64-.64 .96-.64 1.12-.32 1.12 0 1.12 .32 .96 .48 .64 .48 .32 .48 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 474.88500976562494, 102.33000183105469)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .32-.49 .48-.49 .8-.49 .96-.32 .96 0 .96 .16 .8 .32 .48 .32 .32 .32 0 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 474.88500976562494, 164.92498779296872)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .32-.48 .48-.48 .8-.48 .96-.32 .96 0 .96 .16 .8 .48 .48 .48 .32 .48 0 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 474.88500976562494, 168.04499816894528)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.16 .32-.48 .64-.48 .96-.48 1.12-.16 1.12 0 1.12 .32 .96 .64 .64 .64 .32 .64 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 474.4049682617187, 192.43499755859372)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .16-.64 .64-.64 .96-.64 1.28-.32 1.28 0 1.28 .32 .96 .64 .64 .64 .16 .64 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M638.63 249.85V250.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M402.92 138.04V138.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 300.3899841308593, 102.33000183105469)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .16-.49 .48-.49 .8-.49 .96-.32 .96 0 .96 .16 .8 .32 .48 .32 .16 .32 0 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M401.48 141.72C401.48 141.56 401.32 141.24 401 141.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M401 142.2C401.32 142.2 401.48 142.04 401.48 141.72"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M396.03 249.85V250.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M401.32 219.74C401.32 219.74 401.32 219.9 401.48 219.9"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M481.6 259.95V254.34"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M481.6 254.34H480.96"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M480.96 254.34V259.95"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M481.76 260.75V253.54"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M480.8 260.75V253.54"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M480.8 253.54H481.76"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M480.96 259.95H481.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M481.76 260.75H480.8"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M481.6 228.23V222.62"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M481.6 222.62H480.96"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M480.96 222.62V228.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M481.76 229.03V222.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M480.8 229.03V222.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M480.96 228.23H481.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M481.76 229.03H480.8"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M481.44 259.95V254.34"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M481.44 228.23V222.62"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M481.12 254.34V259.95"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M481.12 222.62V228.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M480.8 267.79V260.75"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M480.8 253.54V229.03"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M483.36 177.29H482.72"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M483.36 171.84V177.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M482.72 171.84H483.36"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M482.72 177.29V171.84"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M483.52 178.25V170.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M482.56 178.25H483.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M482.56 178.25V170.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M483.52 170.88H482.56"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M483.36 143.48V148.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M482.72 143.48H483.36"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M482.72 148.93V143.48"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M482.56 149.73V142.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M483.52 142.52H482.56"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M483.52 149.73V142.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M482.56 149.73H483.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M483.36 148.93H482.72"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M483.36 205.64H482.72"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M483.36 200.19V205.64"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M482.72 205.64V200.19"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M483.52 206.6V199.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M482.56 206.6H483.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M482.56 206.6V199.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M483.52 199.39H482.56"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M482.72 200.19H483.36"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M483.2 200.19V205.64"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M482.88 205.64V200.19"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M483.2 171.84V177.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M483.2 143.48V148.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M482.88 148.93V143.48"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M482.88 177.29V171.84"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M482.56 212.05V206.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M482.56 199.39V178.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M482.56 170.88V149.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M482.56 142.52V138.04"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M459.49 138.04V138.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M459.17 138.04V138.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M432.57 237.04 431.29 237.84"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.95 189.14V188.98"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M418.95 188.98 419.11 188.82"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M419.27 188.98 419.11 188.82"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.62 142.2V143"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.62 164.31V165.27"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M443.62 188.02V188.98"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M421.35 165.27V165.59"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M444.42 164.31V165.27"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M444.42 188.02V188.98"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M444.1 164.15V165.43"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M444.1 187.86V189.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 380.0700073242187, 161.79750061035153)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V.16 .32 .48H-.16"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M506.6 216.21H462.85"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M506.76 212.05H462.85"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M506.76 212.05V215.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M506.76 215.89C506.76 215.89 506.92 215.89 506.92 215.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M507.08 215.73H506.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M506.6 216.21C506.92 216.21 507.08 216.05 507.08 215.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M506.92 215.73V212.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M507.08 212.05H506.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M507.08 215.73V212.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M394.75 129.71H393.31"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M394.59 133.55H393.47"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M641.35 129.71H639.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M641.19 133.55H640.07"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 386.79748535156244, 96.08250427246094)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16V.16 .32 .48"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M636.55 130.67V128.59"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M515.57 128.59V130.67"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 477.41247558593744, 96.44249725341797)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0-.16-.32-.48H-.16"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M636.39 128.11H634.62"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M517.49 128.11H515.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M636.87 128.59H636.71"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M636.71 128.59 636.55 128.43"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M636.87 128.59C636.87 128.27 636.71 128.11 636.39 128.11"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M636.87 128.59V128.75"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M515.25 128.59H515.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M515.25 128.75V128.59"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M515.73 128.11C515.41 128.11 515.25 128.27 515.25 128.59"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M515.57 128.43 515.41 128.59"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M515.25 129.23H514.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M515.25 130.67V129.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M514.93 130.67V129.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M637.19 129.23V130.67"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M636.87 129.23H637.19"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M636.87 129.23V130.67"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M517.49 128.59H515.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M517.49 128.91H515.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M515.41 128.91V128.59"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M636.87 129.23C636.87 129.07 636.71 128.91 636.71 128.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M636.71 128.91V128.59"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M637.19 129.23C637.19 128.91 636.87 128.59 636.71 128.59"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M636.71 128.91H634.62"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M636.71 128.59H634.62"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M515.41 128.91C515.41 128.91 515.25 129.07 515.25 129.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M515.41 128.59C515.25 128.59 514.93 128.91 514.93 129.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M634.62 97.67H634.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M517.81 97.67H517.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M634.62 97.83V97.67"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M517.65 130.35H517.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M634.3 130.35H634.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M517.65 97.67V97.83"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M517.65 130.19V130.35"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M634.62 128.91H636.87"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M636.87 128.91V130.67"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M515.25 130.67V128.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M515.25 128.91H517.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M636.87 127.79V128.59"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M634.62 127.79H636.87"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M515.25 127.79H517.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M515.25 128.59V127.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M448.59 130.35H446.19"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M438.82 130.35H422.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M415.58 130.35H414.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M407.09 130.35H398.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M448.59 122.98H401.8"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M447.15 122.18H403.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M446.99 119.94H403.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M447.31 121.7H403.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M447.31 125.06H403.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M626.77 93.18H579.98"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M625.33 92.38H581.43"
    />
    <g
      clipPath="url(#clip_23)"
      transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -5.684341886080802e-14, 0)"
    >
      <path
        transform="matrix(.75,0,0,.75,0,0)"
        strokeWidth={0.801}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="#040503"
        d="M625.17 89.98H581.59"
      />
    </g>
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M625.49 91.74H581.27"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M625.49 95.11H581.27"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M572.13 93.18H525.34"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M570.69 92.38H526.79"
    />
    <g
      clipPath="url(#clip_24)"
      transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -5.684341886080802e-14, 0)"
    >
      <path
        transform="matrix(.75,0,0,.75,0,0)"
        strokeWidth={0.801}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="#040503"
        d="M570.53 89.98H526.95"
      />
    </g>
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M570.85 91.74H526.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M570.85 95.11H526.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M628.22 112.09C629.34 112.89 630.78 112.89 631.74 112.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M628.38 112.09H628.22"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M628.38 112.09C629.34 112.89 630.62 112.89 631.74 112.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M632.06 112.41C631.42 113.53 631.42 114.81 632.06 115.77"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M632.06 115.93V115.77"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M632.06 112.41C631.26 113.53 631.26 114.81 632.06 115.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M627.89 115.93C628.7 114.81 628.7 113.53 627.89 112.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M627.89 115.77V115.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M627.89 115.77C628.7 114.81 628.7 113.53 627.89 112.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M631.74 116.25C630.78 115.45 629.34 115.45 628.22 116.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M631.74 116.25C630.62 115.45 629.34 115.45 628.38 116.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M628.22 116.25H628.38"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M628.22 112.09C628.06 112.09 627.89 112.25 627.89 112.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M632.06 112.41C632.06 112.25 631.9 112.09 631.74 112.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M631.74 116.25C631.9 116.25 632.06 116.09 632.06 115.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M627.89 115.93C627.89 116.09 628.06 116.25 628.22 116.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M632.06 112.41C632.06 112.25 631.9 112.09 631.74 112.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M628.22 112.09C628.06 112.09 627.89 112.25 627.89 112.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M631.74 116.25C631.9 116.25 632.06 116.09 632.06 115.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M627.89 115.93C627.89 116.09 628.06 116.25 628.22 116.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M628.38 112.09H628.22"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M632.06 115.93V115.77"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M627.89 115.77V115.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M628.22 116.25H628.38"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M523.9 112.09H523.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M520.38 112.09C521.34 112.89 522.78 112.89 523.9 112.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M520.38 112.09C521.5 112.89 522.78 112.89 523.74 112.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M524.22 112.41C523.42 113.53 523.42 114.81 524.22 115.77"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M524.22 115.93V115.77"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M524.22 112.41C523.42 113.53 523.42 114.81 524.22 115.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M520.06 115.93C520.86 114.81 520.86 113.53 520.06 112.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M520.06 115.77V115.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M520.06 115.77C520.7 114.81 520.7 113.53 520.06 112.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M523.9 116.25C522.78 115.45 521.34 115.45 520.38 116.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M523.74 116.25H523.9"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M523.74 116.25C522.78 115.45 521.5 115.45 520.38 116.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M520.38 112.09C520.22 112.09 520.06 112.25 520.06 112.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M524.22 112.41C524.22 112.25 524.06 112.09 523.9 112.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M523.9 116.25C524.06 116.25 524.22 116.09 524.22 115.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M520.06 115.93C520.06 116.09 520.22 116.25 520.38 116.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M524.22 112.41C524.22 112.25 524.06 112.09 523.9 112.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M520.38 112.09C520.22 112.09 520.06 112.25 520.06 112.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M523.9 116.25C524.06 116.25 524.22 116.09 524.22 115.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M520.06 115.93C520.06 116.09 520.22 116.25 520.38 116.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M523.9 112.09H523.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M524.22 115.93V115.77"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M520.06 115.77V115.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M523.74 116.25H523.9"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M517.97 97.99 517.81 97.51"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M634.3 97.51H517.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M634.3 97.51 634.14 97.99"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M634.14 97.99H517.97"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M517.97 130.03 517.49 130.35"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M517.49 97.83V130.35"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M517.49 97.83 517.97 97.99"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M517.97 97.99V130.03"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M517.97 130.03 517.65 130.35"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M517.49 130.35H517.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M634.14 97.99 634.62 97.83"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M634.62 130.35V97.83"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M634.62 130.35 634.14 130.03"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M634.14 130.03V97.99"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M517.97 97.99 517.81 97.67"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M517.81 97.51V97.67"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M634.14 97.99 634.46 97.83"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M517.65 97.83 517.97 97.99"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M517.49 97.83H517.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M634.46 130.35H634.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M634.62 130.35H634.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M517.97 130.03H634.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M523.74 114.49C523.74 114.33 523.74 114.01 523.74 113.85"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M628.38 113.85C628.38 114.01 628.38 114.33 628.38 114.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M520.54 113.85C520.38 114.01 520.38 114.33 520.54 114.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M631.58 114.49C631.74 114.33 631.74 114.01 631.58 113.85"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M634.14 130.03 634.3 130.67"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M517.81 130.67 517.97 130.03"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M634.3 97.67 634.14 97.99"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M634.3 97.51V97.67"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M634.3 130.67V130.35"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M517.81 130.67V130.35"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M638.63 133.39V133.87"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M396.03 133.87H638.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M396.03 133.87V133.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M396.03 133.39H638.63"
    />
    <g
      clipPath="url(#clip_25)"
      transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -5.684341886080802e-14, 0)"
    >
      <path
        transform="matrix(.75,0,0,.75,0,0)"
        strokeWidth={0.801}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="#040503"
        d="M642.32 131.15H392.51"
      />
      <path
        transform="matrix(.75,0,0,.75,0,0)"
        strokeWidth={0.801}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="#040503"
        d="M642.32 133.39V131.15"
      />
      <path
        transform="matrix(.75,0,0,.75,0,0)"
        strokeWidth={0.801}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="#040503"
        d="M642.32 133.39H638.63"
      />
    </g>
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M392.51 131.15V133.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M392.51 133.39H396.03"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 294.5025024414062, 98.00250244140625)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16V.16 .32 .48"
    />
    <g
      clipPath="url(#clip_26)"
      transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -5.684341886080802e-14, 0)"
    >
      <path
        transform="matrix(.75,0,0,.75,481.74,98.362499)"
        strokeWidth={0.801}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="#040503"
        d="M0 0-.16-.16V-.32-.48H-.32"
      />
    </g>
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M642 130.67H392.67"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M642 133.71V133.87"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M638.95 133.87V133.71"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M642 133.71H638.95"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M642 133.87H638.95"
    />
    <g
      clipPath="url(#clip_27)"
      transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -5.684341886080802e-14, 0)"
    >
      <path
        transform="matrix(.75,0,0,.75,0,0)"
        strokeWidth={0.801}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="#040503"
        d="M642.32 133.39H642.48"
      />
    </g>
    <g
      clipPath="url(#clip_28)"
      transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -5.684341886080802e-14, 0)"
    >
      <path
        transform="matrix(.75,0,0,.75,0,0)"
        strokeWidth={0.801}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="#040503"
        d="M642 133.87C642.32 133.87 642.48 133.71 642.48 133.39"
      />
    </g>
    <g
      clipPath="url(#clip_29)"
      transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -5.684341886080802e-14, 0)"
    >
      <path
        transform="matrix(.75,0,0,.75,0,0)"
        strokeWidth={0.801}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="#040503"
        d="M642 133.71C642.16 133.71 642.32 133.55 642.32 133.39"
      />
    </g>
    <g
      clipPath="url(#clip_30)"
      transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -5.684341886080802e-14, 0)"
    >
      <path
        transform="matrix(.75,0,0,.75,0,0)"
        strokeWidth={0.801}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="#040503"
        d="M642.32 131.15H642.48"
      />
    </g>
    <g
      clipPath="url(#clip_31)"
      transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -5.684341886080802e-14, 0)"
    >
      <path
        transform="matrix(.75,0,0,.75,0,0)"
        strokeWidth={0.801}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="#040503"
        d="M642.48 131.15V133.39"
      />
    </g>
    <g
      clipPath="url(#clip_32)"
      transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -5.684341886080802e-14, 0)"
    >
      <path
        transform="matrix(.75,0,0,.75,0,0)"
        strokeWidth={0.801}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="#040503"
        d="M642.32 131.15V133.39"
      />
      <path
        transform="matrix(.75,0,0,.75,0,0)"
        strokeWidth={0.801}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="#040503"
        d="M642.32 131.15C642.32 130.99 642.32 130.99 642.16 130.99"
      />
    </g>
    <g
      clipPath="url(#clip_33)"
      transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -5.684341886080802e-14, 0)"
    >
      <path
        transform="matrix(.75,0,0,.75,0,0)"
        strokeWidth={0.801}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="#040503"
        d="M642.48 131.15C642.48 130.83 642.32 130.67 642 130.67"
      />
    </g>
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M392.51 130.99C392.35 130.99 392.35 130.99 392.35 131.15"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M392.35 131.15H392.19"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M392.67 130.67C392.35 130.67 392.19 130.83 392.19 131.15"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M392.67 133.71V133.87"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M395.71 133.87V133.71"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M395.71 133.87H392.67"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M395.71 133.71H392.67"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M392.19 133.39V131.15"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M392.35 133.39H392.19"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M392.35 133.39V131.15"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M392.19 133.39C392.19 133.71 392.35 133.87 392.67 133.87"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M392.35 133.39C392.35 133.55 392.51 133.71 392.67 133.71"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M638.63 134.19V133.87"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M396.03 133.87V134.19"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 430.6649780273437, 102.33000183105469)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .32-.49 .48-.49 .8-.49 1.12-.32 1.12 0 1.12 .32 .8 .48 .48 .48 .32 .48 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 430.7850036621093, 103.16999816894531)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .16-.48 .32-.48 .64-.48 .8-.32 .8 0 .8 .16 .64 .48 .32 .48 .16 .48 0 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 300.2699890136718, 102.33000183105469)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.49 .32-.81 .64-.81 .96-.81 1.28-.49 1.28 0 1.28 .32 .96 .64 .64 .64 .32 .64 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 343.1699829101562, 102.33000183105469)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .16-.49 .48-.49 .8-.49 .96-.32 .96 0 .96 .16 .8 .32 .48 .32 .16 .32 0 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 346.17749023437494, 102.33000183105469)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .32-.49 .48-.49 .8-.49 .96-.32 .96 0 .96 .16 .8 .32 .48 .32 .32 .32 0 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 410.5950317382812, 102.33000183105469)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .16-.49 .48-.49 .64-.49 .96-.32 .96 0 .96 .16 .64 .32 .48 .32 .16 .32 0 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 474.76501464843744, 102.33000183105469)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.49 .32-.81 .64-.81 1.12-.81 1.44-.49 1.44 0 1.44 .32 1.12 .64 .64 .64 .32 .64 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 392.5650024414062, 103.04999542236328)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .32-.48 .48-.48 .8-.48 1.12-.32 1.12 0 1.12 .32 .8 .48 .48 .48 .32 .48 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 378.3825073242187, 103.04999542236328)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .32-.48 .48-.48 .8-.48 1.12-.32 1.12 0 1.12 .32 .8 .48 .48 .48 .32 .48 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 357.4725036621093, 103.04999542236328)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .32-.48 .64-.48 .96-.48 1.12-.32 1.12 0 1.12 .32 .96 .48 .64 .48 .32 .48 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M635.91 138.04H398.76"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M396.03 134.19C396.03 134.03 395.87 133.87 395.71 133.71"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M395.71 134.19C395.71 134.03 395.55 133.87 395.55 133.87"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M639.27 133.87C639.11 133.87 638.95 134.03 638.95 134.19"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M638.95 133.71C638.79 133.87 638.63 134.03 638.63 134.19"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M395.71 133.71H396.03"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M392.51 133.55V133.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M638.63 133.71H638.95"
    />
    <g
      clipPath="url(#clip_34)"
      transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -5.684341886080802e-14, 0)"
    >
      <path
        transform="matrix(.75,0,0,.75,0,0)"
        strokeWidth={0.801}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="#040503"
        d="M642.32 133.39V133.55"
      />
    </g>
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M636.39 413.73V434.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M635.91 413.73V434.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M635.91 413.73H636.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M636.39 434.88H635.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 478.8524780273437, 492.8024902343749)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .16-.48 .48-.48 .64-.48 .8-.32 .8 0 .8 .16 .64 .32 .48 .32 .16 .32 0 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 478.7324829101562, 187.62750244140622)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.16 .32-.48 .48-.48 .8-.48 .96-.16 .96 0 .96 .32 .8 .48 .48 .48 .32 .48 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M642 134.19H636.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M642 663.47H636.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M636.39 663.47V658.83"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M636.39 438.72V658.83"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M636.39 438.72H639.75"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M639.75 438.72V434.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M639.75 409.89H636.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M636.39 413.73H639.75"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M639.75 413.73V409.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M639.75 434.88H636.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 478.8524780273437, 444.74249267578114)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.16 .16-.48 .48-.48 .64-.48 .8-.16 .8 0 .8 .32 .64 .48 .48 .48 .16 .48 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M636.39 658.83H635.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M635.91 438.72V658.83"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M635.91 438.72H636.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M635.91 134.19V267.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M635.91 311.37V409.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M635.91 134.19H636.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M636.39 409.89H635.91"
    />
    <g
      clipPath="url(#clip_35)"
      transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -5.684341886080802e-14, 0)"
    >
      <path
        transform="matrix(.75,0,0,.75,0,0)"
        strokeWidth={0.801}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="#040503"
        d="M642.48 134.35V268.12"
      />
    </g>
    <g
      clipPath="url(#clip_36)"
      transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -5.684341886080802e-14, 0)"
    >
      <path
        transform="matrix(.75,0,0,.75,0,0)"
        strokeWidth={0.801}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="#040503"
        d="M642.48 311.05V663.47"
      />
    </g>
    <g
      clipPath="url(#clip_37)"
      transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -5.684341886080802e-14, 0)"
    >
      <path
        transform="matrix(.75,0,0,.75,0,0)"
        strokeWidth={0.801}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="#040503"
        d="M642.48 663.47H642"
      />
    </g>
    <g
      clipPath="url(#clip_38)"
      transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -5.684341886080802e-14, 0)"
    >
      <path
        transform="matrix(.75,0,0,.75,0,0)"
        strokeWidth={0.801}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="#040503"
        d="M642.48 134.19V134.35"
      />
    </g>
    <g
      clipPath="url(#clip_39)"
      transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -5.684341886080802e-14, 0)"
    >
      <path
        transform="matrix(.75,0,0,.75,0,0)"
        strokeWidth={0.801}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="#040503"
        d="M642 134.19H642.48"
      />
    </g>
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M635.91 409.89V413.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M636.07 409.89V413.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M639.27 133.87V134.19"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M642 134.19V133.87"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M635.91 434.88V438.72"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M636.07 434.88V438.72"
    />
    <g
      clipPath="url(#clip_40)"
      transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -5.684341886080802e-14, 0)"
    >
      <path
        transform="matrix(.75,0,0,.75,0,0)"
        strokeWidth={0.801}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="#040503"
        d="M642.48 134.19C642.32 134.03 642.16 133.87 642 133.87"
      />
    </g>
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M638.63 249.85V250.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M638.63 409.89V413.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M638.63 434.88V438.72"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M638.63 592.67V593.47"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M638.63 656.59V657.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 477.8925170898437, 327.5999755859375)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .32-.48 .48-.48 .8-.48 .96-.32 .96 0 .96 .32 .8 .48 .48 .48 .32 .48 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 477.8925170898437, 308.85748291015625)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .32-.48 .48-.48 .8-.48 .96-.32 .96 0 .96 .16 .8 .48 .48 .48 .32 .48 0 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M632.54 590.91V601"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M632.54 605.16V657.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 475.24499511718744, 485.59503173828114)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.16 .16-.48 .48-.48 .64-.48 .8-.16 .8 0 .8 .32 .64 .48 .48 .48 .16 .48 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M632.54 657.39H635.59"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M635.59 657.39H635.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M392.67 663.47H398.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M392.67 134.19H398.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 296.54251098632807, 492.8024902343749)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .16-.48 .32-.48 .64-.48 .8-.32 .8 0 .8 .16 .64 .32 .32 .32 .16 .32 0 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 296.54251098632807, 444.74249267578114)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.16 .16-.48 .32-.48 .64-.48 .8-.16 .8 0 .8 .32 .64 .48 .32 .48 .16 .48 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 296.54251098632807, 187.62750244140622)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.16 .16-.48 .48-.48 .8-.48 .96-.16 .96 0 .96 .32 .8 .48 .48 .48 .16 .48 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M398.28 658.83V663.47"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M392.19 134.35V268.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M392.19 311.05V663.47"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M392.19 134.19V134.35"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M392.19 134.19H392.67"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M392.67 663.47H392.19"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M398.76 134.19V267.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M398.76 311.37V658.83"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M398.28 134.19H398.76"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M398.28 658.83H398.76"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M392.67 134.19V133.87"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M392.67 133.87C392.51 133.87 392.35 134.03 392.19 134.19"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M395.39 133.87V134.19"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M396.03 249.85V250.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M396.03 592.67V593.47"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M396.03 656.59V657.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M398.76 657.39H399.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M399.08 657.39H402.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 300.15002441406244, 235.4474945068359)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .16-.48 .48-.48 .64-.48 .8-.32 .8 0 .8 .16 .64 .32 .48 .32 .16 .32 0 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 300.15002441406244, 381.6675109863281)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .16-.48 .48-.48 .64-.48 .8-.32 .8 0 .8 .16 .64 .48 .48 .48 .16 .48 0 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M402.12 312.49V657.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 300.15002441406244, 485.59503173828114)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.16 .16-.48 .48-.48 .64-.48 .8-.16 .8 0 .8 .32 .64 .48 .48 .48 .16 .48 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M548.74 154.22H549.06"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M550.82 152.46V152.13 151.97"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M549.06 150.21H548.74 548.58"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M546.82 151.97V152.13 152.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M548.58 154.22H548.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M569.57 144.61H572.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M574.86 138.04V137.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M577.26 138.04V149.57"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M527.59 172.96H524.54"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M572.61 252.42V252.58"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M572.61 253.06V253.22"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M572.29 251.78V252.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M594.25 249.21H592"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M579.02 138.04V138.36"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M585.27 138.68V138.04"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M425.68 312.17H428.72"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M422.79 312.17H425.68"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M417.99 312.17H421.03"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M414.94 312.17H417.99"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M410.13 312.17H413.18"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M407.09 312.17H410.13"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M507.72 405.08 508.2 404.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M508.36 396.27 506.28 397.07"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M507.72 405.08C507.72 405.24 507.88 405.4 507.88 405.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 379.70999145507807, 297.802490234375)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V-.16-.32"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 378.8624877929687, 301.2900085449219)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0-.16"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M507.72 405.08V405.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M506.28 397.07V397.07L506.12 396.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M628.54 607.09H631.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 467.91751098632807, 458.7974853515624)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16V.16 .48 .8 1.12 1.6 1.92 2.24 2.57 2.73L0 2.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 432.82498168945307, 458.7974853515624)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0 .16L-.16 .48V.8 1.12 1.6 1.92 2.24L0 2.57V2.73 2.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M633.18 598.28H635.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 471.0449829101562, 452.78997802734364)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V.16 .32 .48 .8 1.12 1.44 1.92 2.25L.16 2.57V2.73 2.89L.32 3.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 438.4725036621093, 455.0775146484374)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0 .16 .32 .64 .96 1.44 1.76 2.08L.16 2.4V2.56 2.72L.32 2.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M572.29 611.73C571.49 611.73 570.85 612.37 570.85 613.17 570.85 613.97 571.49 614.62 572.29 614.62"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M579.02 607.25C578.22 607.57 577.74 608.37 577.9 609.01 578.06 609.81 578.86 610.29 579.5 610.13"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M560.12 611.73C559.31 611.73 558.67 612.37 558.67 613.17 558.83 613.97 559.31 614.62 560.12 614.62"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M559.63 607.57H556.75"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M559.79 605.81H559.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M556.91 594.27H553.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M563.16 594.27H559.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M394.11 663.47V668.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M640.55 663.47V668.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M457.4 267.79V138.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M405.49 138.52V267.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M406.29 138.04V267.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M456.76 138.04V267.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M612.67 246.81V267.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M611.87 246.81V267.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M631.42 246.81V267.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M608.99 247.13H609.15"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M483.36 590.91V658.83"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M607.87 414.85H608.51"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 457.94250488281244, 313.54498291015625)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V-.16L-.16-.48-.32-.64H-.64-.8-.96L-1.12-.32V-.16 0"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M610.11 418.86C610.43 418.7 610.59 418.38 610.59 418.06"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M609.79 417.1C609.47 417.1 609.15 417.26 609.15 417.58"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 457.70251464843744, 309.9375)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0H-.16-.48-.64L-.8 .32V.48 .8 1.12L-.64 1.28-.48 1.44H-.16 0V1.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 457.3424682617187, 311.25750732421875)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0L-.16-.16H-.48L-.64-.48-.8-.8V-1.12-1.44L-.64-1.76-.48-1.92-.16-2.08H0"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M615.56 414.85H616.2"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M632.54 657.07H483.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M475.67 657.07H402.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M549.38 151.33V151.01"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M549.7 153.1H549.86"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M601.3 255.62 601.14 265.07"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M613.79 246.65C612.51 246.17 611.07 245.85 609.79 245.85"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M596.01 255.62 595.53 265.07"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M616.2 246.65C614.11 246.17 611.87 245.85 609.79 245.85"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M596.65 252.1C596.17 253.22 596.01 254.34 596.01 255.62"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 448.56750488281244, 187.26750183105466)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0 .64-.64 1.44-1.44 2.4-2.08 3.69-2.56 4.97-3.04 6.41-3.36 8.01-3.52 9.78-3.68 11.7-3.84"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M600.82 265.07V267.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M594.25 265.07V267.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M513.65 145.09H515.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M517.97 140.92V142.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M578.06 149.57V147.33"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M572.45 267.79V253.22"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M572.61 252.42V252.58"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M572.61 253.06V253.22"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M523.26 216.69H462.85"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M523.26 216.69H523.58"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M523.74 216.69H524.06"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M412.06 244.41V138.04"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M407.89 244.41V138.04"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M455 244.41V138.04"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M450.83 244.41V138.04"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M480.64 222.14V267.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M482.24 138.04V212.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M459.65 138.04V138.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M421.83 164.79V166.07"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M506.76 215.73H462.85"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M395.23 130.19H392.99"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M395.23 130.35H392.99"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M641.68 130.35H639.59"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M641.68 130.19H639.59"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M636.55 128.59H634.62"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M517.49 128.59H515.57"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M447.31 129.71H445.87"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M439.14 129.71H422.47"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M415.74 129.71H414.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M407.41 129.71H403.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M447.31 120.42H403.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M625.49 90.46H581.27"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M570.85 90.46H526.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M398.76 134.19H635.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M636.39 134.19V267.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M636.39 311.37V409.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M642 134.19V267.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M642 311.37V663.47"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M636.39 409.89V413.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M636.39 434.88V438.72"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M635.59 601.16V657.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M398.28 134.19V267.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M398.28 311.37V658.83"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M392.67 134.19V267.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M392.67 311.37V663.47"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M399.08 311.37V657.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 419.6024780273437, 181.38000488281247)"
      strokeWidth={0.481}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C-.16-.16-.32 0-.48 0-.48 .16-.48 .32-.48 .48-.32 .48-.16 .48 0 .48 0 .32 0 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M559.47 241.84 559.63 241.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 353.8724975585937, 141.1349945068359)"
      strokeWidth={0.481}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C-.17 0-.33 0-.33 .16-.33 .32-.33 .48-.17 .48 0 .64 .16 .48 .16 .48 .32 .32 .16 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M471.99 188.18V187.86"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 353.2649841308593, 201.57000732421872)"
      strokeWidth={0.481}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C-.16 0-.32 0-.32 .16-.32 .32-.32 .48-.16 .64 0 .64 .16 .48 .16 .32 .32 .32 .16 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M471.02 268.76 471.18 268.44"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={1.122}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M564.44 520.58 559.15 537.56"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 418.04251098632807, 402.69000244140614)"
      d="M0 0C-.32 1.12 .32 2.08 1.28 2.4 2.24 2.72 3.37 2.24 3.69 1.12 4.01 .16 3.37-.96 2.4-1.28 1.44-1.6 .32-.96 0 0Z"
      fill="#040503"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={1.122}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M474.71 174.56 469.42 191.54"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 350.74499511718744, 143.17498779296872)"
      d="M0 0C-.32 .96 .16 2.08 1.28 2.4 2.24 2.72 3.36 2.08 3.68 1.12 4 .16 3.36-.96 2.4-1.28 1.28-1.6 .32-.96 0 0Z"
      fill="#040503"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 354.2324829101562, 123.23249816894528)"
      d="M0 0V-1.12H-6.25V0H-3.69V6.41C-3.69 6.73-3.37 7.05-3.21 7.05-2.89 7.05-2.73 6.73-2.73 6.41V0H0Z"
      fill="#231f20"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 358.91247558593744, 125.99250793457028)"
      d="M0 0C0-2.08-3.36-2.24-4.64-2.4V-3.68H-.64C-.32-3.68 0-4 0-4.16 0-4.48-.32-4.8-.64-4.8H-5.6V-1.44C-4.16-1.44-1.12-1.28-1.12 0-1.12 1.29-4 2.09-5.6 2.25V3.21C-4 3.05 0 2.25 0 0Z"
      fill="#231f20"
    />
    <path
      d="M 346.412 211.235 L 346.412 210.515 L 341.724 210.515 L 341.724 211.235 L 343.644 211.235 L 343.644 216.163 C 343.644 216.403 343.884 216.523 344.004 216.523 C 344.244 216.523 344.364 216.403 344.364 216.163 L 344.364 211.235 L 346.412 211.235 Z"
      fill="#231f20"
      style={{ transformOrigin: "344.068px 213.519px" }}
      transform="matrix(1, 0, 0, 1, -8e-12, 0)"
    />
    <path
      d="M 351.091 214.603 C 351.091 213.875 350.731 212.795 349.891 212.795 L 348.211 212.795 C 348.691 212.075 349.291 211.475 349.771 211.115 C 349.891 211.115 350.011 210.995 350.011 210.875 C 350.011 210.635 349.891 210.395 349.651 210.395 C 349.531 210.395 349.531 210.515 349.411 210.515 C 348.571 211.115 347.371 212.195 346.891 213.995 C 346.891 214.243 346.891 214.483 346.891 214.603 C 346.891 215.323 347.131 216.523 347.971 216.523 L 349.891 216.523 C 350.731 216.523 351.091 215.323 351.091 214.603 Z M 350.371 214.603 C 350.371 215.083 350.131 215.443 349.891 215.683 L 347.971 215.683 C 347.731 215.443 347.611 215.083 347.611 214.603 C 347.611 214.363 347.731 213.875 347.851 213.515 L 349.891 213.515 C 350.131 213.755 350.371 214.123 350.371 214.603 Z"
      fill="#231f20"
      style={{ transformOrigin: "348.991px 213.459px" }}
      transform="matrix(1, 0, 0, 1, -9e-12, 0)"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 412.63500976562494, 164.0849914550781)"
      d="M0 0V-.96H-6.25V0H-3.69V6.57C-3.69 6.89-3.37 7.05-3.21 7.05-2.89 7.05-2.73 6.89-2.73 6.57V0H0Z"
      fill="#231f20"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 417.44250488281244, 167.56500244140622)"
      d="M0 0V-.96H-.96V-5.61H-1.92V-.96H-4.97L-3.2-4.96C-2.88-5.61-3.85-5.93-4.17-5.45L-6.09-.64V-.48C-6.09-.16-5.93 0-5.61 0H-1.92V1.92C-1.92 2.24-1.76 2.4-1.44 2.4-1.28 2.4-.96 2.24-.96 1.92V0H0Z"
      fill="#231f20"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 420.3300170898437, 288.07501220703125)"
      d="M0 0V-1.12H-6.25V0H-3.69V6.41C-3.69 6.73-3.37 7.05-3.21 7.05-2.89 7.05-2.73 6.73-2.73 6.41V0H0Z"
      fill="#231f20"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 424.7699890136718, 292.875)"
      d="M0 0C0-.32-.16-.48-.48-.48H-1.76V-7.04C-1.76-7.37-1.92-7.53-2.4-7.53L-4.8-6.88V-5.76L-2.72-6.4V-.48H-4C-4.16-.48-4.48-.32-4.48 0-4.48 .32-4.16 .48-4 .48H-.48C-.16 .48 0 .32 0 0Z"
      fill="#231f20"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 429.69750976562494, 292.875)"
      d="M0 0C0-.32-.16-.48-.48-.48H-1.76V-7.04C-1.76-7.37-2.08-7.53-2.4-7.53L-4.8-6.88V-5.76L-2.72-6.4V-.48H-4C-4.32-.48-4.48-.32-4.48 0-4.48 .32-4.32 .48-4 .48H-.48C-.16 .48 0 .32 0 0Z"
      fill="#231f20"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 420.3300170898437, 382.98748779296875)"
      d="M0 0V-1.12H-6.25V0H-3.69V6.41C-3.69 6.73-3.37 7.05-3.21 7.05-2.89 7.05-2.73 6.73-2.73 6.41V0H0Z"
      fill="#231f20"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 424.7699890136718, 387.79498291015625)"
      d="M0 0C0-.32-.16-.48-.48-.48H-1.76V-7.04C-1.76-7.37-1.92-7.53-2.4-7.53L-4.8-6.88V-5.76L-2.72-6.4V-.48H-4C-4.16-.48-4.48-.32-4.48 0-4.48 .32-4.16 .48-4 .48H-.48C-.16 .48 0 .32 0 0Z"
      fill="#231f20"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 429.93749999999994, 388.15496826171875)"
      d="M0 0V-.96H-4.65V-2.88C-4.49-3.04-3.53-3.36-3.36-3.36L-1.44-3.84C-.8-4 0-4.16 0-4.96V-6.09C0-7.05-1.12-8.01-2.88-8.01-4.01-8.01-4.97-7.85-5.45-7.53V-6.41C-4.97-6.57-4.17-7.05-2.88-7.05-1.76-7.05-1.28-6.41-1.12-6.09V-4.96L-2.24-4.64-4.33-4.16C-4.81-4-5.61-3.84-5.61-3.04V0H0Z"
      fill="#231f20"
    />
    <path
      strokeWidth={1.122}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M 351.381 195.217 L 347.413 207.952"
      style={{ transformOrigin: "349.397px 201.584px" }}
      transform="matrix(1, 0, 0, 1, -5e-12, 0)"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 350.50500488281244, 193.6349945068359)"
      d="M0 0C-.32 .96 .32 2.09 1.28 2.41 2.24 2.73 3.36 2.25 3.68 1.12 4 .16 3.36-.96 2.4-1.28 1.44-1.6 .32-.96 0 0Z"
      fill="#040503"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={1.122}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M550.82 229.51 545.69 246.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 407.94750976562494, 184.38751220703122)"
      d="M0 0C-.32 .96 .16 2.08 1.12 2.4 2.24 2.72 3.37 2.08 3.69 1.12 3.85 0 3.37-.96 2.4-1.28 1.28-1.6 .16-1.12 0 0Z"
      fill="#040503"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={1.122}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M564.12 395.47 558.83 412.61"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 417.80249023437494, 308.9775085449219)"
      d="M0 0C-.32 .96 .16 2.08 1.28 2.4 2.24 2.72 3.37 2.08 3.69 1.12 4.01 0 3.37-.96 2.4-1.28 1.44-1.6 .32-1.12 0 0Z"
      fill="#040503"
    />
    <rect
      x={347.841}
      y={104.631}
      width={41.112}
      height={15.115}
      style={{ fill: "rgb(216, 216, 216)", stroke: "rgb(0, 0, 0)" }}
      transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -5.684341886080802e-14, 0)"
    />
    <rect
      x={329.894}
      y={217.861}
      width={41.112}
      height={15.115}
      style={{ fill: "rgb(216, 216, 216)", stroke: "rgb(0, 0, 0)" }}
      transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -5.684341886080802e-14, 0)"
    />
    <rect
      x={395.758}
      y={146.291}
      width={41.112}
      height={15.115}
      style={{ fill: "rgb(216, 216, 216)", stroke: "rgb(0, 0, 0)" }}
      transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -5.684341886080802e-14, 0)"
    />
    <rect
      x={400.938}
      y={268.385}
      width={41.112}
      height={15.115}
      style={{ fill: "rgb(216, 216, 216)", stroke: "rgb(0, 0, 0)" }}
      transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -5.684341886080802e-14, 0)"
    />
    <rect
      x={400.938}
      y={361.832}
      width={41.112}
      height={15.115}
      style={{ fill: "rgb(216, 216, 216)", stroke: "rgb(0, 0, 0)" }}
      transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -5.684341886080802e-14, 0)"
    />
    <text
      style={{
        whiteSpace: "pre",
        fill: "rgb(51, 51, 51)",
        fontFamily: "Arial, sans-serif",
        fontSize: "15.3px",
      }}
      x={130.477}
      y={139.792}
      transform="matrix(0.7657240033149719, 0, 0, 0.838710069656372, 248.68728637695307, -0.44286200404168596)"
    >
      {T5 + " \xB0C"}
    </text>
    <text
      style={{
        whiteSpace: "pre",
        fill: "rgb(51, 51, 51)",
        fontFamily: "Arial, sans-serif",
        fontSize: "15.3px",
      }}
      x={130.477}
      y={139.792}
      transform="matrix(0.7657240033149719, 0, 0, 0.838710069656372, 297.2122497558593, 41.53942108154297)"
    >
      {T4 + " \xB0C"}
    </text>
    <text
      style={{
        whiteSpace: "pre",
        fill: "rgb(51, 51, 51)",
        fontFamily: "Arial, sans-serif",
        fontSize: "15.3px",
      }}
      x={130.477}
      y={139.792}
      transform="matrix(0.7657240033149719, 0, 0, 0.838710069656372, 230.69486999511713, 112.41859436035156)"
    >
      {T6 + " \xB0C"}
    </text>
    <text
      style={{
        whiteSpace: "pre",
        fill: "rgb(51, 51, 51)",
        fontFamily: "Arial, sans-serif",
        fontSize: "15.3px",
      }}
      x={130.477}
      y={139.792}
      transform="matrix(0.7657240033149719, 0, 0, 0.838710069656372, 302.11929321289057, 163.66969299316403)"
    >
      {T11 + " \xB0C"}
    </text>
    <text
      style={{
        whiteSpace: "pre",
        fill: "rgb(51, 51, 51)",
        fontFamily: "Arial, sans-serif",
        fontSize: "15.3px",
      }}
      x={130.477}
      y={139.792}
      transform="matrix(0.7657240033149719, 0, 0, 0.838710069656372, 302.6644897460937, 256.35784912109375)"
    >
      {T12 + " \xB0C"}
    </text>
  </svg>
);
export default NilanFront;
