import * as React from "react";
const NilanAbove = (props, T1, T2, T3) => (
  <svg
    viewBox="85.755 91.068 185.67 138.012"
    width={185.67}
    height={138.012}
    {...props}
  >
    <defs>
      <clipPath id="clip_41">
        <path
          transform="matrix(.75,0,0,.75,0,0)"
          d="M113.92 136H116V137.44H113.92Z"
          fillRule="evenodd"
        />
      </clipPath>
      <clipPath id="clip_42">
        <path
          transform="matrix(.75,0,0,.75,0,0)"
          d="M113.92 136H115.2V293.28H113.92Z"
          fillRule="evenodd"
        />
      </clipPath>
      <clipPath id="clip_43">
        <path
          transform="matrix(.75,0,0,.75,0,0)"
          d="M113.92 291.84H115.68V294.4H113.92Z"
          fillRule="evenodd"
        />
      </clipPath>
      <clipPath id="clip_44">
        <path
          transform="matrix(.75,0,0,.75,0,0)"
          d="M113.92 89.6V672.48H643.04V89.6Z"
          fillRule="evenodd"
        />
      </clipPath>
      <clipPath id="clip_45">
        <path
          transform="matrix(.75,0,0,.75,0,0)"
          d="M113.92 89.6V672.48H643.04V89.6Z"
          fillRule="evenodd"
        />
      </clipPath>
      <clipPath id="clip_46">
        <path
          transform="matrix(.75,0,0,.75,0,0)"
          d="M113.92 89.6V672.48H643.04V89.6Z"
          fillRule="evenodd"
        />
      </clipPath>
      <clipPath id="clip_47">
        <path
          transform="matrix(.75,0,0,.75,0,0)"
          d="M113.92 291.84H115.68V293.28H113.92Z"
          fillRule="evenodd"
        />
      </clipPath>
    </defs>
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M353.25 136.6C353.41 136.6 353.41 136.44 353.41 136.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.33 136.28C351.33 136.44 351.49 136.6 351.65 136.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M353.41 136.12C353.41 135.95 353.25 135.79 353.09 135.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.81 135.79C351.49 135.79 351.33 135.95 351.33 136.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M353.41 136.28V136.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.33 136.12V136.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M352.93 137.08V136.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.81 136.6V137.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M354.37 295.67V295.67 295.51"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M122.51 295.51V295.67"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M190.13 136.12C190.13 135.95 189.97 135.79 189.65 135.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M188.37 135.79C188.05 135.79 187.89 135.95 187.89 136.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M190.13 136.28V136.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M187.89 136.12V136.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M189.81 136.6C189.97 136.6 190.13 136.44 190.13 136.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M187.89 136.28C187.89 136.44 188.05 136.6 188.21 136.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M189.65 137.08V136.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M188.37 136.6V137.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M177.95 136.28V136.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M175.87 136.12V136.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M177.95 136.12C177.95 135.95 177.79 135.79 177.63 135.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M176.19 135.79C176.03 135.79 175.87 135.95 175.87 136.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M177.63 136.6C177.95 136.6 177.95 136.44 177.95 136.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M175.87 136.28C175.87 136.44 175.87 136.6 176.19 136.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M177.47 137.08V136.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M176.35 136.6V137.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M279.22 136.28V136.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M276.98 136.12V136.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M279.22 136.12C279.22 135.95 279.06 135.79 278.74 135.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M277.46 135.79C277.3 135.79 276.98 135.95 276.98 136.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M278.9 136.6C279.06 136.6 279.22 136.44 279.22 136.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M276.98 136.28C276.98 136.44 277.14 136.6 277.3 136.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M278.74 137.08V136.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M277.62 136.6V137.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M228.27 136.6C228.43 136.6 228.59 136.44 228.59 136.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M226.5 136.28C226.5 136.44 226.5 136.6 226.66 136.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M228.59 136.28V136.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M226.5 136.12V136.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M228.59 136.12C228.59 135.95 228.43 135.79 228.11 135.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M226.82 135.79C226.66 135.79 226.5 135.95 226.5 136.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M228.11 137.08V136.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M226.98 136.6V137.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M295.57 136.6C295.73 136.6 295.89 136.44 295.89 136.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M293.64 136.28C293.64 136.44 293.8 136.6 293.96 136.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M295.89 136.28V136.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M293.64 136.12V136.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M295.89 136.12C295.89 135.95 295.73 135.79 295.41 135.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M294.12 135.79C293.8 135.79 293.64 135.95 293.64 136.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M295.24 137.08V136.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M294.12 136.6V137.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M235.16 136.6C235.32 136.6 235.32 136.44 235.32 136.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M233.23 136.28C233.23 136.44 233.39 136.6 233.55 136.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M235.32 136.12C235.32 135.95 235.16 135.79 235 135.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M233.55 135.79C233.39 135.79 233.23 135.95 233.23 136.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M235.32 136.28V136.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M233.23 136.12V136.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M234.84 137.08V136.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M233.71 136.6V137.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M272.49 136.28V136.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M270.25 136.12V136.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M272.49 136.12C272.49 135.95 272.33 135.79 272.01 135.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M270.73 135.79C270.41 135.79 270.25 135.95 270.25 136.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M272.17 136.6C272.33 136.6 272.49 136.44 272.49 136.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M270.25 136.28C270.25 136.44 270.41 136.6 270.57 136.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M272.01 137.08V136.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M270.73 136.6V137.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M166.74 136.28V136.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M164.49 136.12V136.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M166.42 136.6C166.58 136.6 166.74 136.44 166.74 136.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M164.49 136.28C164.49 136.44 164.65 136.6 164.81 136.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M166.74 136.12C166.74 135.95 166.58 135.79 166.25 135.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M164.97 135.79C164.81 135.79 164.49 135.95 164.49 136.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M166.25 137.08V136.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M165.13 136.6V137.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M172.18 136.28V136.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M170.1 136.12V136.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M172.18 136.12C172.18 135.95 172.02 135.79 171.86 135.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M170.42 135.79C170.26 135.79 170.1 135.95 170.1 136.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M171.86 136.6C172.18 136.6 172.18 136.44 172.18 136.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M170.1 136.28C170.1 136.44 170.26 136.6 170.42 136.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M171.7 137.08V136.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M170.58 136.6V137.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M125.56 136.12C125.56 135.95 125.39 135.79 125.07 135.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M123.79 135.79C123.63 135.79 123.31 135.95 123.31 136.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M125.23 136.6C125.39 136.6 125.56 136.44 125.56 136.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M123.31 136.28C123.31 136.44 123.47 136.6 123.63 136.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M125.56 136.28V136.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M123.31 136.12V136.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M125.07 137.08V136.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M123.95 136.6V137.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M180.04 302.24V303.2"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M182.6 303.2V302.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M153.76 206.6H146.55"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M143.66 242.48H144.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M144.3 210.93H143.66"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M150.23 212.85H146.55"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M145.26 240.4H145.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M145.74 212.85H145.26"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M141.26 213.97 137.41 214.13"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M137.41 239.12 141.26 239.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M144.3 239.28H145.26"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M145.26 214.13H144.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M137.41 215.73H136.77"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M136.61 237.68H137.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M146.23 173.28H145.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M159.69 173.28H158.56"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M159.69 180.33V180.17"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M158.56 179.37H146.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M143.98 183.69H143.34"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M349.57 294.23C350.05 294.23 350.53 294.23 351.01 294.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M348.12 294.23C348.6 294.23 349.08 294.23 349.57 294.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.65 294.07V293.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M347.48 293.91C347.48 293.91 347.48 294.07 347.64 294.07"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 294.23H351.17"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M347.96 294.23H348.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M347.64 292.46H347.48"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.65 293.91V292.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M347.48 292.46V293.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 290.54V290.38"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M348.28 290.38C348.12 290.38 348.12 290.54 348.12 290.54"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M348.12 290.54V292.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 292.3V290.54"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M243.17 294.23C243.65 294.23 244.13 294.23 244.61 294.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 294.23C242.21 294.23 242.69 294.23 243.17 294.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M245.25 294.07V293.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.09 293.91V294.07"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.57 294.23V294.23H241.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M244.61 294.23V294.23H244.77"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.09 292.46V293.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M245.25 293.91V292.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 290.54V292.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M244.61 292.3V290.54"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M244.61 290.54V290.38"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 290.38V290.54"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M353.57 294.23C354.21 294.23 354.85 294.23 355.33 294.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.81 294.23C352.45 294.23 353.09 294.23 353.57 294.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M352.45 294.07H352.61"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M352.45 293.91 352.61 294.07"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M352.61 292.46H352.45"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M352.45 292.46V293.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M355.01 290.54V290.38"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M352.29 290.38C352.13 290.38 352.13 290.54 352.13 290.54"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M352.13 290.54V292.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M355.01 292.3V290.54"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M355.33 292.3C355.33 292.3 355.17 292.3 355.01 292.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M356.29 293.91V292.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M356.13 294.07C356.29 294.07 356.29 293.91 356.29 293.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M352.13 292.3C351.97 292.3 351.97 292.3 351.81 292.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M354.21 285.58V285.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M354.21 285.9V285.9 285.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M354.21 286.22V286.22 286.06"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M354.21 287.66V287.5"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M354.21 287.98V287.98 287.82"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M354.21 288.3V288.3 288.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M353.09 285.1V285.1H352.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M353.09 285.26V285.26H352.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M353.09 285.26V285.26L352.93 285.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M353.09 285.42V285.42H352.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M353.09 285.58V285.58H352.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M353.09 285.58V285.58L352.93 285.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M353.09 285.74V285.74H352.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M353.09 285.9V285.9H352.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M353.09 285.9V286.06H352.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M353.09 286.06V286.06H352.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M353.09 286.22V286.22H352.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M353.09 286.38V286.38H352.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M353.09 286.38V286.38H352.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M353.09 286.54V286.54H352.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M353.09 286.7V286.7H352.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M353.09 286.7V286.7H352.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M353.09 286.86V286.86H352.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M353.09 287.02V287.02H352.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M353.09 287.02V287.02H352.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M353.09 287.18V287.18H352.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M353.09 284.94V284.94H352.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M354.21 285.26V285.26 285.1"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M354.21 286.38V286.22"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M354.21 286.7V286.54"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M354.21 287.02V287.02 286.86"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M352.93 285.1V285.1H353.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M352.93 285.26V285.26H353.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M352.93 285.42V285.42H353.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M352.93 285.42V285.42H353.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M352.93 285.58V285.58H353.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M352.93 285.74V285.74H353.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M352.93 285.74V285.74H353.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M352.93 285.9V285.9H353.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M352.93 286.06V286.06H353.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M352.93 286.06V286.06H353.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M352.93 286.22V286.22H353.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M352.93 286.38V286.38H353.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M352.93 286.38V286.38L353.09 286.54"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M352.93 286.54V286.54H353.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M352.93 286.7V286.7H353.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M352.93 286.7V286.86H353.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M352.93 286.86V286.86H353.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M352.93 287.02V287.02H353.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M352.93 287.02V287.18H353.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M352.93 284.94V285.1H353.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M354.21 285.1V284.94"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M354.21 285.42V285.26"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M354.21 285.74V285.58"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M354.21 286.06V285.9"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M354.21 286.54V286.38"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M354.21 286.86V286.7"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M354.21 287.18V287.02"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M354.21 287.82V287.66"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M354.21 288.14V287.98"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M354.21 288.3V288.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M353.09 286.86V287.02"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M353.09 286.54V286.7"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M353.09 286.22V286.38"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M353.09 286.06V286.22"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M353.09 285.74V285.9"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M353.09 285.42V285.58"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M353.09 285.1V285.26"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M239.16 294.23C239.8 294.23 240.28 294.23 240.92 294.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M237.4 294.23C237.88 294.23 238.52 294.23 239.16 294.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.04 293.91V294.07"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.04 292.46V293.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M237.72 290.54V292.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M240.6 292.3V290.54"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M240.6 290.54C240.6 290.54 240.6 290.38 240.44 290.38"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M237.72 290.38V290.54"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M240.92 292.3C240.76 292.3 240.76 292.3 240.6 292.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M237.72 292.3C237.56 292.3 237.4 292.3 237.4 292.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M239.8 285.1V285.1H239.64"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M239.8 285.1V285.1H239.64"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M239.8 285.26V285.26H239.64"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M239.8 285.42V285.42H239.64"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M239.8 285.58V285.42H239.64"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M239.8 285.58V285.58H239.64"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M239.8 285.74V285.74H239.64"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M239.8 285.9V285.9L239.64 285.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M239.8 285.9V285.9H239.64"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M239.8 286.06V286.06H239.64"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M239.8 286.22V286.22L239.64 286.06"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M239.8 286.22V286.22H239.64"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M239.8 286.38V286.38H239.64"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M239.8 286.54V286.54H239.64"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M239.8 286.54V286.54H239.64"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M239.8 286.7V286.7H239.64"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M239.8 286.86V286.86H239.64"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M239.8 286.86V286.86H239.64"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M239.8 287.02V287.02H239.64"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M239.8 287.18V287.18H239.64"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.52 285.26V285.26 285.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.52 285.58V285.58 285.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.52 285.9V286.06"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.52 287.66V287.66 287.82"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.52 287.98V288.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M239.8 284.94V284.94H239.64"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M239.64 285.1H239.8"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M239.64 285.26H239.8V285.1"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M239.64 285.26H239.8"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M239.64 285.42H239.8"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M239.64 285.58H239.8"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M239.64 285.58H239.8"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M239.64 285.74H239.8"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M239.64 285.9H239.8"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M239.64 285.9H239.8"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M239.64 286.06H239.8"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M239.64 286.22H239.8"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M239.64 286.38 239.8 286.22"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M239.64 286.38H239.8"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M239.64 286.54H239.8"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M239.64 286.7 239.8 286.54"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M239.64 286.7H239.8"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M239.64 286.86H239.8"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M239.64 287.02H239.8V286.86"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M239.64 287.02H239.8"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M239.64 287.18H239.8"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.52 286.38V286.38 286.54"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.52 286.7V286.86"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.52 287.02V287.18"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.52 288.14V288.14 288.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.52 284.94V285.1"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M239.64 284.94H239.8"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M239.64 285.1V284.94"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M239.64 285.42V285.26"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M239.64 285.74V285.58"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M239.64 286.06V285.9"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M239.64 286.54V286.38"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M239.64 286.86V286.7"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M239.64 287.18V287.02"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.52 287.82V287.98"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.52 287.5V287.66"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.52 286.86V287.02"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.52 286.54V286.7"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.52 286.22V286.38"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.52 286.06V286.22"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.52 285.74V285.9"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.52 285.42V285.58"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.52 285.1V285.26"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M236.44 292.46V293.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 177.4500122070312, 219.3450012207031)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.18 168.79V164.31"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.98 168.79V164.31"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.5 170.08C359.82 169.76 359.98 169.28 359.98 168.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.34 170.08H359.5"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.02 169.44C359.18 169.28 359.18 169.12 359.18 168.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M115.94 269.56V271.16"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M115.94 274.36V276.13"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M115.94 285.42V287.18"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.14 170.56 359.02 169.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.02 171.52 359.82 171.2"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.82 171.2V172.48"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.82 260.27V266.19"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.02 266.99 359.82 266.19"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.02 170.4 359.82 171.2"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M195.9 136.6V137.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M195.9 136.6H196.7"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M196.7 136.6V137.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.94 137.08V136.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.98 136.6H360.94"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.98 137.08V136.6"
    />
    <g
      clipPath="url(#clip_41)"
      transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -5.684341886080802e-14, 0)"
    >
      <path
        transform="matrix(.75,0,0,.75,0,0)"
        strokeWidth={0.801}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="#040503"
        d="M114.34 136.6H115.14"
      />
    </g>
    <g
      clipPath="url(#clip_42)"
      transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -5.684341886080802e-14, 0)"
    >
      <path
        transform="matrix(.75,0,0,.75,0,0)"
        strokeWidth={0.801}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="#040503"
        d="M114.34 136.6V292.46"
      />
    </g>
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M115.14 136.6V137.4"
    />
    <g
      clipPath="url(#clip_43)"
      transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -5.684341886080802e-14, 0)"
    >
      <path
        transform="matrix(.75,0,0,.75,0,0)"
        strokeWidth={0.801}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="#040503"
        d="M114.34 292.46C114.34 292.95 114.5 293.43 114.82 293.59"
      />
    </g>
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.18 136.6H359.98"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M115.14 136.6H115.94"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M206.79 136.6V137.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M196.7 136.6H206.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M115.94 136.6H123.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M125.23 136.6H125.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M125.39 137.08V136.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M349.89 136.6V137.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M349.89 136.6H351.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M353.25 136.6H359.18"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M115.94 269.4V271.16"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M115.94 274.36V276.13"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M115.94 285.42V287.18"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M163.85 272.44C164.49 271.16 163.85 269.56 162.57 268.92 162.57 268.92 162.41 268.76 162.25 268.76"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M135.81 269.4 135.49 269.08 135.17 268.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M134.85 268.76C133.57 268.44 131.96 269.08 131.64 270.52 131.48 270.84 131.48 271.32 131.48 271.8"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M132.12 277.73H134.53"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M132.12 278.05H134.53"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M132.12 278.53H134.53"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M133.57 279.33H133.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M353.25 136.6H351.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M353.09 135.79H351.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M189.65 135.79H188.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M189.81 136.6H188.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M177.63 135.79H176.19"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M177.63 136.6H176.19"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M278.74 135.79H277.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M278.9 136.6H277.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M228.27 136.6H226.66"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M228.11 135.79H226.82"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M295.57 136.6H293.96"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M295.41 135.79H294.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M235.16 136.6H233.55"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M235 135.79H233.55"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M272.01 135.79H270.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M272.17 136.6H270.57"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M166.42 136.6H164.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M166.25 135.79H164.97"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M171.86 135.79H170.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M171.86 136.6H170.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M125.07 135.79H123.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M125.23 136.6H123.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 86.59500122070307, 226.67999267578122)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0H-.16V-.16H-.32V-.32-.48"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M361.74 301.76H115.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 271.3049926757812, 226.32000732421872)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V.16H-.16V.32 .48H-.32"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M361.42 302.24H115.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M361.74 295.67V301.76"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M124.27 290.86C123.47 290.86 122.83 291.34 122.83 292.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M352.13 291.18C351.81 291.34 351.65 291.66 351.81 291.98 351.81 292.14 351.97 292.14 351.97 292.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M115.14 295.67H122.83"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M122.83 295.67V292.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 92.84249877929682, 218.86500549316403)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.48 .32-.64 .64-.64 .96-.64 1.28-.48 1.28 0 1.28 .32 .96 .48 .64 .48 .32 .48 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M115.14 301.76V295.67"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M124.27 290.86H237.72"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M240.6 290.86H241.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M244.61 290.86H348.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 290.86H352.13"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M354.05 294.23V295.67"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M354.05 295.67H361.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M361.9 301.76H361.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M361.74 301.76V295.99"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M361.74 295.99H361.9"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M361.9 301.76V295.99"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M361.74 301.92V301.76"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M361.42 302.24C361.74 302.24 361.9 302.08 361.9 301.76"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M355.17 295.51V295.67"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M355.17 295.51H361.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M361.42 295.67V295.51"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M361.74 295.99V295.83"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M361.9 295.99C361.9 295.67 361.74 295.51 361.42 295.51"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M115.14 301.76V301.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M114.82 301.76H115.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M114.82 301.76C114.82 302.08 115.14 302.24 115.46 302.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M115.46 295.51H121.71"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M121.71 295.51V295.67"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M115.46 295.67V295.51"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M115.14 295.99H114.82"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M115.46 295.51C115.14 295.51 114.82 295.67 114.82 295.99"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M115.14 295.83V295.99"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M114.82 295.99V301.76"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M115.14 295.99V301.76"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M329.05 302.4H357.9"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M357.58 302.56C357.74 302.56 357.9 302.4 357.9 302.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M357.58 302.56H329.22"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M329.05 302.4C329.05 302.4 329.05 302.56 329.22 302.56"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M357.9 302.24V302.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M329.05 302.24V302.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M334.18 302.88H327.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M327.29 302.56H334.98"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M334.82 302.56 334.18 302.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M334.82 302.56H334.98"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M327.29 302.56 327.93 302.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M327.61 302.24H327.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M329.05 302.24H327.61"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M334.5 302.24H334.98"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M327.29 302.4H329.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M327.29 302.24V302.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M327.29 302.4V302.56"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M149.11 302.88H142.7"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M142.06 302.56H149.75"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M149.75 302.56 149.11 302.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M142.06 302.56 142.7 302.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M142.54 302.24H142.06"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M149.27 302.24H142.54"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M149.27 302.24H149.75"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M142.06 302.4H149.75"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M142.06 302.24V302.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M149.75 302.56V302.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M142.06 302.4V302.56"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 98.00999450683588, 208.6575012207031)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-1.44 1.12-2.57 2.57-2.57 4.01-2.57 5.13-1.44 5.13 0 5.13 1.28 4.01 2.4 2.57 2.4 1.12 2.4 0 1.28 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 99.08999633789057, 208.6575012207031)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.8 .49-1.28 1.13-1.28 1.93-1.28 2.41-.8 2.41 0 2.41 .64 1.93 1.12 1.13 1.12 .49 1.12 0 .64 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M130.68 279.49 131.96 280.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M130.68 278.05V279.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M133.41 275.16 132.12 275.8"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M134.69 275.96 133.41 275.16"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M130.84 276.61 130.68 278.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M132.12 275.8 130.84 276.61"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M135.81 278.21 135.97 276.77"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M135.97 276.77 134.69 275.96"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M131.96 280.29 133.09 281.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M133.09 281.09 134.37 280.45"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M135.81 279.81V278.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M134.37 280.45 135.81 279.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M181 305.44H181.64"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M181 305.44V303.2"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M181 303.2H181.64"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M181.64 305.44V303.2"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M181.64 305.44H183.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M183.72 303.2 183.08 305.44"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M181.64 303.2H183.72"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M179.55 305.44H181"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M178.91 303.2 179.55 305.44"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M178.91 303.2H181"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M178.91 305.44H179.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M178.91 305.44 178.27 303.2"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M179.39 305.44 178.75 303.2"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M178.27 303.2H178.75"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M179.55 305.44H179.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M178.75 303.2H178.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M183.24 305.44 183.88 303.2"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M183.08 305.44H183.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M183.88 303.2H183.72"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M183.24 305.44H183.72"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M183.72 305.44 184.36 303.2"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M183.88 303.2H184.36"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M178.11 305.44H178.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M176.99 303.2 178.11 305.44"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M176.99 303.2H178.27"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M183.72 305.44H184.68"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M185.8 303.2 184.68 305.44"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M184.36 303.2H185.8"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M184.84 305.44 185.96 303.2"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M184.68 305.44H184.84"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M185.96 303.2H185.8"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M177.47 305.44 176.35 303.2"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M177.79 305.44 176.67 303.2"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M177.47 305.44H177.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M176.35 303.2H176.67"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M185.16 305.44 186.28 303.2"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M184.84 305.44H185.16"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M185.96 303.2H186.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M178.11 305.44H177.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M176.67 303.2H176.99"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M176.03 303.2 176.19 303.52 177.31 305.44"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M177.31 305.44H177.47"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M176.03 303.2H176.35"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M186.6 303.2 186.44 303.52 185.32 305.44"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M185.16 305.44H185.32"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M186.28 303.2H186.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M176.99 305.44 175.71 303.2"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M177.31 305.44H176.99"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M175.71 303.2H176.03"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M185.64 305.44 186.93 303.2"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M185.32 305.44H185.64"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M186.93 303.2H186.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.46 292.14C360.62 292.14 360.62 292.14 360.62 291.98"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.62 291.98 360.46 291.82"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.46 291.66V291.82"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.46 291.66 360.62 291.5"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.62 291.5V291.5C360.62 291.34 360.62 291.34 360.46 291.34"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.46 291.34V291.5"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.14 291.34C360.14 291.34 360.3 291.5 360.46 291.5"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.14 291.34V291.18"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.14 291.18V291.18H359.98"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.98 291.18V291.34"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.82 291.5C359.82 291.5 359.98 291.5 359.98 291.34"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.82 291.5 359.66 291.34"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.66 291.34 359.5 291.5H359.66"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.66 291.5V291.66"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.66 291.82C359.66 291.82 359.82 291.66 359.66 291.66"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.66 291.82V291.98"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.66 291.98C359.5 291.98 359.5 291.98 359.66 292.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.66 292.14 359.82 291.98"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.98 292.14 359.82 291.98"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.98 292.14V292.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.98 292.3H360.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.14 292.3V292.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.46 291.98C360.3 291.98 360.3 292.14 360.14 292.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.46 291.98V292.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 269.2649841308593, 218.86500549316403)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.64 .48-1.12 1.12-1.12 1.6-1.12 2.24-.64 2.24 0 2.24 .48 1.6 .97 1.12 .97 .48 .97 0 .48 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 269.1449890136718, 218.86500549316403)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.8 .48-1.44 1.28-1.44 1.92-1.44 2.56-.8 2.56 0 2.56 .64 1.92 1.29 1.28 1.29 .48 1.29 0 .64 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M117.22 292.14V291.98C117.38 291.98 117.22 291.98 117.22 291.98"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M117.22 291.98V291.82"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M117.22 291.66C117.06 291.66 117.06 291.82 117.22 291.82"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M117.22 291.66V291.5"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M117.22 291.5C117.38 291.5 117.38 291.5 117.22 291.5V291.34"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M117.22 291.34 117.06 291.5"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.9 291.34 117.06 291.5"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.9 291.34V291.18"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.9 291.18H116.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.74 291.18V291.34"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.42 291.5C116.58 291.5 116.58 291.5 116.74 291.34"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.42 291.5V291.34"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.42 291.34C116.26 291.34 116.26 291.5 116.26 291.5"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.26 291.5 116.42 291.66"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.42 291.82V291.66"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.42 291.82 116.26 291.98"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.26 291.98V292.14H116.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.42 292.14V291.98"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.74 292.14C116.58 292.14 116.58 291.98 116.42 291.98"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.74 292.14V292.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.74 292.3V292.3H116.9"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.9 292.3V292.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M117.06 291.98 116.9 292.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M117.06 291.98 117.22 292.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 86.7150039672851, 218.86500549316403)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.64 .48-1.12 1.12-1.12 1.76-1.12 2.24-.64 2.24 0 2.24 .48 1.76 .97 1.12 .97 .48 .97 0 .48 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 86.59500122070307, 218.86500549316403)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.8 .64-1.44 1.28-1.44 2.08-1.44 2.56-.8 2.56 0 2.56 .64 2.08 1.29 1.28 1.29 .64 1.29 0 .64 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M166.25 267.79H162.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M143.18 267.79H139.98"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M165.45 267.47H163.69"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M142.38 267.47H140.62"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M162.09 270.36V269.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M143.82 268.6H143.34"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M143.34 271.8V268.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M142.38 271.48H140.78"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M140.78 271.48V267.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M142.38 271.48V267.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M166.74 271.16V268.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M166.74 271.16H164.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M164.49 271.16V267.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M140.78 270.36H140.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M140.46 270.36V267.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M166.74 271.16V268.44"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M164.49 271.16V267.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M166.74 270.36V268.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M160.17 153.9H159.85"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M159.85 147.33V153.9"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M160.17 147.33H159.85"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M160.17 147.33V153.9"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M159.85 147.81V147.33"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M159.85 153.9V153.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M160.49 146.85H159.69"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M159.69 146.85V147.33"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M159.69 147.33H159.85"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M159.85 147.81H159.69"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M159.69 147.81V153.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M159.69 153.42H159.85"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M159.85 153.9H159.69"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M159.69 153.9V154.38"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M159.69 154.38H160.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M157.44 144.61V173.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M151.99 173.28V142.68"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 115.4400024414062, 110.25749206542969)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .32-.64 .64-.64 .96-.64 1.28-.32 1.28 0 1.28 .32 .96 .64 .64 .64 .32 .64 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 124.08749389648432, 122.39250183105466)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .16-.48 .48-.48 .64-.48 .96-.32 .96 0 .96 .16 .64 .48 .48 .48 .16 .48 0 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M163.05 175.84V177.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M162.73 175.84H163.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M162.73 177.61V175.84"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M162.73 175.2V177.61"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M163.21 175.2H162.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M162.89 177.45V175.84"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M172.82 272.12 172.5 271.64"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M172.5 271.64 172.34 271.8"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M172.34 271.32 172.5 271.64"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M172.34 271.16V271.32"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M172.5 271.16 173.31 272.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M172.34 271.16V268.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M172.5 267.96V271.16"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M134.85 271.64 134.53 272.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M135.01 271.8 134.85 271.64"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M134.85 271.64 135.17 271.32"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M135.17 271.32V271.16"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M134.21 272.44 134.85 271.16"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M135.17 271.16V268.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M134.85 271.16V267.79"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 115.5599975585937, 110.25749206542969)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .32-.48 .48-.48 .8-.48 .96-.32 .96 0 .96 .16 .8 .48 .48 .48 .32 .48 0 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M159.04 146.53H159.36"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M159.04 146.53 159.36 145.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M159.36 146.53 159.53 145.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M143.34 183.69V142.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M141.1 143.16V183.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M162.57 177.77V148.61"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M143.34 145.73H143.66"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M143.34 145.73V142.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M143.66 145.73V142.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M160.49 146.53V179.69"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M143.66 205.96V220.38"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M143.66 232.87V247.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M141.26 206.6V246.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M145.74 247.61V205.8"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M146.55 244.41V226.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M158.72 244.41H146.55"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M158.72 244.41V226.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M158.72 226.63H146.55"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M146.55 247.61V245.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M157.6 245.05H146.55"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M146.55 225.66V208.2"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M158.72 225.66H146.55"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M158.72 225.66V209"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M157.6 208.2H146.55"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M146.55 207.56V205.64"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M156.32 207.56H146.55"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M159.85 209.8V243.61"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M158.72 215.09C154.72 214.45 150.71 214.13 146.55 213.97"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M158.72 227.91 157.6 227.43 155.52 226.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M158.72 209C154.72 209.8 150.55 210.61 146.55 211.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M154.4 209.96C151.83 210.45 149.11 211.09 146.55 211.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M144.3 210.93V242.48"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M143.66 220.38V232.87"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M150.23 214.13V212.85"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M145.26 212.85V240.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M137.41 214.13V239.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M136.77 236.88H136.61"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M136.77 215.89H136.61"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M136.61 237.68V236.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M136.77 236.88V215.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M136.77 220.06H136.61"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M136.61 220.06V236.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M136.77 218.13H136.61"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M136.61 218.13V220.06"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M154.4 214.45 157.6 213.17 158.72 212.85"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M136.61 215.89V218.13"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M158.72 213.33 157.6 213.81 155.52 214.61"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M159.69 173.28V180.33"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M145.42 184.01V173.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M146.23 184.17V180.97"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M158.56 180.97H146.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M158.56 173.28H146.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M146.23 180.33V173.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M158.56 180.33H146.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M158.56 180.33V173.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M156.16 182.25C153.28 182.73 150.55 183.37 147.67 184.17"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M154.08 182.57C152.15 183.05 150.07 183.53 148.15 184.17"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M143.98 183.69V183.85"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.98 168.79V170.56"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M117.38 277.89H117.22"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M117.38 277.89H117.22"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M117.38 277.89V278.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M117.38 278.21V277.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.9 277.41H116.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.74 277.41H116.9"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M117.22 278.21C117.06 278.21 116.9 278.37 116.9 278.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M117.22 278.21C117.06 278.21 116.9 278.37 116.9 278.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.74 277.41C116.58 277.41 116.58 277.57 116.58 277.57"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.74 277.41C116.58 277.41 116.58 277.41 116.58 277.57"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.9 278.53H116.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.74 278.53H116.9"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.9 278.37V278.53"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.9 278.53V278.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.58 278.37 116.42 278.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.58 278.37C116.58 278.37 116.58 278.21 116.42 278.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.42 278.21H116.26"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.26 278.21H116.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M117.22 278.21H117.38"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M117.22 278.21H117.38"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.9 277.57C116.9 277.73 117.06 277.89 117.22 277.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.9 277.57C116.9 277.73 117.06 277.89 117.22 277.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.26 277.89V278.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.26 278.21V277.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.42 277.89C116.58 277.89 116.58 277.73 116.58 277.57"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.42 277.89C116.42 277.89 116.58 277.73 116.58 277.57"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 86.7150039672851, 208.53749084472653)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.64 .48-1.12 1.12-1.12 1.76-1.12 2.24-.64 2.24 0 2.24 .64 1.76 1.12 1.12 1.12 .48 1.12 0 .64 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 87.07499694824213, 208.53749084472653)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .32-.64 .64-.64 .96-.64 1.28-.32 1.28 0 1.28 .32 .96 .64 .64 .64 .32 .64 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.9 277.57V277.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.26 277.89H116.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.58 278.53V278.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.58 278.53H116.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.42 277.89H116.26"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.9 277.57V277.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.58 278.53H116.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.58 278.37V278.53"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M117.38 278.21H117.22"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M117.22 278.21V277.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M117.38 277.89H117.22"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.9 277.57C116.9 277.73 117.06 277.89 117.22 277.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.74 277.57H116.58"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.74 277.57V277.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.74 278.53H116.9"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.74 277.57H116.58"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.42 277.89C116.58 277.89 116.74 277.73 116.74 277.57"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.58 278.53H116.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.74 278.37C116.74 278.21 116.58 278.21 116.42 278.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.58 278.37H116.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.9 277.57H116.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.9 277.57V277.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M117.22 278.21C117.06 278.21 116.9 278.21 116.9 278.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.74 278.37V278.53"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.42 277.89H116.26"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.9 278.53V278.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.26 277.89V278.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.26 278.21H116.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 86.7150039672851, 162.1575012207031)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.48 .48-.96 1.12-.96 1.76-.96 2.24-.48 2.24 0 2.24 .64 1.76 1.12 1.12 1.12 .48 1.12 0 .64 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 87.07499694824213, 162.1575012207031)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .32-.64 .64-.64 .96-.64 1.28-.32 1.28 0 1.28 .48 .96 .8 .64 .8 .32 .8 0 .48 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.9 216.85H116.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.9 216.69V216.85"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M117.22 216.37C117.06 216.37 116.9 216.53 116.9 216.69"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M117.22 216.37H117.38"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M117.38 216.21V216.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M117.38 216.21C117.38 216.21 117.38 216.05 117.22 216.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.9 215.89C116.9 216.05 117.06 216.05 117.22 216.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.9 215.73V215.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.74 215.73H116.9"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.74 215.73H116.58"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.58 215.89V215.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.42 216.05C116.42 216.05 116.58 216.05 116.58 215.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.26 216.05H116.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.26 216.05V216.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.26 216.37V216.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.42 216.37H116.26"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.58 216.69C116.58 216.53 116.42 216.37 116.42 216.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.58 216.85V216.69"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.58 216.85H116.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.26 216.37H116.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M117.38 216.21H117.22"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M117.22 216.21V216.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M117.38 216.37V216.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.9 216.85V216.69"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.9 215.73H116.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.9 215.89V215.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.26 216.21V216.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M117.22 216.21V216.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M117.22 216.37H117.38"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.9 215.89C116.9 216.05 117.06 216.21 117.22 216.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.58 216.69C116.58 216.53 116.58 216.37 116.42 216.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.26 216.21V216.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.58 216.85H116.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.74 215.73H116.58"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.74 216.85H116.9"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M117.22 216.37C117.06 216.37 116.9 216.53 116.9 216.69"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.58 215.73V215.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.42 216.21C116.58 216.21 116.58 216.05 116.58 215.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.42 216.21V216.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.42 216.21H116.26"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.58 216.69V216.85"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M117.38 216.21H117.22"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.74 215.73H116.58"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.74 216.85H116.9"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M117.38 216.37H117.22"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M117.22 216.37V216.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.58 216.85H116.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.9 215.89C116.9 216.05 117.06 216.21 117.22 216.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.9 216.85V216.69"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.26 216.21V216.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M117.22 216.37C117.06 216.37 116.9 216.53 116.9 216.69"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.74 215.73V215.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.74 215.89H116.58"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.42 216.21C116.58 216.21 116.74 216.05 116.74 215.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.26 216.37H116.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.9 215.73H116.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.74 216.69C116.74 216.53 116.58 216.37 116.42 216.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.58 216.69H116.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.42 216.21H116.26"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.74 216.69V216.85"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.9 215.89V215.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.98 141.24C359.98 141.08 359.82 140.92 359.66 140.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.98 141.24C359.98 141.08 359.82 140.92 359.66 140.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.5 140.6V140.76"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.5 140.6V140.76"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.66 140.6H359.5"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.5 140.6H359.66"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 269.2649841308593, 105.56999206542969)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.64 .48-1.12 1.12-1.12 1.6-1.12 2.24-.64 2.24 0 2.24 .64 1.6 1.12 1.12 1.12 .48 1.12 0 .64 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 269.62499999999994, 105.56999206542969)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .16-.64 .64-.64 .96-.64 1.28-.32 1.28 0 1.28 .48 .96 .64 .64 .64 .16 .64 0 .48 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.14 141.4H359.98"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.14 141.4C360.3 141.4 360.3 141.4 360.3 141.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.46 140.92C360.3 140.92 360.3 141.08 360.3 141.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.62 140.92H360.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.62 140.76V140.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.62 140.76V140.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.46 140.6H360.62"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.3 140.44C360.3 140.6 360.3 140.6 360.46 140.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.3 140.28V140.44"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.3 140.28H360.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.98 140.28H360.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.98 140.44V140.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.66 140.6C359.82 140.6 359.98 140.6 359.98 140.44"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.5 140.92V140.76"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.66 140.92H359.5"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.98 141.24V141.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.5 140.76V140.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.46 140.92H360.62"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.62 140.76V140.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.62 140.92V140.76"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.5 140.92H359.66"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.3 140.44V140.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.46 140.92C360.3 140.92 360.3 141.08 360.3 141.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.3 140.28H360.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.66 140.6C359.82 140.6 359.98 140.6 359.98 140.44"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.3 140.44C360.3 140.6 360.3 140.6 360.46 140.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.98 140.28V140.44"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.98 141.24V141.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.14 141.4C360.14 141.4 360.3 141.4 360.3 141.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.98 141.4H360.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.62 140.6H360.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.14 140.28H359.98"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.5 140.76V140.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.3 141.24H360.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.46 140.92C360.3 140.92 360.14 141.08 360.14 141.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.5 140.92H359.66"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.98 141.4V141.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.98 141.24H360.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.14 141.4V141.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.66 140.76C359.82 140.76 359.98 140.6 359.98 140.44"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.66 140.76V140.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.98 141.24C359.98 141.08 359.82 140.92 359.66 140.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.62 140.92V140.76"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.14 140.44V140.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.14 140.28H360.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.14 140.44H360.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.46 140.92H360.62"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.5 140.76V140.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.98 140.28V140.44"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.62 140.76H360.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.46 140.76V140.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.62 140.76V140.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.14 140.44C360.14 140.6 360.3 140.76 360.46 140.76"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.66 140.76H359.5"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.3 141.24H360.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.14 140.28H359.98"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.62 278.21H360.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.46 278.21H360.62"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 269.62499999999994, 208.53749084472653)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .16-.64 .64-.64 .96-.64 1.28-.32 1.28 0 1.28 .32 .96 .64 .64 .64 .16 .64 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.14 278.53H359.98"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.14 278.53H360.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.3 278.37V278.53"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.46 278.21C360.3 278.21 360.3 278.37 360.3 278.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.62 277.89V278.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.46 277.89H360.62"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.3 277.57C360.3 277.73 360.3 277.89 360.46 277.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.3 277.57C360.3 277.41 360.3 277.41 360.14 277.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.98 277.41H360.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.98 277.41V277.57"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.66 277.89C359.82 277.89 359.98 277.73 359.98 277.57"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.5 277.89H359.66"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.5 278.21V277.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.66 278.21H359.5"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.98 278.37C359.98 278.37 359.82 278.21 359.66 278.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.98 278.53V278.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.5 278.21H359.66"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.5 277.89V278.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.98 278.53H360.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.98 278.37V278.53"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 269.2649841308593, 208.53749084472653)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.64 .48-1.12 1.12-1.12 1.6-1.12 2.24-.64 2.24 0 2.24 .64 1.6 1.12 1.12 1.12 .48 1.12 0 .64 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.98 278.37C359.98 278.37 359.82 278.21 359.66 278.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.66 277.89H359.5"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.62 277.89H360.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.3 277.57C360.3 277.73 360.3 277.89 360.46 277.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.3 277.57 360.14 277.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.62 278.21V277.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.14 277.41H359.98"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.66 277.89C359.82 277.89 359.98 277.73 359.98 277.57"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.14 278.53H360.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.98 277.41V277.57"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.46 278.21C360.3 278.21 360.3 278.37 360.3 278.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.3 278.53V278.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.62 277.89H360.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.98 278.37C359.98 278.21 359.82 278.21 359.66 278.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.14 277.57H360.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.14 277.57H360.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.5 277.89V278.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.46 278.21H360.62"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.62 278.21V277.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.14 277.57C360.14 277.73 360.3 277.89 360.46 277.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.66 277.89C359.82 277.89 359.98 277.73 359.98 277.57"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.3 278.53H360.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.14 278.53V278.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.3 278.37H360.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.14 277.57H359.98"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.98 277.57V277.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.14 277.57V277.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.66 277.89H359.5"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.98 278.37V278.53"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.5 278.21H359.66"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.46 278.21C360.3 278.21 360.14 278.21 360.14 278.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.98 278.53H360.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 86.7150039672851, 105.56999206542969)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.64 .48-1.12 1.12-1.12 1.76-1.12 2.24-.64 2.24 0 2.24 .64 1.76 1.12 1.12 1.12 .48 1.12 0 .64 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 87.07499694824213, 105.56999206542969)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .32-.64 .64-.64 .96-.64 1.28-.32 1.28 0 1.28 .48 .96 .64 .64 .64 .32 .64 0 .48 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.26 140.6V140.76"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.26 140.6V140.76"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.26 140.76V140.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.26 140.92V140.76"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.58 140.28V140.44"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.58 140.44V140.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.9 141.4H116.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.9 141.4V141.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M117.22 140.92C117.06 140.92 116.9 141.08 116.9 141.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M117.22 140.92H117.38"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M117.38 140.76V140.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M117.38 140.76C117.38 140.6 117.38 140.6 117.22 140.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.9 140.44C116.9 140.6 117.06 140.6 117.22 140.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.9 140.28V140.44"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.74 140.28H116.9"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.74 140.28H116.58"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.42 140.6C116.42 140.6 116.58 140.6 116.58 140.44"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.26 140.6H116.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.42 140.92H116.26"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.58 141.24C116.58 141.08 116.42 140.92 116.42 140.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.58 141.24C116.58 141.4 116.58 141.4 116.74 141.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.74 140.28H116.58"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.42 140.6H116.26"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M117.38 140.92V140.76"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.58 141.24C116.58 141.08 116.58 140.92 116.42 140.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.26 140.92H116.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.42 140.6C116.58 140.6 116.58 140.6 116.58 140.44"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.74 141.4H116.9"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.58 141.24C116.58 141.4 116.58 141.4 116.74 141.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M117.38 140.76C117.38 140.6 117.38 140.6 117.22 140.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.9 140.28H116.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.9 141.4V141.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M117.22 140.92H117.38"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.9 140.44V140.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.9 140.44C116.9 140.6 117.06 140.6 117.22 140.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M117.22 140.92C117.06 140.92 116.9 141.08 116.9 141.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.26 140.76V140.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.74 141.4V141.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.74 141.24H116.9"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.9 141.4V141.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.26 140.92H116.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.58 141.24H116.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.58 141.24H116.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M117.38 140.92H117.22"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M117.22 140.76V140.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M117.38 140.76H117.22"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M117.22 140.92V140.76"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.74 141.24C116.74 141.08 116.58 140.92 116.42 140.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.42 140.76H116.26"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.42 140.76V140.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.9 140.28H116.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.42 140.76C116.58 140.76 116.74 140.6 116.74 140.44"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.74 140.44H116.58"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M117.22 140.76V140.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.26 140.76V140.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.74 140.28V140.44"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.74 140.28H116.58"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M117.22 140.92C117.06 140.92 116.9 141.08 116.9 141.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.9 140.44C116.9 140.6 117.06 140.76 117.22 140.76"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.9 140.44V140.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.98 216.85V216.69"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.98 216.69V216.85"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.14 216.85H360.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.14 216.85H360.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.3 215.73H360.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.3 215.73H360.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 269.2649841308593, 162.1575012207031)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.48 .48-.96 1.12-.96 1.6-.96 2.24-.48 2.24 0 2.24 .64 1.6 1.12 1.12 1.12 .48 1.12 0 .64 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 269.62499999999994, 162.1575012207031)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .16-.64 .64-.64 .96-.64 1.28-.32 1.28 0 1.28 .48 .96 .8 .64 .8 .16 .8 0 .48 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.14 216.85H359.98"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.98 216.85H360.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.98 215.73V215.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.98 215.89V215.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.66 216.21C359.82 216.21 359.98 216.05 359.98 215.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.66 216.21V216.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.66 216.05C359.82 216.05 359.98 216.05 359.98 215.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.3 216.69V216.85"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.46 216.37C360.3 216.37 360.3 216.53 360.3 216.69"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.62 216.37H360.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.62 216.21V216.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.62 216.21V216.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.46 216.05H360.62"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.3 215.89C360.3 216.05 360.3 216.05 360.46 216.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.3 215.73V215.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.98 215.73H360.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.5 216.05H359.66"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.5 216.05V216.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.5 216.37V216.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.66 216.37H359.5"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.98 216.69C359.98 216.53 359.82 216.37 359.66 216.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.46 216.37C360.3 216.37 360.3 216.53 360.3 216.69"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.66 216.21H359.5"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.5 216.21V216.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.98 216.69C359.98 216.53 359.82 216.37 359.66 216.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.62 216.21H360.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.46 216.21V216.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.62 216.21V216.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.3 216.85V216.69"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.14 215.73H359.98"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.3 215.89V215.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.5 216.37H359.66"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.5 216.21V216.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.3 215.89C360.3 216.05 360.3 216.21 360.46 216.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.62 216.37V216.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.46 216.37H360.62"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.3 216.85H360.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.14 216.85V216.69"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.3 216.69H360.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.98 215.73V215.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.98 216.85H360.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.14 215.73H359.98"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.98 216.69C359.98 216.53 359.82 216.37 359.66 216.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.98 216.69V216.85"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.66 216.21H359.5"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.5 216.37H359.66"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.5 216.21V216.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.14 215.89V215.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.14 215.73H360.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.14 215.89H360.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.62 216.21H360.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.14 215.89C360.14 216.05 360.3 216.21 360.46 216.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.46 216.37H360.62"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.66 216.21C359.82 216.21 359.98 216.05 359.98 215.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.62 216.37V216.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.46 216.37C360.3 216.37 360.14 216.53 360.14 216.69"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M356.13 137.56V137.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M355.97 138.04 356.13 137.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M355.97 138.04 356.13 137.56"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M236.28 138.2V137.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M236.92 138.04 236.28 137.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M356.46 137.88 355.97 138.04"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M356.46 137.88V138.2"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M236.6 137.56 236.92 138.04"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M236.6 137.88 236.92 138.04"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M236.6 137.56V137.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M236.92 138.04H355.97"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M356.13 137.88 355.97 138.04"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M356.46 137.88H356.13"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M236.28 137.88H236.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M236.92 138.04 236.6 137.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M236.6 137.56H356.13"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 287.02C350.85 287.02 350.85 287.18 350.69 287.5"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M242.05 287.5C242.05 287.18 241.89 287.02 241.73 287.02"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.49 141.72H351.01"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 141.72V147.33"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 147.33H351.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.49 147.33V141.72"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.49 190.1H351.01"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 190.1V195.55"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 195.55H351.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.49 195.55V190.1"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.49 238.32H351.01"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 238.32V243.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 243.93H351.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.49 243.93V238.32"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.49 274.52H351.01"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 274.52V280.13"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 280.13H351.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.49 280.13V274.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.49 232.39H351.01"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 232.39V237.84"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 237.84H351.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.49 237.84V232.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.49 184.01H351.01"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 184.01V189.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 189.46H351.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.49 189.46V184.01"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.49 147.81H351.01"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 147.81V153.26"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 153.26H351.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.49 153.26V147.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.49 196.03H351.01"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 196.03V201.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 201.63H351.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.49 201.63V196.03"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.49 244.41H351.01"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 244.41V249.85"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 249.85H351.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.49 249.85V244.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.49 280.61H351.01"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 280.61V286.06"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 286.06H351.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.49 286.06V280.61"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.49 226.3H351.01"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 226.3V231.75"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 231.75H351.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.49 231.75V226.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.49 177.93H351.01"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 177.93V183.53"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 183.53H351.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.49 183.53V177.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 287.02V141.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 263.2575073242187, 106.04999542236328)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0H.16 .32V-.16H.48V-.32-.48"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.49 287.02V140.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.49 287.02V286.86H351.33 351.17L351.01 287.02"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.49 153.9H351.01"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 153.9V159.34"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 159.34H351.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.49 159.34V153.9"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.49 202.12H351.01"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 202.12V207.56"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 207.56H351.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.49 207.56V202.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.49 250.49H351.01"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 250.49V255.94"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 255.94H351.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.49 255.94V250.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.49 268.6H351.01"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 268.6V274.04"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 274.04H351.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.49 274.04V268.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.49 220.22H351.01"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 220.22V225.82"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 225.82H351.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.49 225.82V220.22"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.49 172H351.01"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 172V177.45"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 177.45H351.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.49 177.45V172"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.49 159.82H351.01"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 159.82V165.43"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 165.43H351.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.49 165.43V159.82"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.49 208.2H351.01"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 208.2V213.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 213.65H351.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.49 213.65V208.2"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.49 256.42H351.01"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 256.42V262.03"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 262.03H351.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.49 262.03V256.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.49 262.51H351.01"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 262.51V267.96"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 267.96H351.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.49 267.96V262.51"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.49 214.29H351.01"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 214.29V219.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 219.74H351.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.49 219.74V214.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.49 165.91H351.01"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 165.91V171.36"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 171.36H351.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.49 171.36V165.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.17 287.02V286.86"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.49 287.02H351.17"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M350.69 141.4C350.69 141.4 350.85 141.4 351.01 141.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 287.02V141.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 141.4C241.89 141.4 242.05 141.4 242.05 141.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M269.45 222.46C269.61 222.46 269.93 222.3 269.93 221.98V221.82"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M268.97 222.14C269.13 222.3 269.29 222.46 269.45 222.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M293.48 246.49C293.64 246.49 293.96 246.33 293.96 246.01V245.85"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M293 246.17C293 246.33 293.32 246.49 293.48 246.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M269.45 270.52C269.61 270.52 269.93 270.2 269.93 270.04V269.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M268.97 270.2C269.13 270.36 269.29 270.52 269.45 270.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M245.41 246.49C245.73 246.49 245.89 246.33 245.89 246.01V245.85"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M244.93 246.17C245.09 246.33 245.25 246.49 245.41 246.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M323.29 210.12C323.45 210.12 323.77 209.96 323.77 209.8"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M322.81 209.48C322.81 209.8 322.97 209.96 323.29 210.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M299.25 186.1C299.57 186.1 299.73 185.94 299.73 185.78"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M298.93 185.46C298.77 185.78 298.93 185.94 299.25 186.1"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M323.29 162.07C323.45 162.07 323.77 161.91 323.77 161.75"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M322.81 161.59C322.81 161.75 322.97 162.07 323.29 162.07"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M347.32 186.1C347.48 186.1 347.64 185.94 347.8 185.78"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M346.84 185.46C346.84 185.78 347 185.94 347.32 186.1"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.57 286.86V286.86 287.02"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.25 287.02H241.57"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 287.02 241.57 286.86H241.41 241.25V287.02"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M243.33 141.24H248.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M248.14 141.24V140.76"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M248.14 140.76H242.69"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M242.69 140.76V141.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M290.6 141.24H292.84"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M293.64 141.24H296.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M296.21 141.24V140.76"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M296.21 140.76H290.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M290.6 140.76V141.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M344.6 141.24H349.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M350.21 141.08V140.76"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M350.21 140.76H344.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M344.6 140.76V141.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M308.7 141.24H311.75"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M312.55 141.24H314.15"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M314.15 141.24V140.76"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M314.15 140.76H308.7"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M308.7 140.76V141.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M260.63 141.24H261.44"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M262.24 141.24H266.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M266.08 141.24V140.76"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M266.08 140.76H260.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M260.63 140.76V141.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M272.65 141.24H273.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M274.73 141.24H278.1"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M278.1 141.24V140.76"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M278.1 140.76H272.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M272.65 140.76V141.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M320.72 141.24H324.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M325.05 141.24H326.17"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M326.17 141.24V140.76"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M326.17 140.76H320.72"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M320.72 140.76V141.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M326.65 141.24H330.5"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M331.3 141.24H332.1"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M332.1 141.24V140.76"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M332.1 140.76H326.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M326.65 140.76V141.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M278.74 141.24H280.18"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M280.98 141.24H284.19"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M284.19 141.24V140.76"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M284.19 140.76H278.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M278.74 140.76V141.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 262.89749145507807, 105.56999206542969)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0 .16 .32 .48"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 181.65750122070307, 105.93000793457031)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V-.16-.32-.48"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M350.53 140.76H242.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M254.71 141.24H255.03"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M255.83 141.24H260.15"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M260.15 141.24V140.76"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M260.15 140.76H254.71"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M254.71 140.76V141.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M302.62 141.24H305.34"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M306.14 141.24H308.22"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M308.22 141.24V140.76"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M308.22 140.76H302.62"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M302.62 140.76V141.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M338.67 141.24H343.16"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M343.96 141.24H344.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M344.12 141.24V140.76"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M344.12 140.76H338.67"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M338.67 140.76V141.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M296.69 141.24H299.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M299.89 141.24H302.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M302.14 141.24V140.76"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M302.14 140.76H296.69"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M296.69 140.76V141.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M248.62 141.24H248.78"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M249.58 141.24H254.22"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M254.22 141.24V140.76"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M254.22 140.76H248.62"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M248.62 140.76V141.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M284.67 141.24H286.59"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M287.39 141.24H290.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M290.12 141.24V140.76"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M290.12 140.76H284.67"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M284.67 140.76V141.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M332.58 141.24H336.75"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M337.55 141.24H338.19"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M338.19 141.24V140.76"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M338.19 140.76H332.58"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M332.58 140.76V141.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M314.63 141.24H318"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M318.8 141.24H320.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M320.08 141.24V140.76"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M320.08 140.76H314.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M314.63 140.76V141.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M266.72 141.24H267.68"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M268.49 141.24H272.17"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M272.17 141.24V140.76"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M272.17 140.76H266.72"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M266.72 140.76V141.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 274.52H241.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.25 274.52V280.13"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.25 280.13H241.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 280.13V274.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 232.39H241.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.25 232.39V237.84"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.25 237.84H241.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 237.84V232.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 184.01H241.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.25 184.01V189.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.25 189.46H241.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 189.46V184.01"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 141.72H241.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.25 141.72V147.33"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.25 147.33H241.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 147.33V141.72"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 190.1H241.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.25 190.1V195.55"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.25 195.55H241.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 195.55V190.1"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 238.32H241.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.25 238.32V243.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.25 243.93H241.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 243.93V238.32"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 280.61H241.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.25 280.61V286.06"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.25 286.06H241.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 286.06V280.61"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 226.3H241.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.25 226.3V231.75"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.25 231.75H241.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 231.75V226.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 177.93H241.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.25 177.93V183.53"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.25 183.53H241.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 183.53V177.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 147.81H241.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.25 147.81V153.26"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.25 153.26H241.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 153.26V147.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 196.03H241.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.25 196.03V201.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.25 201.63H241.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 201.63V196.03"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 244.41H241.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.25 244.41V249.85"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.25 249.85H241.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 249.85V244.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 180.93749999999994, 105.69000244140625)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V.32L.16 .48H.32 .48"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.25 287.02V140.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 268.6H241.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.25 268.6V274.04"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.25 274.04H241.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 274.04V268.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 220.22H241.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.25 220.22V225.82"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.25 225.82H241.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 225.82V220.22"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 172H241.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.25 172V177.45"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.25 177.45H241.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 177.45V172"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 153.9H241.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.25 153.9V159.34"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.25 159.34H241.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 159.34V153.9"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 202.12H241.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.25 202.12V207.56"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.25 207.56H241.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 207.56V202.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 250.49H241.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.25 250.49V255.94"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.25 255.94H241.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 255.94V250.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 262.51H241.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.25 262.51V267.96"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.25 267.96H241.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 267.96V262.51"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 214.29H241.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.25 214.29V219.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.25 219.74H241.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 219.74V214.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 165.91H241.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.25 165.91V171.36"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.25 171.36H241.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 171.36V165.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 159.82H241.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.25 159.82V165.43"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.25 165.43H241.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 165.43V159.82"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 208.2H241.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.25 208.2V213.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.25 213.65H241.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 213.65V208.2"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 256.42H241.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.25 256.42V262.03"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.25 262.03H241.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 262.03V256.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.57 140.92V141.24 141.4H241.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.25 140.92H241.57"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M350.53 140.76V140.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M350.53 140.92V141.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 141.4V141.4H351.17V141.24 140.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.49 140.92H351.17"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M242.21 140.76V140.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.17 147.33V141.72"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.17 195.55V190.1"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.17 243.93V238.32"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.17 280.13V274.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.17 237.84V232.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.17 189.46V184.01"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.17 153.26V147.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.17 201.63V196.03"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.17 249.85V244.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.17 286.06V280.61"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.17 231.75V226.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.17 183.53V177.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.17 159.34V153.9"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.17 207.56V202.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.17 255.94V250.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.17 274.04V268.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.17 225.82V220.22"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.17 177.45V172"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.17 165.43V159.82"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.17 213.65V208.2"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.17 262.03V256.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.17 267.96V262.51"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.17 219.74V214.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.17 171.36V165.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.57 274.52V280.13"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.57 232.39V237.84"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.57 184.01V189.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.57 141.72V147.33"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.57 190.1V195.55"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.57 238.32V243.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.57 280.61V286.06"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.57 226.3V231.75"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.57 177.93V183.53"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.57 147.81V153.26"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.57 196.03V201.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.57 244.41V249.85"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.57 268.6V274.04"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.57 220.22V225.82"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.57 172V177.45"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.57 153.9V159.34"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.57 202.12V207.56"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.57 250.49V255.94"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.57 262.51V267.96"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.57 214.29V219.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.57 165.91V171.36"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.57 159.82V165.43"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.57 208.2V213.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.57 256.42V262.03"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 287.18C351.17 287.18 351.17 287.02 351.17 287.02"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.33 287.18 351.49 287.02"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 140.76H350.53"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 140.76V140.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 140.92H350.53"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.57 287.02C241.57 287.02 241.57 287.18 241.73 287.18"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.25 287.02V287.18H241.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 140.92V140.76"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 140.76H242.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 140.92H242.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M354.05 288.3V287.5"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.84 288.3V287.5"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M354.05 287.5H350.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M242.53 287.5H238.84"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M236.28 140.92H241.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M236.28 140.92V138.2"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M356.46 138.2H236.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.49 140.92H356.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M356.46 140.92V138.2"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M249.1 251.13C251.82 262.35 263.36 269.24 274.57 266.35 285.79 263.47 292.68 252.1 289.8 240.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M291.88 240.4C288.68 228.07 276.18 220.54 263.84 223.58 253.58 226.14 246.37 235.44 246.37 246.01"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M246.37 246.01C246.37 247.93 246.53 249.69 247.01 251.62"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M302.94 180.49C302.46 182.25 302.3 183.85 302.3 185.62"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M302.3 185.62C302.3 197.15 311.75 206.6 323.29 206.6 332.9 206.6 341.39 200.03 343.64 190.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 259.2900085449218, 143.41500854492185)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C3.04-12.33-4.49-24.83-16.83-28.03-29.16-31.08-41.66-23.55-44.71-11.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 94.88999938964838, 122.39250183105466)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-11.7 9.45-20.99 20.99-20.99 32.52-20.99 41.98-11.7 41.98 0 41.98 11.53 32.52 20.98 20.99 20.98 9.45 20.98 0 11.53 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 94.52999877929682, 122.39250183105466)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-11.86 9.61-21.47 21.47-21.47 33.32-21.47 42.94-11.86 42.94 0 42.94 11.85 33.32 21.3 21.47 21.3 9.61 21.3 0 11.85 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 94.52999877929682, 122.39250183105466)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-11.86 9.61-21.47 21.47-21.47 33.32-21.47 42.94-11.86 42.94 0 42.94 11.85 33.32 21.46 21.47 21.46 9.61 21.46 0 11.85 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 94.16999816894526, 122.39250183105466)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-12.18 9.93-21.95 21.95-21.95 33.97-21.95 43.9-12.18 43.9 0 43.9 12.01 33.97 21.78 21.95 21.78 9.93 21.78 0 12.01 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 93.32250213623041, 122.39250183105466)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-12.82 10.42-23.07 23.08-23.07 35.74-23.07 46.15-12.82 46.15 0 46.15 12.65 35.74 23.07 23.08 23.07 10.42 23.07 0 12.65 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 91.16250610351557, 122.39250183105466)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-14.42 11.7-25.95 25.96-25.95 40.22-25.95 51.92-14.42 51.92 0 51.92 14.26 40.22 25.95 25.96 25.95 11.7 25.95 0 14.26 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 92.24250030517572, 122.39250183105466)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .16-.48 .48-.48 .81-.48 .97-.32 .97 0 .97 .16 .81 .48 .48 .48 .16 .48 0 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 110.2724990844726, 140.41500854492185)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .16-.48 .48-.48 .81-.48 .97-.32 .97 0 .97 .16 .81 .48 .48 .48 .16 .48 0 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 128.29499816894526, 122.39250183105466)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .16-.48 .48-.48 .81-.48 .97-.32 .97 0 .97 .16 .81 .48 .48 .48 .16 .48 0 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 110.2724990844726, 104.37000274658203)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .16-.48 .48-.48 .81-.48 .97-.32 .97 0 .97 .16 .81 .48 .48 .48 .16 .48 0 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 94.52999877929682, 169.97250366210935)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-11.86 9.61-21.47 21.47-21.47 33.32-21.47 42.94-11.86 42.94 0 42.94 11.85 33.32 21.46 21.47 21.46 9.61 21.46 0 11.85 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 94.16999816894526, 169.97250366210935)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-12.02 9.93-21.79 21.95-21.79 33.97-21.79 43.9-12.02 43.9 0 43.9 12.17 33.97 21.94 21.95 21.94 9.93 21.94 0 12.17 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 94.52999877929682, 169.97250366210935)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-11.86 9.61-21.31 21.47-21.31 33.32-21.31 42.94-11.86 42.94 0 42.94 11.85 33.32 21.46 21.47 21.46 9.61 21.46 0 11.85 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 94.88999938964838, 169.97250366210935)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-11.54 9.45-20.99 20.99-20.99 32.52-20.99 41.98-11.54 41.98 0 41.98 11.69 32.52 20.98 20.99 20.98 9.45 20.98 0 11.69 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 93.32250213623041, 169.97250366210935)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-12.66 10.42-23.07 23.08-23.07 35.74-23.07 46.15-12.66 46.15 0 46.15 12.81 35.74 23.06 23.08 23.06 10.42 23.06 0 12.81 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 91.16250610351557, 169.97250366210935)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-14.26 11.7-25.96 25.96-25.96 40.22-25.96 51.92-14.26 51.92 0 51.92 14.41 40.22 25.95 25.96 25.95 11.7 25.95 0 14.41 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 92.24250030517572, 169.97250366210935)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.17 .16-.49 .48-.49 .8-.49 .96-.17 .96 0 .96 .32 .8 .48 .48 .48 .16 .48 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 110.2724990844726, 187.98748779296872)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .16-.48 .48-.48 .8-.48 .96-.32 .96 0 .96 .32 .8 .48 .48 .48 .16 .48 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 128.29499816894526, 169.97250366210935)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.17 .16-.49 .48-.49 .8-.49 .96-.17 .96 0 .96 .32 .8 .48 .48 .48 .16 .48 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 110.2724990844726, 151.95001220703122)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.16 .16-.48 .48-.48 .8-.48 .96-.16 .96 0 .96 .32 .8 .48 .48 .48 .16 .48 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.17 287.18V287.02"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.17 140.92H351.01"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.57 287.18V287.02"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 140.92H241.57"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M347.96 292.46V293.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M349.57 292.3H348.76L347.96 292.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.33 292.46 350.53 292.3H349.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.33 293.91V292.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M347.96 293.91C349.08 294.23 350.21 294.23 351.33 293.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.33 293.91V294.07"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M347.96 294.07C349.08 294.23 350.21 294.23 351.33 294.07"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M347.96 294.07V293.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M349.73 292.3H350.53L351.33 292.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M349.73 292.3H349.57"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M347.96 292.46 348.76 292.3H349.57"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M347.96 294.07C347.8 294.07 347.64 294.07 347.64 294.07"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.65 294.07C351.49 294.07 351.49 294.07 351.33 294.07"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.17 294.23C351.33 294.07 351.49 294.07 351.65 294.07"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M347.64 294.07 347.96 294.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.65 292.46 351.17 292.3H351.01"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.33 292.46C351.49 292.46 351.49 292.46 351.65 292.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 292.3H349.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M347.64 292.46C347.64 292.46 347.8 292.46 347.96 292.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M348.12 292.3H347.96L347.64 292.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M349.57 292.3H348.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.33 292.46C351.49 292.46 351.65 292.46 351.65 292.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M347.48 292.46C347.64 292.46 347.8 292.46 347.96 292.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M347.64 292.46H347.48"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.65 293.91V292.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.65 293.91C351.65 293.91 351.49 293.91 351.33 293.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.33 292.46V293.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M347.48 293.91C347.48 293.91 347.48 294.07 347.64 294.07"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M347.96 293.91C347.8 293.91 347.64 293.91 347.48 293.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M347.96 293.91V294.07"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M347.96 293.91V292.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M347.48 292.46V293.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.65 294.07V293.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.33 294.07V293.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M349.73 292.3H349.57"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M348.12 292.3H348.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.57 292.46V293.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M243.17 292.3H242.21L241.57 292.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M244.77 292.46 243.97 292.3H243.17"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M244.77 293.91V292.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.57 293.91C242.53 294.23 243.65 294.23 244.77 293.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M244.77 293.91C244.77 293.91 244.77 294.07 244.93 294.07"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.41 294.07C242.53 294.23 243.81 294.23 244.93 294.07"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.41 294.07 241.57 293.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M243.17 292.3H243.97L244.93 292.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M244.93 292.46H244.77"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.57 292.46H241.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.41 292.46 242.21 292.3H243.17"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.41 294.07C241.41 294.07 241.25 294.07 241.09 294.07"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M245.25 294.07C245.09 294.07 244.93 294.07 244.93 294.07"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M244.77 294.23C244.93 294.07 245.09 294.07 245.25 294.07"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.09 294.07 241.57 294.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M245.25 292.46 244.77 292.3H244.61"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M244.93 292.46C244.93 292.46 245.09 292.46 245.25 292.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M244.61 292.3H243.17"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.09 292.46C241.25 292.46 241.41 292.46 241.41 292.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 292.3H241.57L241.09 292.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M243.17 292.3H241.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.09 292.46C241.25 292.46 241.25 292.46 241.41 292.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.09 293.91V294.07"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.41 293.91C241.25 293.91 241.25 293.91 241.09 293.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.41 293.91V294.07"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M244.93 292.46C244.93 292.46 245.09 292.46 245.25 292.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.41 293.91V292.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.09 292.46V293.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M245.25 293.91V292.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M245.25 293.91C245.09 293.91 244.93 293.91 244.93 293.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M244.93 292.46V293.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M245.25 294.07V293.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M244.93 294.07V293.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.57 293.91H241.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.57 292.46H241.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M244.93 293.91H244.77"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M244.93 292.46H244.77"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 292.3H241.89"
    />
    <g
      clipPath="url(#clip_44)"
      transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -5.684341886080802e-14, 0)"
    >
      <path
        transform="matrix(.75,0,0,.75,0,0)"
        strokeWidth={0.801}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="#040503"
        d="M115.46 137.56 114.82 137.4"
      />
    </g>
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M115.14 137.4H114.82"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M115.46 137.56 115.14 137.4"
    />
    <g
      clipPath="url(#clip_45)"
      transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -5.684341886080802e-14, 0)"
    >
      <path
        transform="matrix(.75,0,0,.75,0,0)"
        strokeWidth={0.801}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="#040503"
        d="M114.82 295.19 115.46 294.87"
      />
    </g>
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M115.14 295.19 115.46 294.87"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M115.14 295.19H114.82"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M115.14 137.08 115.46 137.56"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M115.14 137.08H361.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M361.42 137.56 361.74 137.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M361.42 294.87 361.9 295.19"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M361.74 295.19H361.9"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M361.42 294.87 361.74 295.19"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M361.9 137.4 361.42 137.56"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M361.9 137.4V295.19"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M361.42 137.56V294.87"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M361.74 295.51 361.42 294.87"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M361.74 295.51H361.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M355.17 295.51H354.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M122.83 295.51H121.71"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M115.46 295.51H115.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M115.46 294.87 115.14 295.51"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M361.74 137.4 361.42 137.56"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M361.74 137.4H361.9"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M115.46 294.87V137.56"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 92.96249389648432, 218.86500549316403)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .32-.48 .48-.48 .8-.48 .96-.32 .96 0 .96 .16 .8 .32 .48 .32 .32 .32 0 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M352.13 291.34C351.97 291.5 351.81 291.82 351.97 291.98 352.13 291.98 352.13 292.14 352.13 292.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 92.24250030517572, 122.39250183105466)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .32-.48 .48-.48 .8-.48 .96-.32 .96 0 .96 .16 .8 .48 .48 .48 .32 .48 0 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 104.13750457763666, 202.41000366210935)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-1.44 1.29-2.57 2.73-2.57 4.17-2.57 5.29-1.44 5.29 0 5.29 1.44 4.17 2.56 2.73 2.56 1.29 2.56 0 1.44 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.14 172.48V164.31"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.14 164.31H359.02"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.02 172.48H360.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M117.54 172.48V164.31"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M117.54 164.31H116.26"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.26 164.31V172.48"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.26 172.48H117.54"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.26 260.27V268.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 86.7150039672851, 202.76998901367185)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.8 .64-1.44 1.44-1.44 2.24-1.44 2.72-.8 2.72 0 2.72 .8 2.24 1.28 1.44 1.28 .64 1.28 0 .8 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 86.7150039672851, 214.66500854492185)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.64 .64-1.28 1.44-1.28 2.24-1.28 2.72-.64 2.72 0 2.72 .8 2.24 1.44 1.44 1.44 .64 1.44 0 .8 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 128.65499877929682, 209.61749267578122)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-1.44 1.29-2.57 2.73-2.57 4.17-2.57 5.29-1.44 5.29 0 5.29 1.44 4.17 2.56 2.73 2.56 1.29 2.56 0 1.44 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 121.44749450683588, 202.41000366210935)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-1.44 1.29-2.57 2.73-2.57 4.17-2.57 5.29-1.44 5.29 0 5.29 1.44 4.17 2.56 2.73 2.56 1.29 2.56 0 1.44 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 128.65499877929682, 202.41000366210935)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-1.44 1.29-2.57 2.73-2.57 4.17-2.57 5.29-1.44 5.29 0 5.29 1.44 4.17 2.56 2.73 2.56 1.29 2.56 0 1.44 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M160.17 211.25C160.17 210.77 160.01 210.12 159.85 209.64"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M163.05 177.12V177.12L163.21 176.48"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M163.21 176.48V149.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M163.21 149.73 163.05 149.25V149.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.14 260.27H359.02"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.02 268.6H360.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 123.96749877929682, 122.39250183105466)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .32-.64 .64-.64 .96-.64 1.28-.32 1.28 0 1.28 .32 .96 .64 .64 .64 .32 .64 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 98.00999450683588, 202.41000366210935)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-1.44 1.12-2.57 2.57-2.57 4.01-2.57 5.29-1.44 5.29 0 5.29 1.44 4.01 2.56 2.57 2.56 1.12 2.56 0 1.44 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M159.85 243.61C160.01 243.13 160.17 242.64 160.17 242.16"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 110.2724990844726, 187.98748779296872)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.16 .16-.48 .48-.48 .8-.48 .96-.16 .96 0 .96 .32 .8 .48 .48 .48 .16 .48 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 86.7150039672851, 206.49000549316403)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.8 .64-1.44 1.44-1.44 2.24-1.44 2.72-.8 2.72 0 2.72 .64 2.24 1.29 1.44 1.29 .64 1.29 0 .64 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 110.2724990844726, 140.41500854492185)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .16-.49 .48-.49 .8-.49 .96-.32 .96 0 .96 .16 .8 .32 .48 .32 .16 .32 0 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 92.24250030517572, 169.97250366210935)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.17 .32-.49 .48-.49 .8-.49 .96-.17 .96 0 .96 .32 .8 .48 .48 .48 .32 .48 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 110.2724990844726, 151.95001220703122)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.16 .16-.48 .48-.48 .8-.48 .96-.16 .96 0 .96 .32 .8 .48 .48 .48 .16 .48 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 128.29499816894526, 169.97250366210935)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.17 .16-.49 .48-.49 .64-.49 .96-.17 .96 0 .96 .32 .64 .48 .48 .48 .16 .48 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 121.44749450683588, 209.61749267578122)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-1.44 1.29-2.57 2.73-2.57 4.17-2.57 5.29-1.44 5.29 0 5.29 1.44 4.17 2.56 2.73 2.56 1.29 2.56 0 1.44 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 128.29499816894526, 122.39250183105466)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .16-.48 .48-.48 .64-.48 .96-.32 .96 0 .96 .16 .64 .48 .48 .48 .16 .48 0 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M117.54 260.27H116.26"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M116.26 268.6H117.54"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M160.17 242V211.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M117.54 268.6V260.27"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M360.14 268.6V260.27"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 110.2724990844726, 104.37000274658203)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .16-.48 .48-.48 .81-.48 .97-.32 .97 0 .97 .16 .81 .48 .48 .48 .16 .48 0 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M114.82 295.19V137.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M361.74 137.4V137.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M361.42 137.56 361.74 137.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M361.74 295.19 361.42 294.87"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M361.74 295.19V295.51"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M115.14 295.19V295.51"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M115.46 294.87 115.14 295.19"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M115.14 137.4 115.46 137.56"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M115.14 137.4V137.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M361.74 137.4H361.58"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M361.58 295.19H361.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M115.14 295.19H115.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M115.3 137.4H115.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M350.21 287.5V287.18"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M242.53 287.5V287.18"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M242.53 287.5H350.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M242.53 287.18H350.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M350.21 287.18V287.02"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M350.21 287.02H242.53"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M242.53 287.02V287.18"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M242.37 147.49H242.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M242.37 142.04V147.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M242.05 142.04H242.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M242.53 141.24H242.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M242.53 141.56V141.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M242.53 287.02V141.56"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M242.05 287.18H242.53"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M242.05 141.4V141.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M350.21 141.56H242.53"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M350.21 287.02V141.56"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 221.91751098632807, 110.25749206542969)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .16-.48 .48-.48 .8-.48 .96-.32 .96 0 .96 .32 .8 .48 .48 .48 .16 .48 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 221.91751098632807, 160.71749877929685)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.16 .16-.48 .48-.48 .8-.48 .96-.16 .96 0 .96 .32 .8 .64 .48 .64 .16 .64 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 221.91751098632807, 211.2975158691406)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .16-.48 .48-.48 .8-.48 .96-.32 .96 0 .96 .32 .8 .48 .48 .48 .16 .48 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 183.69749450683588, 184.50749206542966)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.16 .16-.48 .48-.48 .8-.48 .96-.16 .96 0 .96 .32 .8 .48 .48 .48 .16 .48 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 201.72750854492182, 202.52999877929685)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .16-.48 .48-.48 .64-.48 .96-.32 .96 0 .96 .32 .64 .48 .48 .48 .16 .48 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 219.74999999999994, 184.50749206542966)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.16 .16-.48 .48-.48 .64-.48 .96-.16 .96 0 .96 .32 .64 .48 .48 .48 .16 .48 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 201.72750854492182, 166.48500061035153)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.16 .16-.48 .48-.48 .64-.48 .96-.16 .96 0 .96 .32 .64 .48 .48 .48 .16 .48 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 242.10749816894526, 157.22999572753903)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.16 .32-.48 .48-.48 .8-.48 .96-.16 .96 0 .96 .32 .8 .48 .48 .48 .32 .48 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 260.13000488281244, 139.2149963378906)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.16 .32-.48 .48-.48 .8-.48 .96-.16 .96 0 .96 .32 .8 .48 .48 .48 .32 .48 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 242.10749816894526, 121.19249725341794)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.16 .32-.48 .48-.48 .8-.48 .96-.16 .96 0 .96 .32 .8 .48 .48 .48 .32 .48 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 224.07748413085932, 139.2149963378906)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.16 .32-.48 .48-.48 .8-.48 .96-.16 .96 0 .96 .32 .8 .48 .48 .48 .32 .48 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M350.69 147.49H350.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M350.37 142.04H350.69"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M350.37 147.49V142.04"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M350.69 141.24H350.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M350.69 141.4V141.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M350.21 287.18H350.69"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M350.21 141.24V141.56"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M349.4 141.4H343.96"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M349.4 140.92V141.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M343.96 140.92H344.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M344.6 140.92H349.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M343.96 141.4V140.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M343.16 141.4H337.55"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M343.16 140.92V141.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M337.55 140.92H338.19"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M338.67 140.92H343.16"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M337.55 141.4V140.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M336.75 141.4H331.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M336.75 140.92V141.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M331.3 140.92H332.1"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M332.58 140.92H336.75"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M331.3 141.4V140.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M330.5 141.4H325.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M330.5 140.92V141.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M325.05 140.92H326.17"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M326.65 140.92H330.5"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M325.05 141.4V140.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M324.25 141.4H318.8"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M324.25 140.92V141.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M318.8 140.92H320.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M320.72 140.92H324.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M318.8 141.4V140.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M318 141.4H312.55"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M318 140.92V141.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M312.55 140.92H314.15"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M314.63 140.92H318"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M312.55 141.4V140.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M311.75 141.4H306.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M311.75 140.92V141.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M306.14 140.92H308.22"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M308.7 140.92H311.75"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M306.14 141.4V140.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M305.34 141.4H299.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M305.34 140.92V141.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M299.89 140.92H302.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M302.62 140.92H305.34"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M299.89 141.4V140.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M299.09 141.4H293.64"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M299.09 140.92V141.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M293.64 140.92H296.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M296.69 140.92H299.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M293.64 141.4V140.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M292.84 141.4H287.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M292.84 140.92V141.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M287.39 140.92H290.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M290.6 140.92H292.84"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M287.39 141.4V140.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M286.59 141.4H280.98"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M286.59 140.92V141.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M280.98 140.92H284.19"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M284.67 140.92H286.59"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M280.98 141.4V140.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M280.18 141.4H274.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M280.18 140.92V141.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M274.73 140.92H278.1"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M278.74 140.92H280.18"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M274.73 141.4V140.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M273.93 141.4H268.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M273.93 140.92V141.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M268.49 140.92H272.17"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M272.65 140.92H273.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M268.49 141.4V140.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M267.68 141.4H262.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M267.68 140.92V141.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M262.24 140.92H266.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M266.72 140.92H267.68"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M262.24 141.4V140.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M261.44 141.4H255.83"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M261.44 140.92V141.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M255.83 140.92H260.15"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M260.63 140.92H261.44"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M255.83 141.4V140.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M248.78 141.4H243.33"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M248.78 140.92V141.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M243.33 140.92H248.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M248.62 140.92H248.78"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M243.33 141.4V140.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M255.03 141.4H249.58"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M255.03 140.92V141.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M249.58 140.92H254.22"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M254.71 140.92H255.03"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M249.58 141.4V140.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M242.53 141.08V141.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M350.21 140.92H344.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M344.12 140.92H338.67"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M338.19 140.92H332.58"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M332.1 140.92H326.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M326.17 140.92H320.72"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M320.08 140.92H314.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M314.15 140.92H308.7"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M308.22 140.92H302.62"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M302.14 140.92H296.69"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M296.21 140.92H290.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M290.12 140.92H284.67"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M284.19 140.92H278.74"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M278.1 140.92H272.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M272.17 140.92H266.72"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M266.08 140.92H260.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M260.15 140.92H254.71"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M254.22 140.92H248.62"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M248.14 140.92H242.69"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M350.21 141.24V141.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 287.18V287.5"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 287.18H241.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 287.5C241.89 287.5 241.89 287.5 242.05 287.34"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M350.85 287.18H351.01"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 287.18V287.5"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M350.69 287.34C350.85 287.5 350.85 287.5 351.01 287.5"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M236.44 287.5V287.18"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M236.44 287.18H237.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M237.88 287.18V287.5"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M237.88 287.5H236.44"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 287.18H237.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 287.5H237.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M354.85 287.18H351.01"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M354.85 287.5V287.18"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M354.85 287.5H351.01"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M354.85 287.18H356.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M356.29 287.18V287.5"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M356.29 287.5H354.85"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M242.53 140.76H246.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M242.37 140.76V140.76H242.53"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M350.21 140.76H350.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M350.21 140.76H346.36"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 221.91751098632807, 211.2975158691406)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .16-.48 .48-.48 .81-.48 .97-.32 .97 0 .97 .16 .81 .48 .48 .48 .16 .48 0 .16 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 221.91751098632807, 110.25749206542969)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.32 .16-.48 .48-.48 .8-.48 .96-.32 .96 0 .96 .32 .8 .48 .48 .48 .16 .48 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 221.91751098632807, 160.71749877929685)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-.16 .16-.32 .48-.32 .8-.32 .96-.16 .96 0 .96 .32 .8 .48 .48 .48 .16 .48 0 .32 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 177.8099975585937, 217.78500366210935)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0H-.16V-.16H-.32V-.32-.48"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M355.97 289.9H236.76"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 266.9775085449218, 217.42498779296872)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V.16 .32H-.16V.48"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M355.81 290.38H237.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M236.76 289.9V288.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M355.97 288.3H236.76"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M355.97 288.3V289.9"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M236.44 289.9H236.76"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M236.76 289.9V290.06"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M236.44 289.9C236.44 290.22 236.76 290.38 237.08 290.38"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M356.29 289.9H355.97"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M356.29 288.3H355.97"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M356.29 289.9V288.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M355.97 289.9V288.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M355.81 290.38C355.97 290.38 356.29 290.22 356.29 289.9"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M355.97 290.06V289.9"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M236.76 288.3V289.9"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M236.44 288.3V289.9"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M236.44 288.3H236.76"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M353.09 294.07C352.93 294.07 352.77 294.07 352.61 294.07"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M356.13 294.07 355.17 294.23H354.21 353.57L353.25 294.07H353.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M356.13 294.07H356.29 356.13"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M355.33 294.23C355.65 294.07 355.97 294.07 356.13 294.07"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.33 294.07C351.49 294.07 351.65 294.23 351.81 294.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M353.09 292.46 353.57 292.3H354.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M352.61 292.46C352.77 292.46 352.93 292.46 353.09 292.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M352.29 292.3H352.61V292.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M354.21 292.3H352.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M353.09 293.91V292.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M353.09 293.91C352.93 293.91 352.77 293.91 352.45 293.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M352.45 292.46V293.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M352.45 292.46C352.77 292.46 352.93 292.46 353.09 292.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M352.61 292.46H352.45"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M352.45 293.91 352.61 294.07"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M353.09 294.07V293.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M353.09 293.91V293.91L353.57 294.07H354.37 355.17L355.97 293.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M355.97 293.91C355.97 293.91 355.97 294.07 356.13 294.07"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M353.09 294.07V293.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M354.21 292.3H353.57L353.09 292.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M353.09 292.46V293.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M354.37 292.3V292.3H354.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M355.97 292.46 355.17 292.3H354.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M355.97 293.91V292.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M352.61 292.3H352.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M352.29 292.3H351.81 351.65"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M354.37 292.3H355.17L356.13 292.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M356.13 292.46H355.97"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M355.01 292.3H354.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M354.37 292.3H354.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M352.29 292.3H352.13"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M356.13 292.46 355.49 292.3H355.33"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M356.13 292.46V292.46H356.29 356.13"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M356.13 292.46H355.97"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M356.13 294.07V293.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M356.13 293.91H355.97"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M356.13 292.46V293.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.49 292.3H351.81 352.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 265.41751098632807, 213.70500183105466)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0H-.16-.48-.64-.8"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 265.65750122070307, 213.82501220703122)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.48-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 265.65750122070307, 213.82501220703122)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.48L-.8 .16H-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 265.65750122070307, 213.94500732421872)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.48-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 265.65750122070307, 214.06500244140622)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.48-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 265.65750122070307, 214.06500244140622)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32L-.48 .16H-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 265.65750122070307, 214.18499755859372)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.48-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 265.65750122070307, 214.30499267578122)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.48-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 265.65750122070307, 214.30499267578122)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0L-.16 .16H-.32-.48-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 265.65750122070307, 214.42498779296872)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.48-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 265.65750122070307, 214.54499816894528)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.48-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 265.65750122070307, 214.54499816894528)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V.16H-.16-.32-.48-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 265.65750122070307, 214.66500854492185)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.48-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 265.65750122070307, 214.78500366210935)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.48-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 265.65750122070307, 214.90499877929685)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.48-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 265.65750122070307, 214.90499877929685)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.48-.8-.96L-1.12 .16"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 265.65750122070307, 215.0250091552734)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.48-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 265.65750122070307, 215.14498901367185)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.48-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 265.65750122070307, 215.14498901367185)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.48-.8L-.96 .16H-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 265.65750122070307, 215.26498413085935)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.48-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 265.65750122070307, 215.3849945068359)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.48-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M354.21 287.5V287.5H354.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M354.21 287.5V287.5H354.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M354.21 287.66V287.66H354.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M354.21 287.82V287.82H354.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M354.21 287.82V287.82L354.05 287.98"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M354.21 287.98V287.98H354.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M354.21 288.14V288.14H354.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 264.81750488281244, 213.70500183105466)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0H.16 .32 .48 .64 .96 1.12 .96 .8"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 265.65750122070307, 213.70500183105466)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.48-.8-.96-1.12-1.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 265.65750122070307, 213.82501220703122)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.48-.8-.96-1.12-1.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 265.65750122070307, 213.82501220703122)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V.16H-.16-.32-.48-.8-.96-1.12-1.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 265.65750122070307, 213.94500732421872)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.48-.8-.96-1.12L-1.28 .16"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 265.65750122070307, 214.06500244140622)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.48-.8-.96-1.12-1.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 265.65750122070307, 214.18499755859372)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.48-.8-.96-1.12-1.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 265.65750122070307, 214.18499755859372)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.48-.8-.96L-1.12 .16H-1.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 265.65750122070307, 214.30499267578122)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.48-.8-.96-1.12-1.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 265.65750122070307, 214.42498779296872)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.48-.8-.96-1.12-1.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 265.65750122070307, 214.42498779296872)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.48-.8L-.96 .16H-1.12-1.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 265.65750122070307, 214.54499816894528)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.48-.8-.96-1.12-1.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 265.65750122070307, 214.66500854492185)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.48-.8-.96-1.12-1.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 265.65750122070307, 214.66500854492185)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.48L-.8 .16H-.96-1.12-1.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 265.65750122070307, 214.78500366210935)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.48-.8-.96-1.12-1.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 265.65750122070307, 214.90499877929685)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.48-.8-.96-1.12-1.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 265.65750122070307, 214.90499877929685)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16L-.32 .16H-.48-.8-.96-1.12-1.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 265.65750122070307, 215.0250091552734)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.48-.8-.96-1.12-1.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 265.65750122070307, 215.14498901367185)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.48-.8-.96-1.12-1.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 265.65750122070307, 215.14498901367185)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0L-.16 .16H-.32-.48-.8-.96-1.12-1.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 265.65750122070307, 215.26498413085935)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.48-.8-.96-1.12-1.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M354.05 287.5H354.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M354.05 287.66H354.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M354.05 287.66H354.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M354.05 287.82H354.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M354.05 287.98H354.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M354.05 287.98H354.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M354.05 288.14H354.21"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M353.09 284.94H353.25 353.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 265.65750122070307, 213.70500183105466)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.48-.8L-.96 .16H-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 265.65750122070307, 213.82501220703122)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.48-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 265.65750122070307, 213.94500732421872)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.48-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 265.65750122070307, 213.94500732421872)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32L-.48 .16H-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 265.65750122070307, 214.06500244140622)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.48-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 265.65750122070307, 214.18499755859372)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.48-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 265.65750122070307, 214.18499755859372)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16L-.32 .16H-.48-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 265.65750122070307, 214.30499267578122)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.48-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 265.65750122070307, 214.42498779296872)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.48-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 265.65750122070307, 214.42498779296872)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0L-.16 .16H-.32-.48-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 265.65750122070307, 214.54499816894528)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.48-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 265.65750122070307, 214.66500854492185)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.48-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 265.65750122070307, 214.78500366210935)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.48-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 265.65750122070307, 214.78500366210935)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.48-.8-.96-1.12V.16"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 265.65750122070307, 214.90499877929685)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.48-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 265.65750122070307, 215.0250091552734)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.48-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 265.65750122070307, 215.0250091552734)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.48-.8L-.96 .16H-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 265.65750122070307, 215.14498901367185)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.48-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 265.65750122070307, 215.26498413085935)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.48-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 265.65750122070307, 215.26498413085935)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.48L-.8 .16H-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M354.21 287.5V287.5H354.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M354.21 287.66V287.66H354.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M354.21 287.66V287.66H354.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M354.21 287.82V287.82H354.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M354.21 287.98V287.98H354.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M354.21 287.98V288.14H354.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M354.21 288.14V288.14H354.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M353.89 284.94H353.09"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.52 294.07C238.36 294.07 238.36 294.07 238.2 294.07"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M240.92 294.23 241.25 294.07H241.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M236.6 294.07C236.76 294.07 237.08 294.07 237.4 294.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.52 292.46 239.16 292.3H239.8"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.2 292.46C238.36 292.46 238.36 292.46 238.52 292.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M237.88 292.3H238.2V292.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M239.8 292.3H237.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.04 292.46C238.2 292.46 238.52 292.46 238.68 292.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.2 292.46H238.04"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.68 292.46H238.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.68 293.91V292.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.68 293.91C238.52 293.91 238.2 293.91 238.04 293.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.04 292.46V293.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.04 293.91C238.04 293.91 238.04 294.07 238.2 294.07"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.52 294.07C238.68 294.07 238.68 293.91 238.68 293.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.52 294.07C238.68 294.07 238.68 293.91 238.68 293.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M239.8 292.3H239.16L238.68 292.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.68 292.46H238.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.68 292.46V293.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.09 292.46 240.6 292.3H239.8"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.04 292.3H237.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.2 292.46H238.04"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M236.44 292.46V293.91"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M237.72 292.3H237.24L236.44 292.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M237.88 292.3H237.72"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M240.6 292.3H239.8"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M237.88 292.3H237.72"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M239.8 292.3H240.6 241.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.41 292.3H240.92"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 177.33000183105463, 219.3450012207031)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H.16"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M236.6 292.46 237.24 292.3H237.88"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M237.88 292.3H237.72"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 179.6100006103515, 213.70500183105466)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C-.16 0-.32 0-.32 0H-.48-.64-.8-.96"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 179.729995727539, 213.82501220703122)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16C-.32 0-.48 0-.48 0H-.64-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 179.729995727539, 213.82501220703122)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16C-.32 0-.48 0-.48 0L-.64 .16H-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 179.729995727539, 213.94500732421872)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16C-.32 0-.48 0-.48 0H-.64-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 179.729995727539, 214.06500244140622)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16C-.32 0-.48 0-.48 0H-.64-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 179.729995727539, 214.06500244140622)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16C-.32 0-.48 .16-.48 .16H-.64-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 179.729995727539, 214.18499755859372)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16C-.32 0-.48 0-.48 0H-.64-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 179.729995727539, 214.30499267578122)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16C-.32 0-.48 0-.48 0H-.64-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 179.729995727539, 214.30499267578122)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0L-.16 .16C-.32 .16-.48 .16-.48 .16H-.64-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 179.729995727539, 214.42498779296872)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16C-.32 0-.48 0-.48 0H-.64-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 179.729995727539, 214.54499816894528)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16C-.32 0-.48 0-.48 0H-.64-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 179.729995727539, 214.54499816894528)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V.16H-.16C-.32 .16-.48 .16-.48 .16H-.64-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 179.729995727539, 214.66500854492185)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16C-.32 0-.48 0-.48 0H-.64-.8-.96L-1.12 .16"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 179.729995727539, 214.78500366210935)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16C-.32 0-.48 0-.48 0H-.64-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 179.729995727539, 214.90499877929685)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16C-.32 0-.48 0-.48 0H-.64-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 179.729995727539, 214.90499877929685)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16C-.32 0-.48 0-.48 0H-.64-.8L-.96 .16H-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 179.729995727539, 215.0250091552734)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16C-.32 0-.48 0-.48 0H-.64-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 179.729995727539, 215.14498901367185)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16C-.32 0-.48 0-.48 0H-.64-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 179.729995727539, 215.14498901367185)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16C-.32 0-.48 0-.48 0H-.64L-.8 .16H-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 179.729995727539, 215.26498413085935)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16C-.32 0-.48 0-.48 0H-.64-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 179.729995727539, 215.3849945068359)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16C-.32 0-.48 0-.48 0H-.64-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.84 287.5V287.5H238.68 238.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.84 287.66V287.66H238.68 238.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.84 287.66V287.66H238.68 238.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.84 287.82V287.82H238.68 238.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.84 287.98V287.98H238.68 238.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.84 287.98V287.98H238.68 238.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.84 288.14V288.14H238.68 238.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 178.88999938964838, 213.70500183105466)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0H.16 .32 .48 .8 .96 1.12 .96"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 179.85000610351557, 213.70500183105466)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.64-.8-1.12-1.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 179.85000610351557, 213.82501220703122)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.64-.8-1.12-1.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 179.85000610351557, 213.82501220703122)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V.16H-.16-.32-.64-.8-1.12-1.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 179.85000610351557, 213.94500732421872)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.64-.8-1.12-1.28V.16"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 179.85000610351557, 214.06500244140622)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.64-.8-1.12-1.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 179.85000610351557, 214.18499755859372)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.64-.8-1.12-1.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 179.85000610351557, 214.18499755859372)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.64-.8-1.12L-1.28 .16"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 179.85000610351557, 214.30499267578122)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.64-.8-1.12-1.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 179.85000610351557, 214.42498779296872)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.64-.8-1.12-1.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 179.85000610351557, 214.42498779296872)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.64-.8L-1.12 .16H-1.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 179.85000610351557, 214.54499816894528)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.64-.8-1.12-1.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 179.85000610351557, 214.66500854492185)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.64-.8-1.12-1.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 179.85000610351557, 214.66500854492185)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32L-.64 .16H-.8-1.12-1.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 179.85000610351557, 214.78500366210935)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.64-.8-1.12-1.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 179.85000610351557, 214.90499877929685)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.64-.8-1.12-1.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 179.85000610351557, 214.90499877929685)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16L-.32 .16H-.64-.8-1.12-1.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 179.85000610351557, 215.0250091552734)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.64-.8-1.12-1.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 179.85000610351557, 215.14498901367185)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.64-.8-1.12-1.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 179.85000610351557, 215.14498901367185)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0L-.16 .16H-.32-.64-.8-1.12-1.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 179.85000610351557, 215.26498413085935)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16-.32-.64-.8-1.12-1.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.84 287.5H238.68 238.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.84 287.66H238.68 238.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.84 287.66H238.68L238.52 287.82"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.84 287.82H238.68 238.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.84 287.98H238.68 238.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.84 288.14H238.68 238.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.84 288.14H238.68 238.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.52 284.94H238.68 238.84"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 179.729995727539, 213.70500183105466)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.32-.48-.64L-.8 .16H-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 179.729995727539, 213.82501220703122)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.32-.48-.64-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 179.729995727539, 213.94500732421872)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.32-.48-.64-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 179.729995727539, 213.94500732421872)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.32L-.48 .16H-.64-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 179.729995727539, 214.06500244140622)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.32-.48-.64-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 179.729995727539, 214.18499755859372)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.32-.48-.64-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 179.729995727539, 214.18499755859372)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0L-.32 .16H-.48-.64-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 179.729995727539, 214.30499267578122)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.32-.48-.64-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 179.729995727539, 214.42498779296872)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.32-.48-.64-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 179.729995727539, 214.42498779296872)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0 .16H-.32-.48-.64-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 179.729995727539, 214.54499816894528)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.32-.48-.64-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 179.729995727539, 214.66500854492185)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.32-.48-.64-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 179.729995727539, 214.78500366210935)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.32-.48-.64-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 179.729995727539, 214.78500366210935)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.32-.48-.64-.8-.96L-1.12 .16"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 179.729995727539, 214.90499877929685)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.32-.48-.64-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 179.729995727539, 215.0250091552734)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.32-.48-.64-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 179.729995727539, 215.0250091552734)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.32-.48-.64-.8L-.96 .16H-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 179.729995727539, 215.14498901367185)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.32-.48-.64-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 179.729995727539, 215.26498413085935)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.32-.48-.64-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 179.729995727539, 215.26498413085935)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.32-.48L-.64 .16H-.8-.96-1.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.84 287.5V287.5H238.68 238.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.84 287.66V287.66H238.68 238.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.84 287.82V287.82H238.68 238.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.84 287.82V287.82H238.68 238.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.84 287.98V287.98H238.68 238.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.84 288.14V288.14H238.68 238.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.84 288.14V288.14H238.68 238.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M239.48 284.94H238.52"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M236.44 293.91V292.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M236.44 293.91C236.44 293.91 236.44 294.07 236.6 294.07"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M290.28 243.93V247.93"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M302.46 187.7V183.53"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.02 287.5H356.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M236.44 287.5H233.71"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M233.71 287.5V138.2"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.02 287.5V138.2"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.02 138.2H233.71"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 226.35749816894526, 139.2149963378906)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-11.86 9.61-21.47 21.47-21.47 33.32-21.47 42.94-11.86 42.94 0 42.94 11.85 33.32 21.46 21.47 21.46 9.61 21.46 0 11.85 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 226.1174926757812, 139.2149963378906)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-12.02 9.93-21.79 21.95-21.79 33.97-21.79 43.9-12.02 43.9 0 43.9 12.17 33.97 21.94 21.95 21.94 9.93 21.94 0 12.17 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 226.7249908447265, 139.2149963378906)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-11.54 9.45-20.99 20.99-20.99 32.68-20.99 41.98-11.54 41.98 0 41.98 11.69 32.68 20.98 20.99 20.98 9.45 20.98 0 11.69 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 226.47750854492182, 139.2149963378906)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-11.86 9.61-21.31 21.47-21.31 33.32-21.31 42.94-11.86 42.94 0 42.94 11.85 33.32 21.46 21.47 21.46 9.61 21.46 0 11.85 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 225.15750122070307, 139.2149963378906)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-12.66 10.42-23.07 23.08-23.07 35.9-23.07 46.15-12.66 46.15 0 46.15 12.81 35.9 23.06 23.08 23.06 10.42 23.06 0 12.81 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 186.34500122070307, 184.50749206542966)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-11.54 9.45-20.99 20.99-20.99 32.52-20.99 41.98-11.54 41.98 0 41.98 11.69 32.52 20.98 20.99 20.98 9.45 20.98 0 11.69 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 185.9850006103515, 184.50749206542966)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-11.86 9.61-21.47 21.47-21.47 33.32-21.47 42.94-11.86 42.94 0 42.94 11.85 33.32 21.46 21.47 21.46 9.61 21.46 0 11.85 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 185.9850006103515, 184.50749206542966)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-11.86 9.61-21.47 21.47-21.47 33.32-21.47 42.94-11.86 42.94 0 42.94 11.85 33.32 21.46 21.47 21.46 9.61 21.46 0 11.85 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 185.61750793457026, 184.50749206542966)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-12.02 9.93-21.79 21.95-21.79 33.97-21.79 43.9-12.02 43.9 0 43.9 12.17 33.97 21.94 21.95 21.94 9.93 21.94 0 12.17 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 184.77749633789057, 184.50749206542966)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0C0-12.66 10.26-23.07 23.08-23.07 35.74-23.07 46.15-12.66 46.15 0 46.15 12.81 35.74 23.07 23.08 23.07 10.26 23.07 0 12.81 0 0Z"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M361.74 295.35 361.9 295.19"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M361.74 137.4V137.4H361.58"
    />
    <g
      clipPath="url(#clip_46)"
      transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -5.684341886080802e-14, 0)"
    >
      <path
        transform="matrix(.75,0,0,.75,0,0)"
        strokeWidth={0.801}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="#040503"
        d="M114.98 295.19 115.14 295.35"
      />
    </g>
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.98 170.56V169.12"
    />
    <g
      clipPath="url(#clip_47)"
      transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -5.684341886080802e-14, 0)"
    >
      <path
        transform="matrix(.75,0,0,.75,0,0)"
        strokeWidth={0.801}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="#040503"
        d="M114.34 292.46H114.82"
      />
    </g>
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M359.18 136.6V137.08"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M115.94 137.08V136.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M134.21 279.01H132.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M353.41 136.28H351.33"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M353.41 136.12H351.33"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M354.05 295.51H354.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M354.05 295.51H354.37"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M122.51 295.51H122.83"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M122.51 295.51H122.83"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M190.13 136.12H187.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M190.13 136.28H187.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M177.95 136.28H175.87"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M177.95 136.12H175.87"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M279.22 136.28H276.98"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M279.22 136.12H276.98"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M228.59 136.28H226.5"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M228.59 136.12H226.5"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M295.89 136.28H293.64"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M295.89 136.12H293.64"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M235.32 136.28H233.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M235.32 136.12H233.23"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M272.49 136.28H270.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M272.49 136.12H270.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M166.74 136.28H164.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M166.74 136.12H164.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M172.18 136.28H170.1"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M172.18 136.12H170.1"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M125.56 136.12H123.31"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M125.56 136.28H123.31"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M157.92 144.93V173.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M157.12 144.45V173.28"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M142.54 142.68V183.53"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M142.86 206.12V247.13"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M158.56 227.75V226.63"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M236.92 138.2V138.04"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M355.97 138.04V138.2"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.73 287.02H242.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M350.69 287.02H351.01"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M349.4 141.24H343.96"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M343.16 141.24H337.55"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M336.75 141.24H331.3"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M330.5 141.24H325.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M324.25 141.24H318.8"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M318 141.24H312.55"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M311.75 141.24H306.14"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M305.34 141.24H299.89"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M299.09 141.24H293.64"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M292.84 141.24H287.39"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M286.59 141.24H280.98"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M280.18 141.24H274.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M273.93 141.24H268.49"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M267.68 141.24H262.24"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M261.44 141.24H255.83"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M255.03 141.24H249.58"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M248.78 141.24H243.33"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 294.23H351.17"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M347.96 294.23H348.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.65 293.91H351.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M347.48 293.91H347.64"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.65 292.46H351.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M347.48 292.46H347.64"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.01 290.54H348.12"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M244.61 294.23H244.77"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.57 294.23H241.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M245.25 293.91H245.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.09 293.91H241.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M241.09 292.46H241.25"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M245.25 292.46H245.41"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M244.61 290.54H241.73"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M115.46 137.56H142.86"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M152.15 137.56H236.6"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M356.13 137.56H361.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M361.42 294.87H354.05"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M122.83 294.87H115.46"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M352.61 294.07V294.23H352.29 351.81"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M351.49 294.07H351.81 352.29 352.61 352.45"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M355.01 290.54H352.13"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M356.29 293.91H356.13"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M356.13 292.46H356.29"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.52 294.07C239.48 294.23 240.44 294.23 241.25 294.07"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.2 294.07V294.23H237.88 237.4"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.68 293.91C239.48 294.23 240.28 294.23 241.09 294.07"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 177.4500122070312, 220.55250549316403)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M0 0V0H-.16V-.16"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M236.44 293.91H236.6L237.24 294.07H237.72 238.04"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M238.04 294.07H238.2"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M240.6 290.54H237.72"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={0.801}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M236.6 292.46H236.44"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      d="M154.56 217.01 156.16 214.77 156.64 215.25 154.56 217.01Z"
      fill="#040503"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      d="M313.19 182.89 310.95 181.45 311.43 180.97 313.19 182.89Z"
      fill="#040503"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      d="M257.59 238.96 257.75 236.24H258.39L257.59 238.96Z"
      fill="#040503"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 204.60749816894526, 156.5099945068359)"
      d="M0 0V-.96H-6.41V0H-3.68V6.57C-3.68 6.89-3.52 7.05-3.2 7.05-2.88 7.05-2.72 6.89-2.72 6.57V0H0Z"
      fill="#231f20"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 209.0549926757812, 161.31750488281247)"
      d="M0 0C0-.16-.32-.48-.48-.48H-1.76V-6.89C-1.76-7.21-2.08-7.53-2.4-7.37L-4.97-6.73V-5.77L-2.72-6.25V-.48H-4.01C-4.33-.48-4.49-.16-4.49 0-4.49 .32-4.33 .64-4.01 .64H-.48C-.32 .64 0 .32 0 0Z"
      fill="#231f20"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 127.69499206542963, 144.25500488281247)"
      d="M0 0V-1.12H-6.25V0H-3.68V6.41C-3.68 6.73-3.36 7.05-3.2 7.05-2.88 7.05-2.56 6.73-2.56 6.41V0H0Z"
      fill="#231f20"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 132.38249206542963, 149.42250061035153)"
      d="M0 0V-.96H-4.65V-2.88C-4.49-3.04-3.53-3.36-3.36-3.36L-1.44-3.84C-.8-4 0-4.16 0-4.96V-6.09C0-7.05-1.12-8.01-2.88-8.01-4.01-8.01-4.97-7.85-5.45-7.53V-6.41C-4.97-6.57-4.17-7.05-2.88-7.05-1.76-7.05-1.28-6.41-1.12-6.09V-4.96L-2.24-4.64-4.33-4.16C-4.81-4-5.61-3.84-5.61-3.04V0H0Z"
      fill="#231f20"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={1.122}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M272.33 216.85 267.2 233.83"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 198.95999145507807, 174.89250183105466)"
      d="M0 0C-.16 .96 .32 2.09 1.28 2.41 2.4 2.73 3.53 2.09 3.85 1.12 4.01 .16 3.53-.96 2.56-1.28 1.44-1.6 .32-1.12 0 0Z"
      fill="#040503"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={1.122}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M169.3 202.44 164.17 219.42"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 121.68749999999994, 164.0849914550781)"
      d="M0 0C-.32 .96 .32 2.08 1.28 2.4 2.4 2.72 3.36 2.24 3.68 1.12 4 .16 3.52-.97 2.4-1.29 1.44-1.61 .32-.97 0 0Z"
      fill="#040503"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, -5.684341886080802e-14, 0)"
      strokeWidth={1.122}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#040503"
      d="M324.41 155.18 319.12 172.16"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 238.01998901367182, 128.6399993896484)"
      d="M0 0C-.32 1.12 .32 2.08 1.28 2.4 2.24 2.72 3.37 2.24 3.69 1.12 4.01 .16 3.37-.96 2.4-1.28 1.44-1.6 .32-.96 0 0Z"
      fill="#040503"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 243.90750122070307, 109.17750549316406)"
      d="M0 0V-.96H-6.25V0H-3.53V6.56C-3.53 6.72-3.37 7.05-3.05 7.05-2.72 7.05-2.56 6.72-2.56 6.56V0H0Z"
      fill="#231f20"
    />
    <path
      transform="matrix(0.75, 0, 0, 0.75, 248.59500122070307, 112.65750122070312)"
      d="M0 0C0-.64-.32-1.28-.8-1.76-.48-2.24-.32-2.88-.32-3.52-.32-4.48-.8-5.6-1.92-5.6H-5.45V-4.64H-1.76C-1.44-4.32-1.28-4-1.28-3.52-1.28-2.88-1.44-2.56-1.76-2.24H-4.97C-5.29-2.24-5.45-1.92-5.45-1.6-5.45-1.44-5.29-1.12-4.97-1.12H-1.6C-1.28-.96-.96-.64-.96 0-.96 .64-1.28 .96-1.6 1.28H-5.45V2.25H-1.6C-.64 2.25 0 1.12 0 0Z"
      fill="#231f20"
    />
    <rect
      x={128.555}
      y={126.732}
      width={41.112}
      height={15.115}
      style={{
        fill: "rgb(216, 216, 216)",
        stroke: "rgb(0, 0, 0)",
      }}
      transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -5.684341886080802e-14, 0)"
    />
    <rect
      x={183.217}
      y={137.655}
      width={41.112}
      height={15.115}
      style={{
        fill: "rgb(216, 216, 216)",
        stroke: "rgb(0, 0, 0)",
      }}
      transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -5.684341886080802e-14, 0)"
    />
    <rect
      x={223.346}
      y={91.068}
      width={41.112}
      height={15.115}
      style={{
        fill: "rgb(216, 216, 216)",
        stroke: "rgb(0, 0, 0)",
      }}
      transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -5.684341886080802e-14, 0)"
    />
    <text
      style={{
        whiteSpace: "pre",
        fill: "rgb(51, 51, 51)",
        fontFamily: "Arial, sans-serif",
        fontSize: "15.3px",
      }}
      x={130.477}
      y={139.792}
      transform="matrix(0.7657240033149719, 0, 0, 0.838710069656372, 30.02243232727045, 21.439062118530273)"
    >
      {T2 + " \xB0C"}
    </text>
    <text
      style={{
        whiteSpace: "pre",
        fill: "rgb(51, 51, 51)",
        fontFamily: "Arial, sans-serif",
        fontSize: "15.3px",
      }}
      x={130.477}
      y={139.792}
      transform="matrix(0.7657240033149719, 0, 0, 0.838710069656372, 84.02951049804682, 32.81583023071289)"
    >
      {T1 + " \xB0C"}
    </text>
    <text
      style={{
        whiteSpace: "pre",
        fill: "rgb(51, 51, 51)",
        fontFamily: "Arial, sans-serif",
        fontSize: "15.3px",
      }}
      x={130.477}
      y={139.792}
      transform="matrix(0.7657240033149719, 0, 0, 0.838710069656372, 124.92133331298822, -13.528244972229004)"
    >
      {T3 + " \xB0C"}
    </text>
  </svg>
);
export default NilanAbove;
