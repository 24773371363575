import React from 'react'
const Settings = () => {
  return (
    <div className="container">
    
      <h1 className="text-center" style={{paddingTop: "30%"}}>
        
      </h1>
      
    </div>
  )
}
export default Settings;