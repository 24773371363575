import React, { useState, useEffect, useRef } from "react";
import io from "socket.io-client";
import axios from 'axios';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import '../css/style.css';


const Lighting = () => {

  const socketRef = useRef();
  const [relays, setRelays] = useState([]);
  const [relaysInf, setRelaysInf] = useState([]);
  
  const [isLoading, setLoading] = useState(true);
  const [isLoading2, setLoading2] = useState(true);

  const [clicked, setClicked] = useState(false);
  const [points, setPoints] = useState({ x: 0, y: 0, });
  const [id, setId] = useState(null);
  const [newName, setNewName] = useState('');
  const [mousePos, setMousePos] = useState({});

  useEffect(() => {

    const handleMouseMove = (event) => {
      setMousePos({ x: event.clientX, y: event.clientY });
    };
    window.addEventListener('mousemove', handleMouseMove);

    const handleClickRight = () => setClicked(false);
    socketRef.current = io.connect('/relays');
   
    socketRef.current.on("relays", (result) => {
      setRelays(result.result);  
      getRelayInf();
      setLoading(false);
    })

    return () => {
      window.removeEventListener("click", handleClickRight);
      socketRef.current.close();
    }
  }, []);

  function getRelayInf(){
   
      const query = {
        "request" : "{\"id\" : \"sqlQuery\", \"query\": \"SELECT * FROM ifserver.relaysinf;\", \"db\": \"ifserver\"}",
        "authorization" : localStorage.getItem("token")
      }
      axios.post('/sendRequest', query)
      .then((res) => {
        
        setRelaysInf(res.data.result)
        localStorage.setItem('relayinf', JSON.stringify(res.data.result));
            
      }).catch((error) => {
        var result = localStorage.getItem('relayinf');
        result = JSON.parse(result);
        
          setRelaysInf(result)
      });  
   
    setLoading2(false);
  }

  function reName(rId, name, func){
   
    var query;

    switch(func){
      case "renameRelay":

        query = {
          "request" : "{\"id\" : \"sqlQuery\", \"query\": \"update relaysinf set name = '" + name + "' where idrelay = " + rId + ";\", \"db\": \"ifserver\"}",
          "authorization" : localStorage.getItem("token")
        }
        break;
      case "renameGroup":

        query = {
          "request" : "{\"id\" : \"sqlQuery\", \"query\": \"update relaysinf set `group` = '" + name + "' where idrelay = " + rId + ";\", \"db\": \"ifserver\"}",
          "authorization" : localStorage.getItem("token")
        }
        break;
      case "changeGroup":
        
      query = {
        "request" : "{\"id\" : \"sqlQuery\", \"query\": \"update relaysinf set `group` = '" + name + "' where `group` = '" + relaysInf[id -1].group + "';\", \"db\": \"ifserver\"}",
        "authorization" : localStorage.getItem("token")
      }
      break;
    }
    
    axios.post('/sendRequest', query)
      .then((res) => {
      
        getRelayInf()
      })
      .catch((error) => {
      
      });  
 }


  function handleClick(id, stat) {
    
    const relay = {
                    "request" : "{\"id\" : \"relays\", \"function\" : \"setRelay\", \"relay\" : \"" + id + "\", \"stat\" : \"" + (1 - stat) + "\"}",
                    "authorization" : localStorage.getItem("token")
                  }
    socketRef.current.emit("request", relay);
    console.log(id)
    
  }

 
  function handleRename(id, newName, func){
    
   setClicked(false)

    if(relaysInf[id -1].name != newName && newName.length > 2){

      reName(id, newName, func)

    }
    
  }

  if (isLoading || isLoading2) {
    return <div className="App">Loading...</div>;
  }

var groups = [...new Set(relaysInf.map(cn => {
  
  if(cn.type == "lighting"){
    return cn.group
  }else{
    return null
  }

}).filter(el => {

  return el != "relaysinf";
}).filter(el => {

  return el != null;
}))]




 const element = relaysInf.length > 0 && relays.length > 0 ? groups.map(cn => {return (

  
  <Card key={cn} className="justify-content m-2 kortti">
      
  <Card.Body>
    <Card.Title>{cn}</Card.Title>

    {relays.map(button => {if(relaysInf[button.relay - 1].group == cn && relaysInf[button.relay - 1].type == "lighting"){        
       return <Button onContextMenu={(e) => {  e.preventDefault();
        setClicked(true);
        setPoints({
          x: mousePos.x,
          y: mousePos.y,
        });
        setId(e.target.id)
      }} id={button.relay} variant={button.stat == "0" ? "outline-success" : "success"} className="m-2" onClick={() => handleClick(button.relay, button.stat)} key={button.relay}>{relaysInf[button.relay - 1].name}</Button> 
    }})}
 
  </Card.Body>
</Card> )

}) : null;


const style = {
  position: 'fixed',
  top: points.y,
  left: points.x,
  zIndex: '10',
  flex: '0'
}

const style2 = {
  minWidth:'150px',
  flex: '0'
}

  return (
   
    
    <div className="p-2">
  
    <Container>

    <>
  
      
    <Row>
    {clicked && (
            <InputGroup className="mb-1" style={style}>
             

        <DropdownButton
          variant="secondary"
          title="Valikko"
          id="input-group-dropdown-1"
        >
          <Dropdown.Item onClick={(e) => handleRename(id, newName, "renameRelay")}>Uudelleennimeä</Dropdown.Item>
          <Dropdown.Item onClick={(e) => handleRename(id, newName, "renameGroup")}>Vaihda ryhmä</Dropdown.Item>
          <Dropdown.Item onClick={(e) => handleRename(id, newName, "changeGroup")}>Vaihda ryhmän nimi</Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={()=>setClicked(false)}>Sulje</Dropdown.Item>
        </DropdownButton>

        <Form.Control
          for="rename"
          onChange={(event) =>
            setNewName(event.target.value)
          }
          
          aria-label="Rename"
          placeholder="Uudelleen nimeä"
          aria-describedby="basic-addon1"
          style={style2}
        />
      </InputGroup>
          
      )}
    {element}
 
    </Row>
   
      
      
    </>
   
    </Container>
    
   
   </div>
    
 


  )
}
export default Lighting;