import React, { useState, useEffect, useRef } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import io from "socket.io-client";
import ReactApexChart from "react-apexcharts";
import Card from 'react-bootstrap/Card';
import 'bootstrap/dist/css/bootstrap.min.css';


const Electric = () => {

  const [electricMeters, setMeters] = useState([]);

 

  const gaugeSettings = {
    series: [77.00],
    options: {
      chart: {
        type: 'radialBar',
        height: 450,
        offsetY: -30,
              
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 135,
          hollow: {
            margin: 0,
            size: '30%',
            background: 'transparent',
            image: undefined,
          },
          grid: {
            padding: {
              left: 0,
              right: 0
            }
          },
          dataLabels: {
            name: {
              fontSize: '1px',
              color: "#FF9B00",
              offsetY: 0
            },
            value: {
              offsetY: 40,
              fontSize: '18px',
              color: "#FF9B00",
              formatter: function (val) {
                return Math.round(50 * val) + " W";
              }
            }
          }
        }
      },
      fill: {          
        type: 'gradient',
        colors: "#FF9B00",
        gradient: {
            shade: 'dark',
            shadeIntensity: 0.15,
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 65, 91]
            
        },
      },
      stroke: {
        dashArray: 4
      },
      labels:[" "]
    },
  
  
}

  const socketRef = useRef();

  useEffect(() => {
    socketRef.current = io.connect('/electric');

    socketRef.current.on("electric", (result) => {
             
      var meters = [{
          label: "Vesi-ilmalämpöpumppu",
          meter: "heating",
          value: parseInt((result.result.registers.find(element => element['slaveId'] == '1' && element['register'] == '18')).value) / 10
      },
       {
        label: "Ilmastointikone",
        meter: "ventilation",
        value: parseInt((result.result.registers.find(element => element['slaveId'] == '1' && element['register'] == '20')).value) / 10
       
       },
       {
        label: "Valaistus",
        meter: "lighting",
        value: parseInt((result.result.registers.find(element => element['slaveId'] == '1' && element['register'] == '22')).value) / 10

       },
       {
        label: "Aurinkopaneelit",
        meter: "solar",
        value: parseInt(result.result.registers.find(element => element['meter'] == 'solar').value)

       },
       {
        label: "Kokonaiskulutus",
        meter: "total",
        value: (parseInt((result.result.registers.find(element => element['slaveId'] == '2' && element['register'] == '18')).value) / 10 + 
        parseInt((result.result.registers.find(element => element['slaveId'] == '2' && element['register'] == '20')).value)/ 10 +
        parseInt((result.result.registers.find(element => element['slaveId'] == '2' && element['register'] == '22')).value) / 10) 

       }
       ,
       {
        label: "Kokonaiskulutus dmd",
        meter: "total",
        value: parseInt((result.result.registers.find(element => element['slaveId'] == '2' && element['register'] == '56')).value) / 10 

       }
       ] 
      
      setMeters(meters);
     
    })

    return () => {
      socketRef.current.close();
    }
  }, []);
 
  const ielements = electricMeters.map(gauge =>
     
    <Card key={gauge.label} className="justify-content m-2 kortti">
      
    <Card.Body>
      <Card.Title>{gauge.label}</Card.Title>
  
      <ReactApexChart options={gaugeSettings.options} series={[gauge.value / 50]} type="radialBar" height={200} labels={['heimo']}  />
      
   
    </Card.Body>
  </Card>)
  
  return (
    <div className="p-2">
  
    <Container>

    <>
  
      
    <Row>
    {ielements}
    </Row>
   
      
      
   </>
  
   </Container>
   
   
  </div>
   
  )
}
export default Electric;